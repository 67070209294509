import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import Backdrop from "../Misc/Backdrop";
import {
  addApplication,
  // fetchApplications,
  applicationById,
} from "../../features/application";
import { addApplicationValue } from "../../features/applicationValue";
import { fetchApplicationStatus } from "../../features/applicationStatus";
import { selectAllPrograms } from "../../features/program";
// import { addWaterSavings } from "../../features/waterSavingsLog";
import { addApplicationStatus } from "../../features/applicationStatus";
import { selectAllDropdownValues } from "../../features/dropdownValue";
// import { selectAllCheckValues } from "../../features/checkValue";
import { fetchStatusList, selectStatusList } from "../../features/status";
import { setToast } from "../../utils/ToastNotification";
import { fetchApplicationValues } from "../../features/applicationValue";
import { formatFullAddress } from "../../utils/formatAddress";
import { addStatusLog } from "../../features/status";
import { BsFillTrashFill } from "react-icons/bs";
import { IoIosAddCircle } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { IoIosAdd } from "react-icons/io";
import { getSubmissionEmailTemplate } from "../../features/emailTemplate";
// import { featureFlags } from "../../utils/featureFlags";
import { fetchPropertyDropdown } from "../../features/properties";

import { Button } from "../Ui/button";

import { upload } from "@testing-library/user-event/dist/upload";
import HashLoader from "react-spinners/HashLoader";

import ReactQuill, { Quill } from "react-quill";

const axios = require("axios").default;

axios.defaults.headers.common["Client"] = "ACWD";

const dropIn = {
  hidden: { y: "-100vh", opacity: 0 },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: { y: "100vh", opacity: 0 },
};
const ApplicationForm = ({ props }) => {
  const dispatch = useDispatch();
  const statusList = useSelector(selectStatusList);
  const dropdownValues = useSelector(selectAllDropdownValues);

  const [uploads, setUploads] = useState(null);

  const inputType = {
    BOOLEAN: "checkbox",
    STRING: "text",
    DATE: "date",
    NUMBER: "text",
  };

  const placeholders = {
    BOOLEAN: "",
    STRING: "Enter Answer",
    DATE: "",
    NUMBER: "Enter Number",
  };

  useEffect(() => {
    const handleDeleteClick = (event) => {
      // Reset the associated file input by clearing its value
      const fileInput = event.target
        .closest(".flex")
        .querySelector("input[type='file']");
      if (fileInput) {
        fileInput.value = "";
      }
      const updateEvent = new Event("change", {
        bubbles: true,
        cancelable: true,
      });
      fileInput.dispatchEvent(updateEvent);
    };

    const temp = props.uploads.map((upload, index) => {
      return (
        <div className="flex flex-col" key={index}>
          <div className="flex gap-1 items-center">
            {upload.required && <span className="text-red-500">*</span>}
            <span className="font-bold">{upload.name}</span>
          </div>

          <span>{upload.description.replace(/<br\s*\/?>/gi, "\n")}</span>
          <div>
            <input
              type="file"
              className="border-gray-200 border w-6/12 p-2 rounded"
              secondaryId={upload.id}
              onChange={(e) => {
                setFiles((prev) => ({
                  ...prev,
                  [`${upload.name}-${upload.id}-${
                    upload.required ? "t" : "f"
                  }`]: e.target.files[0],
                }));
              }}
            />
            <span>
              <button>
                <BsFillTrashFill
                  size="18"
                  className="cursor-pointer dark:text-white hover:text-red-400 ml-5"
                  onClick={handleDeleteClick}
                />
              </button>
            </span>
          </div>
        </div>
      );
    });
    setUploads(temp);
  }, [props.uploads]);

  const [fields, setFields] = useState(null);

  const [values, setValues] = useState({});
  const [uploadFiles, setUploadFiles] = useState([]);
  const [checkboxValues, setCheckboxValues] = useState({});

  const [files, setFiles] = useState({});
  const [fieldNames, setFieldNames] = useState([]);
  const [propertyId, setPropertyId] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);

  const [dropdownFields, setDropdownFields] = useState(null);

  const programs = useSelector(selectAllPrograms);
  const [program, setProgram] = useState(null);

  useEffect(() => {
    const temp = {};
    props.fields.forEach((x) => {
      if (x.checkValues.length > 0 && !x.internalFacing && !x.deleted) {
        x.checkValues.forEach((y) => {
          temp[`${y.value}-${y.id}-${x.id}${x.required ? "-t" : "-f"}`] = false;
        });
      }
    });
    setCheckboxValues(temp);
  }, [props.fields]);

  const addTailwindClass = (inputString, tailwindClass) => {
    // Create a new DOMParser
    const parser = new DOMParser();

    // Parse the input string
    const parsedHtml = parser.parseFromString(inputString, "text/html");

    // Get all the anchor tags with an href attribute
    const anchorTags = parsedHtml.querySelectorAll("a[href]");

    // Add Tailwind CSS class to each anchor tag
    anchorTags.forEach((anchorTag) => {
      anchorTag.classList.add(tailwindClass);
    });

    // Convert the modified HTML back to a string
    const modifiedHtmlString = parsedHtml.documentElement.outerHTML;
    const modifiedString = modifiedHtmlString.replace(/class=/g, "className=");

    return modifiedString;
  };
  useEffect(() => {
    if (program) {
      const inputString = program.description;
      const tailwindClass = "text-blue-500"; // Replace with the desired Tailwind CSS class
      const modifiedString = addTailwindClass(inputString, tailwindClass);
    }
  }, [program]);

  useEffect(() => {
    if (programs) {
      const temp = programs?.filter((program) => {
        return program.id === props.program.id;
      });
      setProgram(temp[0]);
    }
  }, [programs]);

  const [lookupSearch, setLookupSearch] = useState("");
  const [lookupOptions, setLookupOptions] = useState(null);
  const [searchList, setSearchList] = useState(null);
  const [myList, setMylist] = useState([]);
  const [myListFieldId, setMyListFieldId] = useState(null);
  const [useMultiplier, setUseMultiplier] = useState(false);

  const [sumInputLabel, setSumInputLabel] = useState(0);
  const [sumOf, setSumOf] = useState("");
  const [newItem, setNewItem] = useState({
    lookupName1: "",
    lookupName2: "",
    inputLabel: "",
    multiplier: 1,
  });

  const getFieldNameFromKey = (key) => {
    let parts = key.split("-");

    let keyFieldName = "";
    if (parts.length > 3) {
      parts.splice(-2);
      keyFieldName = parts.join("-");
    } else {
      keyFieldName = parts[0];
    }

    return keyFieldName;
  };

  const getCheckboxFieldNameFromKey = (key) => {
    let parts = key.split("-");

    let keyFieldName = "";
    if (parts.length > 4) {
      parts.splice(-3);
      keyFieldName = parts.join("-");
    } else {
      keyFieldName = parts[0];
    }

    return keyFieldName.trim();
  };

  const getCheckValueIdFromKey = (key) => {
    return parseInt(key.split("-")[key.split("-").length - 3]);
  };

  const getCheckFieldIdFromKey = (key) => {
    return parseInt(key.split("-")[key.split("-").length - 2]);
  };

  const getRequiredFromKey = (key) => {
    return key.split("-")[key.split("-").length - 1];
  };

  const getFieldIdFromKey = (key) => {
    return parseInt(key.split("-")[key.split("-").length - 2]);
  };

  const getSecondaryId = (key) => {
    return parseInt(key.split("-")[key.split("-").length - 2]);
  };

  const addToMyList = (payload) => {
    const newList = [...myList];
    const index = myList.length;

    if (payload) {
      const tempObj = {
        id: index,
        index: index,
        lookupName1: payload.lookupName1,
        lookupName2: payload.lookupName2,
        inputLabel: payload.inputLabel,
        multiplier: payload.multiplier,
      };
      newList.push(tempObj);
      setMylist([...newList]);
    } else {
      const tempObj = {
        id: index,
        index: index,
        lookupName1: newItem.lookupName1,
        lookupName2: newItem.lookupName2,
        inputLabel: newItem.inputLabel,
        multiplier: newItem.multiplier,
      };
      newList.push(tempObj);

      setMylist(newList);
    }
  };

  const clearInputLabel = (index) => {
    const fields = document.querySelectorAll(`#inputLabel`);
    fields.forEach((x) => {
      x.value = "";
    });
  };

  const clearAddNewItemInputs = () => {
    const fields = document.querySelectorAll(`#addItem`);
    fields.forEach((x) => {
      x.value = "";
    });
  };

  useEffect(() => {
    let total = 0;
    let sumString = "";
    if (myList.length > 0) {
      if (useMultiplier && useMultiplier === false) {
        myList.forEach((x, index) => {
          total += parseInt(x.inputLabel) * 1;
          if (index !== myList.length - 1) {
            sumString += ` [${x.inputLabel} * 1] +`;
          } else {
            sumString += ` [${x.inputLabel} * 1]`;
          }
        });
      } else {
        myList?.forEach((x, index) => {
          total += parseInt(x.inputLabel) * parseInt(x.multiplier);
          if (index !== myList.length - 1) {
            sumString += ` [${x.inputLabel} * ${x.multiplier}] +`;
          } else {
            sumString += ` [${x.inputLabel} * ${x.multiplier}]`;
          }
        });
      }
    }
    setSumInputLabel(total);
    setSumOf(sumString);
  }, [myList]);

  const setLookupInput = (lo, e) => {
    const temp = [...lookupOptions];

    temp.forEach((x) => {
      if (
        x.lookupName1 === lo.lookupName1 &&
        x.lookupName2 === lo.lookupName2
      ) {
        x.inputLabel = e.target.value;
      }
    });
    setLookupOptions([...temp]);
  };

  const deleteFromMyList = (index) => {
    let temp = [...myList];

    if (
      temp?.filter((y) => {
        return y.index === index;
      }).length > 0
    ) {
      const removeIndex = temp.findIndex((obj) => obj.id === index);

      temp.splice(removeIndex, 1);
      setMylist([...temp]);
    } else {
      const toastPayload = {
        index: 2,
        description: 16,
        type: "application",
      };
      setToast(toastPayload);
    }
  };

  const generateList = () => {
    if (lookupOptions && lookupOptions.length > 0 && lookupSearch.length > 0) {
      const temp = lookupOptions.filter((lo) => {
        return (
          lo["lookupName1"]
            .toLowerCase()
            .includes(lookupSearch.toLowerCase()) ||
          lo["lookupName2"].toLowerCase().includes(lookupSearch.toLowerCase())
        );
      });
      if (temp.length > 0) {
        setSearchList([...temp]);
      } else {
        setSearchList(0);
      }
    }
  };

  useEffect(() => {
    const obj = {};
    console.log(
      "props.fields123: ",
      props.fields.filter((x) => {
        return !x.internalFacing; // hide internal facing fields
      })
    );

    // props.fields
    //   .filter((x) => {
    //     return !x.internalFacing; // hide internal facing fields
    //   })
    //   .forEach((x) => {
    //     if (x.fieldType === "BOOLEAN") {
    //       console.log("BOOLFOOLD", x);
    //     }
    //   });
    props.fields
      .filter((x) => {
        return !x.internalFacing && !x.deleted; // hide internal facing fields
      })
      .map((field) => {
        if (field.fieldType === "DROPDOWN") {
          const temp = dropdownValues?.filter((value) => {
            return value?.fieldId === field.id;
          });

          if (temp.length > 0) {
            setDropdownFields((prev) => ({
              ...prev,
              [`${field.fieldName}`]: temp,
            }));
            obj[`${field.fieldName}`] = temp;
          }
        }
        if (field.fieldType === "LOOKUP" && lookupOptions === null) {
          if (field.lookupOptions.length > 0) {
            setMyListFieldId(field.lookupOptions[0].fieldId);
            const lookupTemp = [];
            field.lookupOptions.map((x) => {
              const tempObj = {};
              tempObj["id"] = x.id;
              tempObj["fieldId"] = x.fieldId;
              tempObj["lookupName1"] = x.lookupName1;
              tempObj["lookupName2"] = x.lookupName2;
              tempObj["multiplier"] = x.multiplier;
              tempObj["inputLabel"] = "";
              lookupTemp.push(tempObj);
            });
            setLookupOptions([...lookupTemp]);
            setUseMultiplier(field.lookupCharacteristics?.useMultiplier);
          }
        }
      });
    const temp = props.fields
      .filter((x) => {
        return !x.internalFacing && !x.deleted; // hide internal facing fields
      })
      .sort((a, b) => {
        return a.applicationOrder - b.applicationOrder;
      })
      .map((field, index) => {
        return (
          <div className="flex flex-col w-full gap-1" key={index}>
            <div
              className={`flex gap-2 ${
                field.fieldType === "SECTION" ? "border-b-8" : "border-b"
              }`}
            >
              <label
                className={`font-bold font-sans ${
                  field.fieldType === "SECTION"
                    ? "text-4xl text-green-reef"
                    : ""
                }`}
              >
                {field.fieldName}
              </label>
              {field.required && <span className="text-red-500">*</span>}
            </div>
            <span>{field.fieldDescription.replace(/<br\s*\/?>/gi, "\n")}</span>
            {field.fieldType === "STRING" && (
              <input
                type={`${inputType[field.fieldType]}`}
                placeholder={`${placeholders[field.fieldType]}`}
                className={`border-gray-200 border p-2 rounded
                ${field.fieldType === "DATE" ? "w-3/12" : ""}
                ${field.fieldType === "BOOLEAN" ? "w-5 h-10" : ""}
              
              `}
                onChange={(e) => {
                  if (
                    field.fieldType === "NUMBER" ||
                    field.fieldType === "STRING" ||
                    field.fieldType === "DATE"
                  ) {
                    setValues((prev) => ({
                      ...prev,
                      [`${field.fieldName}-${field.id}${
                        field.required ? "-t" : "-f"
                      }`]: e.target.value,
                    }));
                  } else {
                    setValues((prev) => ({
                      ...prev,
                      [`${field.fieldName}-${field.id}${
                        field.required ? "-t" : "-f"
                      }`]: e.target.checked,
                    }));
                  }
                }}
              />
            )}
            {field.fieldType === "NUMBER" && (
              <input
                type={`${inputType[field.fieldType]}`}
                placeholder={`${placeholders[field.fieldType]}`}
                className={`border-gray-200 border p-2 rounded
                ${field.fieldType === "DATE" ? "w-3/12" : ""}
                ${field.fieldType === "BOOLEAN" ? "w-5 h-10" : ""}
              
              `}
                onChange={(e) => {
                  if (
                    field.fieldType === "NUMBER" ||
                    field.fieldType === "STRING" ||
                    field.fieldType === "DATE"
                  ) {
                    setValues((prev) => ({
                      ...prev,
                      [`${field.fieldName}-${field.id}${
                        field.required ? "-t" : "-f"
                      }`]: e.target.value,
                    }));
                  } else {
                    setValues((prev) => ({
                      ...prev,
                      [`${field.fieldName}-${field.id}${
                        field.required ? "-t" : "-f"
                      }`]: e.target.checked,
                    }));
                  }
                }}
              />
            )}

            {field.fieldType === "BOOLEAN" && (
              <input
                type={`${inputType[field.fieldType]}`}
                placeholder={`${placeholders[field.fieldType]}`}
                className={`border-gray-200 border p-2 rounded
                ${field.fieldType === "DATE" ? "w-3/12" : ""}
                ${field.fieldType === "BOOLEAN" ? "w-5 h-10" : ""}
              
              `}
                onChange={(e) => {
                  if (
                    field.fieldType === "NUMBER" ||
                    field.fieldType === "STRING" ||
                    field.fieldType === "DATE"
                  ) {
                    setValues((prev) => ({
                      ...prev,
                      [`${field.fieldName}-${field.id}${
                        field.required ? "-t" : "-f"
                      }`]: e.target.value,
                    }));
                  } else {
                    setValues((prev) => ({
                      ...prev,
                      [`${field.fieldName}-${field.id}${
                        field.required ? "-t" : "-f"
                      }`]: e.target.checked,
                    }));
                  }
                }}
              />
            )}
            {field.fieldType === "DATE" && (
              <input
                type={`${inputType[field.fieldType]}`}
                placeholder={`${placeholders[field.fieldType]}`}
                className={`border-gray-200 border p-2 rounded
                ${field.fieldType === "DATE" ? "w-3/12" : ""}
                ${field.fieldType === "BOOLEAN" ? "w-5 h-10" : ""}
              
              `}
                onChange={(e) => {
                  if (
                    field.fieldType === "NUMBER" ||
                    field.fieldType === "STRING" ||
                    field.fieldType === "DATE"
                  ) {
                    setValues((prev) => ({
                      ...prev,
                      [`${field.fieldName}-${field.id}${
                        field.required ? "-t" : "-f"
                      }`]: e.target.value,
                    }));
                  } else {
                    setValues((prev) => ({
                      ...prev,
                      [`${field.fieldName}-${field.id}${
                        field.required ? "-t" : "-f"
                      }`]: e.target.checked,
                    }));
                  }
                }}
              />
            )}
            {field.fieldType === "DROPDOWN" && (
              <select
                className="border border-gray-200 rounded w-6/12 p-2"
                onChange={(e) => {
                  setValues((prev) => ({
                    ...prev,
                    [`${field.fieldName}-${field.id}${
                      field.required ? "-t" : "-f"
                    }`]: e.target.value,
                  }));
                }}
              >
                <option disabled selected>
                  {""}
                </option>
                {obj[field.fieldName] &&
                  obj[field.fieldName].map((el) => {
                    return <option value={el.value}>{el.value}</option>;
                  })}
              </select>
            )}
            {field.fieldType === "CHECK" && (
              <div className="flex flex-col gap-2">
                {field.checkValues.map((x) => {
                  return (
                    <div className="flex gap-2 items-center">
                      <input
                        type="checkbox"
                        className="w-4 h-4"
                        onChange={(e) => {
                          setCheckboxValues((prev) => ({
                            ...prev,
                            [`${x.value}-${x.id}-${field.id}${
                              field.required ? "-t" : "-f"
                            }`]: e.target.checked,
                          }));
                        }}
                      />
                      <label>{x.value}</label>
                    </div>
                  );
                })}
                {field.checkOther && (
                  <>
                    <span className="mt-2">Other</span>
                    <input
                      type="text"
                      onChange={(e) => {
                        setValues((prev) => ({
                          ...prev,
                          [`${field.fieldName}-${field.id}${
                            field.required ? "-t" : "-f"
                          }`]: e.target.value,
                        }));
                      }}
                      className={`border-gray-200 border rounded p-2`}
                    />
                  </>
                )}
              </div>
            )}
            {field.fieldType === "LOOKUP" && (
              <div className="flex flex-col gap-4 mt-4">
                <div className="flex flex-col gap-2">
                  <span className="font-bold">My List</span>

                  <span className="">{`Total ${
                    field.lookupCharacteristics?.useMultiplier
                      ? field.lookupCharacteristics.multiplierLabel
                      : ""
                  }: ${sumInputLabel}`}</span>
                  <table className="table-auto w-full text-left shadow">
                    <thead>
                      <tr className="bg-blue-reef text-gray-50">
                        <th className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                          {field.lookupCharacteristics?.name1Label}
                        </th>
                        <th className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                          {field.lookupCharacteristics?.name2Label}
                        </th>
                        <th className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                          {field.lookupCharacteristics?.inputLabel}
                        </th>
                        {field.lookupCharacteristics?.useMultiplier && (
                          <th className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                            {field.lookupCharacteristics?.multiplierLabel}
                          </th>
                        )}

                        <th className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                          {"Actions"}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {myList?.map((x) => {
                        return (
                          <tr className="h-[2.5rem]">
                            <td className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                              {x.lookupName1}
                            </td>
                            <td className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                              {x.lookupName2}
                            </td>

                            <td className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                              {x.inputLabel}
                            </td>
                            {field.lookupCharacteristics?.useMultiplier && (
                              <td className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                                {x.multiplier * x.inputLabel}
                              </td>
                            )}

                            <td className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                              {
                                <BsFillTrashFill
                                  size="20"
                                  className="text-red-500 cursor-pointer hover:text-gray-200"
                                  onClick={(e) => {
                                    deleteFromMyList(x.id);
                                  }}
                                />
                              }
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="flex flex-col gap-4">
                  <div className="flex flex-col">
                    <span className="font-bold">Search the Lookup</span>
                    <span>
                      {field.lookupCharacteristics?.searchDescription}
                    </span>
                  </div>
                  <div className="flex gap-2">
                    <input
                      type="text"
                      className="`border-gray-200 border rounded p-2 w-8/12"
                      onChange={(e) => {
                        setLookupSearch(e.target.value);
                      }}
                    />
                    <button
                      className="bg-green-reef p-2 rounded text-white shadow"
                      onClick={() => {
                        generateList();
                        clearInputLabel();
                      }}
                    >
                      Search
                    </button>
                  </div>

                  <table className="table-auto w-full text-left shadow">
                    <thead>
                      {searchList !== 0 && searchList && (
                        <tr className="bg-blue-reef text-white">
                          <th className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                            {field.lookupCharacteristics?.name1Label}
                          </th>
                          <th className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                            {field.lookupCharacteristics?.name2Label}
                          </th>

                          {field.lookupCharacteristics?.useMultiplier && (
                            <th className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                              {field.lookupCharacteristics?.multiplierLabel}
                            </th>
                          )}
                          <th className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                            {field.lookupCharacteristics?.inputLabel}
                          </th>
                          <th className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                            {"Actions"}
                          </th>
                        </tr>
                      )}
                    </thead>

                    <tbody>
                      {searchList !== 0 ? (
                        searchList?.map((x, index) => {
                          return (
                            <tr key={index} className="h-[2.5rem]">
                              <td className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                                {x.lookupName1}
                              </td>
                              <td className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                                {x.lookupName2}
                              </td>

                              {field.lookupCharacteristics?.useMultiplier && (
                                <td className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                                  {x.multiplier}
                                </td>
                              )}
                              <td className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50 w-3/12">
                                <input
                                  id={`inputLabel`}
                                  className="`border-gray-200 border rounded p-2 w-full"
                                  onChange={(e) => {
                                    //update lookup options by filtering and finding lookup option by lookupName1 + lookupName2 and then updating the inputLabel key value pair
                                    setLookupInput(x, e);
                                  }}
                                />
                              </td>
                              <td className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                                <button
                                  className="bg-green-reef rounded shadow hover:bg-white hover:border hover:text-gray-900 text-gray-50"
                                  onClick={() => {
                                    addToMyList(x, index);
                                  }}
                                >
                                  <IoIosAdd size="24" />
                                </button>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr key={index} className="h-[2.5rem]">
                          <td className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                            {"No search results"}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {field.lookupCharacteristics?.addDescription && (
                  <div className="flex flex-col">
                    <span className="font-bold">
                      Add new item not found in search
                    </span>
                    <span>{field.lookupCharacteristics.addDescription}</span>
                    <div className="flex gap-4">
                      <input
                        id="addItem"
                        className="`border-gray-200 border rounded p-2 w-8/12"
                        placeholder={field.lookupCharacteristics?.name1Label}
                        onChange={(e) => {
                          setNewItem((prev) => ({
                            ...prev,
                            lookupName1: e.target.value,
                          }));
                        }}
                      />
                      <input
                        id="addItem"
                        className="`border-gray-200 border rounded p-2 w-8/12"
                        placeholder={field.lookupCharacteristics?.name2Label}
                        onChange={(e) => {
                          setNewItem((prev) => ({
                            ...prev,
                            lookupName2: e.target.value,
                          }));
                        }}
                      />
                      {field.lookupCharacteristics?.useMultiplier && (
                        <input
                          id="addItem"
                          className="`border-gray-200 border rounded p-2 w-8/12"
                          placeholder={
                            field.lookupCharacteristics.multiplierLabel
                          }
                          onChange={(e) => {
                            setNewItem((prev) => ({
                              ...prev,
                              multiplier: e.target.value,
                            }));
                          }}
                        />
                      )}
                      <input
                        id="addItem"
                        className="`border-gray-200 border rounded p-2 w-8/12"
                        placeholder={field.lookupCharacteristics?.inputLabel}
                        onChange={(e) => {
                          setNewItem((prev) => ({
                            ...prev,
                            inputLabel: e.target.value,
                          }));
                        }}
                      />
                      <button
                        className="bg-green-reef p-4 rounded text-white shadow"
                        onClick={() => {
                          addToMyList();
                          clearAddNewItemInputs();
                        }}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        );
      });
    console.log("values: ", values);
    setFields(temp);

    const temp2 = props.fields.map((field) => {
      return field.fieldName;
    });

    console.log("temp2: ", temp2);
    setFieldNames(temp2);
  }, [
    props.fields,
    lookupSearch,
    searchList,
    myList,
    lookupOptions,
    sumInputLabel,
    newItem,
    useMultiplier,
    myListFieldId,
  ]);

  //required fields validation

  useEffect(() => {
    const fields = props.fields;

    for (let i = 0; i < fields.length; i++) {
      if (fields[i].internalFacing === false && fields[i].deleted === false)
        if (fields[i].fieldType === "CHECK" && fields[i].checkOther === true) {
          setValues((prev) => ({
            ...prev,
            [`${fields[i].fieldName}-${fields[i].id}${
              fields[i].required ? "-t" : "-f"
            }`]: "",
          }));
        } else if (fields[i].fieldType === "LOOKUP") {
          setValues((prev) => ({
            ...prev,
            [`${"LOOKUP*FIELD"}-${fields[i].id}${
              fields[i].required ? "-t" : "-f"
            }`]: "",
          }));
        } else if (fields[i].fieldType === "BOOLEAN") {
          setValues((prev) => ({
            ...prev,
            [`${fields[i].fieldName}-${fields[i].id}${
              fields[i].required ? "-t" : "-f"
            }`]: false,
          }));
        } else {
          setValues((prev) => ({
            ...prev,
            [`${fields[i].fieldName}-${fields[i].id}${
              fields[i].required ? "-t" : "-f"
            }`]: "",
          }));
        }
    }
  }, [props.fields]);

  useEffect(() => {
    if (props.uploads) {
      const temp = [];
      props.uploads.forEach((u) => {
        if (u.required) temp.push(`${u.name}-${u.id}-t`);
      });
      setUploadFiles([...temp]);
    }
  }, [props.uploads]);

  const [customerProperties, setCustomerProperties] = useState(null);
  //property types
  const [programType, setProgramType] = useState({
    singleFamilyHome: props.program.singleFamilyHome,
    residential: props.program.residential,
    multiFamilyComplex: props.program.multiFamilyComplex,
    landscape: props.program.landscape,
    commercial: props.program.commercial,
  });

  useEffect(() => {
    // if (props.properties) {
    // const temp = props.properties.filter((property) => {
    //   return property?.customerProperty?.filter((x) => {
    //     return (
    //       parseInt(x.customerId) === parseInt(localStorage.getItem("user_id"))
    //     );
    //   })[0];
    // });
    // console.log("customer-properties: ", temp);
    // setCustomerProperties(temp);
    // }
    const fetchCustomerProperties = async () => {
      try {
        const cp = await dispatch(
          fetchPropertyDropdown({ customerId: localStorage.getItem("user_id") })
        );
        console.log("cp", cp.payload);
        setCustomerProperties(cp.payload);
      } catch (err) {
        console.log("fetch-property-err");
      }
    };
    fetchCustomerProperties();
  }, []);

  const validateRequired = () => {
    for (let i = 0; i < uploadFiles.length; i++) {
      if (!files.hasOwnProperty(uploadFiles[i])) {
        return false;
      }
    }
    for (const [key, value] of Object.entries(values)) {
      if (
        (getRequiredFromKey(key) === "t" && value === "") ||
        (getRequiredFromKey(key) === "t" &&
          props.fields.filter((x) => {
            return x.fieldName === getFieldNameFromKey(key);
          })[0]?.fieldType === "BOOLEAN" &&
          value === false)
      ) {
        if (key.split("-")[0] === "LOOKUP*FIELD" && myList.length > 0) {
          continue;
        }
        if (
          props.fields.filter((x) => {
            return x.fieldName === getFieldNameFromKey(key);
          })[0]?.fieldType === "CHECK"
        ) {
          let checkFound = false;

          console.log("checkboxValues: ", checkboxValues);
          for (const [key2, value2] of Object.entries(checkboxValues)) {
            // console.log(
            //   "checkbox-field key: ",
            //   getCheckboxFieldNameFromKey(key2)
            // );
            // console.log("fields: ", props.fields);
            // console.log(
            //   "field: ",
            //   props.fields.filter((x) => {
            //     return x.fieldName === getCheckboxFieldNameFromKey(key2);
            //   })
            // );
            if (
              key2.includes(
                props.fields.filter((x) => {
                  return x.fieldName === getFieldNameFromKey(key); //checkACWD getting wrong key
                })[0].id
              )
            ) {
              if (value2 === true) {
                checkFound = true;
                continue;
              }
            }
          }
          if (checkFound) {
            continue;
          }
        }

        return false;
      }
    }

    return true;
  };

  const [submitLoad, setSubmitLoad] = useState(false);
  //on save for later go to status 0 and don't require any fields.
  const submitLater = async () => {
    try {
      if (propertyId) {
        setSubmitLoad(true);

        const currProperty = props.properties.filter((el) => {
          return el.id === parseInt(propertyId);
        })[0];

        console.log("statusList: ", statusList);

        const programStatusArr =
          statusList.filter((s) => {
            return s.programId === props.program.id;
          }).length > 0
            ? statusList.filter((s) => {
                return s.programId === props.program.id;
              })
            : null;

        const statusIds = programStatusArr
          ? programStatusArr.map((ps) => {
              return parseInt(ps.statusId);
            })
          : null;

        console.log("StatusIds: ", statusIds); //current logic assumes statusIds array is > 1
        const minId = Math.min(...statusIds); //minId is the id with smallest statusId include statusId 0
        console.log("MinIds: ", minId);

        let nsId = minId;
        if (statusIds.length > 1) {
          // const withoutStatusZero = [statusIds.shift()];
          const withoutStatusZero = statusIds;
          withoutStatusZero.pop();
          console.log("withoutStatusZero: ", withoutStatusZero);
          nsId = Math.min(...withoutStatusZero); //nsId is the id with smallest statusId excluing statusId 0
        } else if (statusIds.length === 1) {
          nsId = statusIds[0];
        }

        //if statusIds array is === 1 then minId wi
        const accountNo = currProperty
          ? currProperty.customerProperty[0].accountNo
          : null;

        const app = await dispatch(
          //need to add next status everytime unlocked is true.
          addApplication({
            customerId: parseInt(localStorage.getItem("user_id")),
            propertyId: parseInt(propertyId),
            programId: props.program.id,
            cost: program.defaultUnitCost,
            grantFundedAmount: null,
            waterSavings: props.program.defaultUnitWaterSavings,
            ACCOUNT_NO: accountNo,
            unlocked: true,
            nextStatus: nsId.toString(),
          })
        );
        for (const [key, value] of Object.entries(values)) {
          if (value !== "") {
            const valueInfo = {
              value: value,
              applicationId: parseInt(app.payload.id),
              fieldId: getFieldIdFromKey(key),
              checkValueId: null,
              multiplier: null,
              lookupName1: null,
              lookupName2: null,
              calcResult: null,
            };

            console.log("value: ", value);
            const resVal = await dispatch(addApplicationValue(valueInfo));
            console.log("value response: ", resVal);
          } else {
            console.log("value Error");
          }
        }

        for (const [key, value] of Object.entries(checkboxValues)) {
          const valueInfo = {
            value: value,
            applicationId: parseInt(app.payload.id),
            fieldId: getFieldIdFromKey(key),
            checkValueId: getCheckValueIdFromKey(key),
            multiplier: null,
            lookupName1: null,
            lookupName2: null,
            calcResult: null,
          };

          await dispatch(addApplicationValue(valueInfo));
        }

        for (const [key, value] of Object.entries(files)) {
          if (!value) return false;
          const tmpData = {
            file: value,
            appId: app.payload.id,
            fileName: value.name,
            referenceId: app.payload.id,
            secondaryId: getSecondaryId(key), // just in case we have Non-required documents as a name or more hyphens.
            referenceType: "application",
            uploader: null,
          };
          uploadDocument(tmpData);
        }

        if (myList.length > 0) {
          myList.forEach(async (x) => {
            try {
              const valueInfo = {
                applicationId: parseInt(app.payload.id),
                fieldId: myListFieldId,
                multiplier: x.multiplier,
                lookupName1: x.lookupName1,
                lookupName2: x.lookupName2,
                calcResult: useMultiplier
                  ? x.multiplier * parseInt(x.inputLabel)
                  : parseInt(x.inputLabel),
                value: x.inputLabel,
                checkValueId: null,
              };

              await dispatch(addApplicationValue(valueInfo));
            } catch (err) {
              console.log(err);
            }
          });
        }

        const statusPayload = {
          statusId: minId,
          applicationId: app.payload.id,
          adminRespond: true,
        };
        await dispatch(addApplicationStatus(statusPayload));
        setSubmitLoad(false);

        props.handleClose();
        dispatch(fetchApplicationValues());
        // dispatch(fetchApplications());
        dispatch(applicationById({ id: localStorage.getItem("user_id") }));
        dispatch(fetchStatusList());
        dispatch(fetchApplicationStatus());
        // dispatch(fetchApplications());

        const toastPayload = {
          index: 0,
          description: 14,
          type: "application",
        };
        setToast(toastPayload);
      } else {
        const toastPayload = {
          index: 1,
          description: 0,
          type: "application",
        };
        setToast(toastPayload);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const uploadDocument = async (input, tmpData) => {
    const formData = new FormData(); //referenceId, fileName, fileLocation, uploader, referenceType
    formData.append("file", input.file);
    formData.append("appId", input.appId);
    formData.append("fileName", input.file.fileName);
    formData.append("referenceId", input.appId);
    formData.append("referenceType", "application");
    formData.append("uploader", localStorage.getItem("user_id"));

    const url =
      process.env.REACT_APP_PRODUCTION === "true"
        ? `https://expressproxy-prod.azurewebsites.net/blobstorage/upload`
        : `http://localhost:3000/blobstorage/upload`;

    // upload the document
    try {
      const res = await axios.post(url, formData, {
        headers: {
          "Content-Type": "application/octet-stream",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setDocumentLink(res.data, input);
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const setDocumentLink = async (data, tmpData) => {
    // add the links to the document
    const url =
      process.env.REACT_APP_PRODUCTION === "true"
        ? `https://expressproxy-prod.azurewebsites.net/reference/add`
        : `http://localhost:3000/reference/add`;

    try {
      const res = await axios.post(
        url,
        {
          referenceId: tmpData.appId,
          referenceType: "application",
          secondaryId: tmpData.secondaryId,
          fileName: data.name,
          fileLocation: data.name,
          uploader: null,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
    } catch (err) {
      console.log(err);
    }

    // update the document list
  };

  const submit = async (e) => {
    try {
      if (validateRequired()) {
        if (propertyId) {
          setSubmitLoad(true);
          const currProperty = props.properties.filter((el) => {
            return el.id === parseInt(propertyId);
          })[0];
          const accountNo = currProperty
            ? currProperty.customerProperty[0].accountNo
            : null;

          const app = await dispatch(
            addApplication({
              customerId: parseInt(localStorage.getItem("user_id")),
              propertyId: parseInt(propertyId),
              programId: props.program.id,
              cost: program.defaultUnitCost,
              grantFundedAmount: null,
              waterSavings: props.program.defaultUnitWaterSavings,
              ACCOUNT_NO: accountNo,
              unlocked: false,
            })
          );

          //values include STRING, NUMBER, DROPDOWN, BOOLEAN
          //values = [field name]-[field id]-[required]
          //ie: test-18-f
          for (const [key, value] of Object.entries(values)) {
            if (value !== "") {
              const valueInfo = {
                value: value,
                applicationId: parseInt(app.payload.id),
                fieldId: getFieldIdFromKey(key),
                checkValueId: null,
                multiplier: null,
                lookupName1: null,
                lookupName2: null,
                calcResult: null,
              };
              await dispatch(addApplicationValue(valueInfo));
            } else {
              console.log("value Error");
            }
          }

          //checkboxValues include CHECK
          for (const [key, value] of Object.entries(checkboxValues)) {
            console.log("checkboxvalues-insert: ", value);
            const valueInfo = {
              value: value,
              applicationId: parseInt(app.payload.id),
              fieldId: getCheckFieldIdFromKey(key),
              checkValueId: getCheckValueIdFromKey(key),
              multiplier: null,
              lookupName1: null,
              lookupName2: null,
              calcResult: null,
            };
            const res = await dispatch(addApplicationValue(valueInfo));
          }

          if (myList.length > 0) {
            myList.forEach(async (x) => {
              try {
                const valueInfo = {
                  applicationId: parseInt(app.payload.id),
                  fieldId: myListFieldId,
                  multiplier: x.multiplier,
                  lookupName1: x.lookupName1,
                  lookupName2: x.lookupName2,
                  calcResult: useMultiplier
                    ? x.multiplier * parseInt(x.inputLabel)
                    : parseInt(x.inputLabel),
                  value: x.inputLabel,
                  checkValueId: null,
                };

                await dispatch(addApplicationValue(valueInfo));
              } catch (err) {
                console.log(err);
              }
            });
          }

          for (const [key, value] of Object.entries(files)) {
            if (!value) return false;
            const tmpData = {
              file: value,
              appId: app.payload.id,
              fileName: value.name,
              referenceId: app.payload.id,
              secondaryId: getSecondaryId(key), // just in case we have Non-required documents as a name or more hyphens.
              referenceType: "application",
              uploader: null,
            };
            uploadDocument(tmpData);
          }

          // await dispatch( //customer should not be able to add water saivngs
          //   addWaterSavings({
          //     applicationId: parseInt(app.payload.id),
          //     value: props.program.defaultUnitWaterSavings,
          //     userId: parseInt(localStorage.getItem("user_id")),
          //     comment: "",
          //     admin: false,
          //   })
          // );
          //fetch first status in program to default application status + 1/ 0 is for saved for later
          if (statusList) {
            // statusPayload logic different in submit later
            const temp = statusList.filter((el) => {
              return el.programId === props.program.id;
            });
            if (temp.length > 0) {
              const milestones = temp.filter((status) => {
                return status.milestone === true;
              });
              if (milestones.length > 0) {
                const statusIdArray = milestones.map((status) => {
                  return parseInt(status.statusId);
                });

                const minId = Math.min(...statusIdArray);

                const statusPayload = {
                  statusId: minId,
                  applicationId: app.payload.id,
                  adminRespond: true,
                };

                await dispatch(addApplicationStatus(statusPayload));
                const status =
                  milestones?.filter((x) => {
                    return parseInt(x.statusId) === minId;
                  }).length > 0
                    ? milestones?.filter((x) => {
                        return parseInt(x.statusId) === minId;
                      })[0]
                    : null;

                if (status) {
                  const statusLog = {
                    statusName: status.name,
                    statusNumber: status.number,
                    userId: null,
                    comment: "",
                    applicationId: app.payload.id,
                    staffAssignmentId: null,
                  };

                  const res2 = await dispatch(addStatusLog(statusLog));
                }
                dispatch(fetchApplicationValues());
                // dispatch(fetchApplications());
                dispatch(fetchStatusList());
                dispatch(fetchApplicationStatus());
              } else {
                const statusPayload = {
                  statusId: parseInt(temp[0].statusId),
                  applicationId: app.payload.id,
                  adminRespond: true,
                };
                await dispatch(addApplicationStatus(statusPayload));

                dispatch(fetchApplicationValues());
                // dispatch(fetchApplications());
                dispatch(fetchStatusList());
                dispatch(fetchApplicationStatus());
              }
            }
          }

          let res1 = await dispatch(
            getSubmissionEmailTemplate({ id: props.program.id })
          );

          if (
            res1.payload.data &&
            process.env.REACT_APP_PRODUCTION === "true"
          ) {
            let lcVars = res1.payload.data.bodyText
              .replace("[Application ID]", "[application id]")
              .replace("[Cost]", "[cost]")
              .replace("[Property Address]", "[property address]");

            let bodyTextFinal = lcVars
              .replace("[application id]", app.payload.id)
              .replace("[cost]", `$${program.defaultUnitCost}`)
              .replace("[property address]", selectedAddress);

            if (process.env.REACT_APP_PRODUCTION === "true") {
              const res = await axios.post(
                `https://expressproxy-prod.azurewebsites.net/bawsca/emails/send`,
                {
                  subject: `Application ${app.payload.id} (${
                    programs.filter((x) => {
                      return x.id === props.program.id;
                    })[0].programName
                  }) has been submitted in the Water Savings Center`,
                  body: `Application ${app.payload.id} (${
                    programs.filter((x) => {
                      return x.id === props.program.id;
                    })[0].programName
                  }) has been submitted in the Water Savings Center`,
                  to: ["water.cons@acwd.com"], //"water.cons@acwd.com"
                  fromName: "ACWD Water Savings Center",
                },
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  },
                }
              );
            }
          }

          // await axios.post(
          //   `https://expressproxy-prod.azurewebsites.net/bawsca/emails/send`,
          //   {
          //     subject: `${res1.payload.data.subjectLine}`,
          //     body: `${bodyTextFinal}`,
          //     to: [localStorage.getItem("email")],
          //     fromName: featureFlags.appName,
          //   },
          //   {
          //     headers: {
          //       Authorization: `Bearer ${localStorage.getItem("token")}`,
          //     },
          //   }
          // );

          //send email
          // if (process.env.REACT_APP_PRODUCTION === "true") {
          //   const res = await axios.post(
          //     `https://expressproxy-prod.azurewebsites.net/bawsca/emails/send`,
          //     {
          //       subject: `Application ${app.payload.id} (${
          //         programs.filter((x) => {
          //           return x.id === props.program.id;
          //         })[0].programName
          //       }) has been submitted in the Water Savings Center`,
          //       body: `Application ${app.payload.id} (${
          //         programs.filter((x) => {
          //           return x.id === props.program.id;
          //         })[0].programName
          //       }) has been submitted in the Water Savings Center`,
          //       to: ["test@test.com"], //"water.cons@acwd.com"
          //       fromName: "EMWD Water Savings Center",
          //     },
          //     {
          //       headers: {
          //         Authorization: `Bearer ${localStorage.getItem("token")}`,
          //       },
          //     }
          //   );
          // }

          const toastPayload = {
            index: 0,
            description: 13,
            type: "application",
          };
          setSubmitLoad(false);

          setToast(toastPayload);
          props.handleClose();
          dispatch(fetchApplicationValues());
          // dispatch(fetchApplications());
          dispatch(applicationById({ id: localStorage.getItem("user_id") }));
          dispatch(fetchStatusList());
          dispatch(fetchApplicationStatus());
        } else {
          const toastPayload = {
            index: 1,
            description: 0,
            type: "application",
          };
          setToast(toastPayload);
        }
      } else {
        const toastPayload = {
          index: 1,
          description: 1,
          type: "application",
        };
        setToast(toastPayload);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // function isDateLaterThanToday(date) {
  //   // Convert the input date to a standard format (YYYY-MM-DD)
  //   const formatDate = (date) => {
  //     const [month, day, year] = date.split("/").map(Number);
  //     // Assuming the year is in the 2000s if it's less than 50, otherwise in the 1900s
  //     const fullYear = year < 50 ? 2000 + year : 1900 + year;
  //     return new Date(fullYear, month - 1, day);
  //   };

  //   const inputDate = formatDate(date);
  //   const currentDate = new Date();

  //   // Compare the input date with the current date
  //   return inputDate > currentDate;
  // }

  const filterCP = (payload) => {
    console.log("payload.moveInOut: ", payload);
    if (payload.customerProperty.accountNo && payload.moveInOut) {
      return (
        payload.moveInOut.cutoffDt === "NULL" ||
        payload.moveInOut.cutoffDt === ""
      );
    }
    if (
      payload.customerProperty.singleHomeFamily &&
      props.program.singleFamilyHome
    ) {
      return true;
    } else if (
      payload.customerProperty.commercial &&
      props.program.commercial
    ) {
      return true;
    } else if (
      payload.customerProperty.residential &&
      props.program.residential
    ) {
      return true;
    } else if (payload.customerProperty.landscape && props.program.landscape) {
      return true;
    } else if (
      payload.customerProperty.multiFamilyComplex &&
      props.program.multiFamilyComplex
    ) {
      return true;
    }
    return false;
  };

  return (
    <Backdrop
      onClick={(e) => {
        e.stopPropagation();
        props.openWarningModal();
      }}
    >
      <motion.div
        className="rounded w-8/12 max-h-[66.67%] overflow-auto bg-white dark:bg-wc-gradient bg-cover text-wc-green dark:text-white shadow-md font-sans relative"
        onClick={(e) => e.stopPropagation()}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
        transition={{ duration: 0.5 }}
      >
        <div className="w-full rounded-t  flex flex-col mb-4">
          <div className="flex gap-2 items-center justify-center rounded-t sticky top-0 z-10">
            <h2 className="text-start  w-full text-white rounded-t tracking-wider bg-blue-reef p-4">
              {props.program.programName}
              {props.program.thirdParty ? "" : " Application"}
            </h2>
          </div>

          {!submitLoad ? (
            <>
              <div class="p-12">
                {!props.program.thirdParty &&
                  localStorage.getItem("user_id") != 123 && (
                    <div className="flex flex-col items-start gap-1 ">
                      <span className="text-blue-reef font-bold">
                        Select the property associated with this application
                      </span>
                      <select
                        className="border-gray-200 border focus:outline-none rounded h-[2.5rem] text-lg dark:text-black w-6/12"
                        onChange={(e) => {
                          const selectedEl = JSON.parse(
                            e.target.selectedOptions[0].getAttribute("data-el")
                          );
                          setPropertyId(selectedEl.id);
                          setSelectedAddress(
                            formatFullAddress([
                              selectedEl.mfcStreetNo,
                              selectedEl.streetPfxDir,
                              selectedEl.mfcStreetName,
                              selectedEl.streetNmSfx,
                              selectedEl.streetSfxDir,
                              selectedEl.secAddrId,
                              selectedEl.secAddrRange,
                              selectedEl.city,
                              selectedEl.provinceCd,
                              selectedEl.postalCode,
                            ])
                          );
                        }}
                      >
                        <option disabled selected>
                          Select A Property
                        </option>
                        {customerProperties?.length > 0 &&
                          customerProperties?.map((el, index) => {
                            if (
                              filterCP({
                                moveInOut: el.location
                                  ? el.location.moveInOut[0]
                                  : null, //grab first moveInOut row, might need to be filter out better in future
                                customerProperty: el.customerProperty[0],
                              })
                            ) {
                              return (
                                <option
                                  key={index}
                                  value={el.id}
                                  data-el={JSON.stringify(el)}
                                >
                                  {formatFullAddress([
                                    el?.mfcStreetNo,
                                    el?.streetPfxDir,
                                    el?.mfcStreetName,
                                    el?.streetNmSfx,
                                    el?.streetSfxDir,
                                    el?.secAddrId,
                                    el?.secAddrRange,
                                    el?.city,
                                    el?.provinceCd,
                                    el?.postalCode,
                                  ])}
                                </option>
                              );
                            }
                          })}
                      </select>
                      <div className="w-6/12 mb-2">
                        <span className="text-gray-400 ">
                          You must first register your property to your Water
                          Savings Center account in "My Profile" to start an
                          application. If your property is not showing in the
                          dropdown, it may not meet the program eligibility
                          property type or the account may be inactive.
                        </span>
                      </div>
                    </div>
                  )}

                <div className="pb-12">
                  <ReactQuill
                    disabled
                    className="w-full rounded  text-black"
                    value={program?.description}
                    readOnly={true}
                    modules={{ toolbar: false }}
                  />
                </div>

                {/* {!props.program.thirdParty &&
                  localStorage.getItem("user_id") != 123 && ( */}

                {!props.program.thirdParty &&
                  localStorage.getItem("user_id") != 123 && (
                    <>
                      <div className="flex items-center gap-2 mb-4">
                        <span className="text-red-500">*</span>
                        <span>Indicates a Required Field or Upload</span>
                      </div>
                      <div className="flex flex-col gap-6">
                        <div className="flex flex-wrap gap-6">
                          {fields && fields}
                        </div>
                        <div className="flex flex-col  gap-6">
                          {uploads && uploads}
                        </div>
                      </div>
                    </>
                  )}
              </div>
              {localStorage.getItem("user_id") != 123 && (
                <div className="flex  w-full justify-center">
                  {!props.program.thirdParty && (
                    <div className="mx-auto p-8">
                      <Button
                        variant="outline"
                        className="bg-gray-400  text-white rounded"
                        onClick={() => {
                          submitLater();
                        }}
                      >
                        Save for later
                      </Button>

                      <Button
                        variant="outline"
                        className="bg-green-reef text-white rounded"
                        onClick={() => {
                          submit();
                        }}
                      >
                        Submit
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </>
          ) : (
            <div className="flex justify-center items-center gap-4 h-[20rem]">
              <span>Saving application</span>
              <HashLoader size={40} color={"#A3AEB9"} />
            </div>
          )}
        </div>
      </motion.div>
    </Backdrop>
  );
};

export default ApplicationForm;
