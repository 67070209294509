import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Moment from "react-moment";
// import { updateBudget } from "../../features/programBudgetLog";
import { selectAllUsers } from "../../features/user";
import { BsFillPencilFill, BsFillTrashFill } from "react-icons/bs";
import { fetchApplicationProperties } from "../../features/applicationProperties";
import { formatFullAddress } from "../../utils/formatAddress";

import { useDispatch, useSelector } from "react-redux";

const axios = require("axios").default;
axios.defaults.headers.common["Client"] = "ACWD";

const AssociatedLocation = ({ props }) => {
  console.log("associated-location-props: ", props);
  // const dispatch = useDispatch();

  const deleteLocation = async (e) => {
    try {
      const url =
        process.env.REACT_APP_PRODUCTION === "true"
          ? `https://expressproxy-prod.azurewebsites.net/application/property/delete`
          : `http://localhost:3000/application/property/delete`;

      const res = await axios.post(
        url,
        { id: props.id },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      props.fetchApplicationProperties(props.applicationId);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <tr
      className={`rounded-lg dark:text-white text-black text-start p-3 cursor-pointer hover:bg-blue-50 `}
    >
      <td className="p-3 dark:text-white whitespace-nowrap">
        {props.property.id}
      </td>

      <td className="p-3 dark:text-white whitespace-nowrap">
        {props.property?.locationNo}
      </td>
      <td className="p-3 dark:text-white whitespace-nowrap">
        {formatFullAddress([
          props?.property?.mfcStreetNo,
          props?.property?.streetPfxDir,
          props?.property?.mfcStreetName,
          props?.property?.streetNmSfx,
          props?.property?.streetSfxDir,
          props?.property?.secAddrId,
          props?.property?.secAddrRange,
          props?.property?.city,
          props?.property?.provinceCd,
          props?.property?.postalCode,
        ])}
      </td>
      <td className="p-3 font-bold  dark:text-white r whitespace-nowrap">
        <BsFillTrashFill
          size="18"
          className="cursor-pointer dark:text-white hover:text-red-400"
          onClick={() => {
            deleteLocation();
          }}
        />
      </td>
    </tr>
  );
};

export default AssociatedLocation;
