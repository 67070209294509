import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
const axios = require("axios").default;

axios.defaults.headers.common["Client"] = "ACWD";

const initStateValue = {
  properties: [],
  status: "idle",
  propertyCount: null,
  propertyCountStatus: "idle",
  error: null,
};

const prod_base_url = "https://expressproxy-prod.azurewebsites.net/property";
const local_base_url = "http://localhost:3000/property";

const active_base_url =
  process.env.REACT_APP_PRODUCTION === "true" ? prod_base_url : local_base_url;

export const fetchProperties = createAsyncThunk(
  "property/fetchProperties",
  async () => {
    try {
      console.log("property-fetch");
      const res = await axios.post(
        `${active_base_url}/fetch-all`,
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const properties = res.data.data;
      return properties;
    } catch (err) {
      console.log(err);
    }
  }
);

export const propertyCount = createAsyncThunk(
  "property/count",
  async (payload) => {
    try {
      const res = await axios.post(
        // `http://localhost:3000/property/count`, //rewrite as dynamic routing
        "https://expressproxy-prod.azurewebsites.net/property/count",
        payload
      );
      const count = res.data.data;
      console.log("property-count", count);
      return count;
    } catch (err) {
      console.log(err);
    }
  }
);
export const fetchPropertiesPublic = createAsyncThunk(
  "property/fetchProperties",
  async () => {
    try {
      console.log("property-fetch");
      const res = await axios.post(
        `${active_base_url}/public/fetch-all`,
        {},
        {}
      );
      const properties = res.data.data;
      return properties;
    } catch (err) {
      console.log(err);
    }
  }
);

export const createProperty = createAsyncThunk(
  "property/createProperty",
  async (property) => {
    try {
      const res = await axios.post(`${active_base_url}/add`, property, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      const addProperty = res.data.data;
      console.log("addProperty", addProperty);
      return addProperty;
    } catch (err) {
      console.log(err);
    }
  }
);
export const registerProperty = createAsyncThunk(
  "property/registerProperty",
  async (property) => {
    try {
      console.log("feature-property: ", property);
      const res = await axios.post(`${active_base_url}/registration`, property);
      const addProperty = res.data.data;
      console.log("register property", addProperty);
      return addProperty;
    } catch (err) {
      console.log("register-property-err: ", err);
    }
  }
);

export const fetchPropertyDropdown = createAsyncThunk(
  "property/drodpdown",
  async (payload) => {
    try {
      const res = await axios.post(
        `${active_base_url}/dropdown/fetch`,
        payload
      );
      const properties = res.data.data;
      return properties;
    } catch (err) {
      console.log(err);
    }
  }
);

export const propertySlice = createSlice({
  name: "property",
  initialState: { value: initStateValue },
  reducers: {
    clearProperties: (state, action) => {
      state.value = initStateValue;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchProperties.pending, (state, action) => {
        state.value.status = "loading";
        state.value.properties = action.payload;
      })
      .addCase(fetchProperties.fulfilled, (state, action) => {
        state.value.status = "succeeded";
        state.value.properties = action.payload;
      })
      .addCase(fetchProperties.rejected, (state, action) => {
        state.value.status = "failed";
        state.value.error = action.error.message;
      })
      .addCase(propertyCount.pending, (state, action) => {
        state.value.propertyCountStatus = "loading";
        state.value.propertyCount = action.payload;
      })
      .addCase(propertyCount.fulfilled, (state, action) => {
        state.value.propertyCountStatus = "succeeded";
        state.value.propertyCount = action.payload;
      })
      .addCase(propertyCount.rejected, (state, action) => {
        state.value.propertyCountStatus = "failed";
        state.value.error = action.error.message;
      });
  },
});

export const selectAllProperties = (state) => state.property.value.properties;
export const getPropertyStatus = (state) => state.property.value.status;
export const selectPropertyCount = (state) =>
  state.property.value.propertyCount;
export const propertyCountStatus = (state) =>
  state.property.value.propertyCountStatus;

export const { clearProperties } = propertySlice.actions;

export default propertySlice.reducer;
