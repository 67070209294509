import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { checkEmptyFields } from "../../utils/FormValidation";
import { loginUser } from "../../features/user";
import { loginCustomer } from "../../features/customer";
import { motion } from "framer-motion";
import { clearProgramUploads } from "../../features/programUpload";
import { clearProgramFields } from "../../features/programField";
import { clearPrograms } from "../../features/program";
import { clearValues } from "../../features/applicationValue";
import { clearApplications } from "../../features/application";
import { clearDropdownValues } from "../../features/dropdownValue";
import { clearApplicationProperties } from "../../features/applicationProperties";
import { clearApplicationStatus } from "../../features/applicationStatus";
import { clearProperties } from "../../features/properties";
import { clearStatusList } from "../../features/status";
import { logout } from "../../features/user";
import { logoutCustomer } from "../../features/customer";
import { RiAdminFill } from "react-icons/ri";
// import Visibility from "@material-ui/icons/Visibility";
// import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { LuEye } from "react-icons/lu";
import { LuEyeOff } from "react-icons/lu";

const axios = require("axios").default;

axios.defaults.headers.common["Client"] = "ACWD";

axios.defaults.headers.common["Client"] = "ACWD";

const dropIn = {
  hidden: { y: "-100vh", opacity: 0 },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: { y: "100vh", opacity: 0 },
};
// const search = async (payload) => {
//   try {
//     const res = await axios.post(`http://localhost:3000/location/search`, {
//       searchParam: payload,
//     });
//   } catch (err) {
//     console.log(err);
//   }
// };
const LoginForm = ({ props }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [formErrors, setFormErrors] = useState({});

  const [loginCredentials, setLoginCredentials] = useState({
    email: "",
    password: "",
    accountType: "",
  });

  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    console.log("showPassword: ", showPassword);
  }, [showPassword]);

  // //clear user state
  const clearRedux = () => {
    dispatch(logout());
    dispatch(logoutCustomer());
    localStorage.clear();
    dispatch(clearProperties());
    dispatch(clearStatusList());
    dispatch(clearProgramUploads());
    dispatch(clearProgramFields());
    dispatch(clearPrograms());
    dispatch(clearValues());
    dispatch(clearApplications());
    dispatch(clearApplicationStatus());
    dispatch(clearDropdownValues());
    dispatch(clearApplicationProperties());
    navigate("/");
  };

  const signInUser = async (e) => {
    try {
      e.preventDefault();
      clearRedux();
      console.log("LOGIN");
      let res = null;
      if (checkEmptyFields(loginCredentials).empty === false) {
        if (loginCredentials.accountType === "admin") {
          console.log("LOGIN:USER");
          res = await dispatch(
            loginUser({
              email: loginCredentials.email,
              password: loginCredentials.password,
            })
          );
          console.log("signIIN: ", res);
          if (res.payload) {
            navigate("dashboard");
          }
        } else {
          res = await dispatch(
            loginCustomer({
              email: loginCredentials.email,
              password: loginCredentials.password,
            })
          );

          if (res.payload) {
            if (res.payload.verified) {
              navigate("dashboard");
            } else {
              props.openConfirmModal(loginCredentials.email);
            }
          } else {
          }
        }
      } else {
        setFormErrors(checkEmptyFields(loginCredentials).errors);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    setLoginCredentials((prev) => ({
      ...prev,
      accountType: props.type.toLowerCase(),
    }));
  }, [props.type]);

  return (
    <motion.div
      className="h-[25rem] w-[40rem] rounded-md bg-gray-50 font-sans dark:text-white"
      onClick={(e) => e.stopPropagation()}
      // variants={dropIn}
      initial="hidden"
      animate="visible"
      exit="exit"
      transition={{ duration: 0.5 }}
    >
      {props.type === "ADMIN" ? (
        <form
          className={`flex h-full w-full flex-col rounded-xl px-20 py-8 ${
            props.type === "ADMIN" ? "bg-white" : "bg-white"
          } mb-4 gap-4 text-white dark:bg-gray-700`}
        >
          {props.type === "ADMIN" && (
            <div className="flex items-center gap-2">
              <h2 className="text-center font-semibold text-wc-green">
                Admin login
              </h2>
              <div className="flex text-wc-green">
                <RiAdminFill size="18" />
              </div>

              {/* <ThemeButton /> */}
            </div>
          )}

          <div className="email flex flex-col gap-2 text-left text-lg text-wc-green">
            <input
              type="text "
              className="h-[2.5rem] w-full rounded border text-black pl-2"
              placeholder="Email Address (Username)"
              onChange={(e) => {
                setLoginCredentials((prev) => ({
                  ...prev,
                  email: e.target.value,
                }));
              }}
            />
            {formErrors.email && (
              <span className="text-red-500">{formErrors.email}</span>
            )}
          </div>
          <div className="password flex flex-col items-start text-left text-wc-green">
            {/* <div className="flex items-center"></div> */}
            <div className="flex w-full items-center rounded border bg-white">
              <input
                type={showPassword ? "text" : "password"}
                className="h-[2.5rem] w-full rounded border text-lg text-black pl-2"
                placeholder=" Password"
                onChange={(e) => {
                  setLoginCredentials((prev) => ({
                    ...prev,
                    password: e.target.value,
                  }));
                }}
              />
              {showPassword ? (
                <LuEye
                  size="24"
                  className="cursor-pointer"
                  onClick={() => {
                    setShowPassword(false);
                  }}
                />
              ) : (
                <LuEyeOff
                  size="24"
                  className="cursor-pointer"
                  onClick={() => {
                    setShowPassword(true);
                  }}
                />
              )}
            </div>
            {formErrors.password && (
              <span className="text-red-500">{formErrors.password}</span>
            )}

            <button
              className={`border-0 border-black ${
                props.type === "ADMIN" ? "bg-teal-reef" : "bg-blue-reef"
              } mb-6 mt-6 h-button w-full cursor-pointer rounded text-white hover:opacity-80`}
              type="submit"
              onClick={(e) => {
                console.log("Sing IN");
                signInUser(e);
              }}
            >
              Sign in
            </button>
            <div className="mb-6 flex justify-center">
              <div className="flex gap-4">
                <div className="flex gap-2">
                  <button
                    className="rounded bg-white underline hover:text-blue-500"
                    onClick={(e) => {
                      props.setLoginController((prev) => ({
                        ...prev,
                        loginType: "CUSTOMER",
                      }));
                    }}
                  >
                    Sign in as customer
                  </button>
                </div>

                <div className="flex gap-1">
                  <input type="checkbox" />
                  <span className="">Remember me</span>
                </div>
              </div>
              {props.type === "CUSTOMER" && (
                <>
                  <span
                    onClick={() => {
                      props.openShowRegister();
                    }}
                    className="ml-5 cursor-pointer underline"
                  >
                    Register a new account
                  </span>
                </>
              )}
            </div>
            <div className="flex gap-2">
              <div className="flex cursor-pointer gap-1 hover:text-blue-400">
                <span
                  className="underline"
                  onClick={() => {
                    navigate("forgotPassword/admin");
                  }}
                >
                  Forgot your password?
                </span>
              </div>
              <div className="flex justify-center gap-1">
                <span>If you need help please</span>
                <a
                  href="https://www.acwd.org/forms.aspx?fid=116"
                  className="cursor-pointer underline hover:text-blue-400"
                >
                  contact us.
                </a>
              </div>
            </div>
          </div>
        </form>
      ) : (
        <form
          className={`mb-4 flex h-full w-full flex-col gap-4 rounded-xl bg-white px-20 py-8 text-white dark:bg-gray-700`}
        >
          <div className="flex items-center gap-2">
            <h2 className="text-center font-semibold text-wc-green">
              {props.type === "ADMIN" ? "Admin Login" : ""}
            </h2>
          </div>

          <div className="email flex flex-col gap-2 text-left text-lg text-wc-green">
            <input
              type="text "
              className="h-[2.5rem] w-full rounded border text-black outline-none pl-2"
              placeholder="Email Address (Username)"
              onChange={(e) => {
                setLoginCredentials((prev) => ({
                  ...prev,
                  email: e.target.value,
                }));
              }}
            />
            {formErrors.email && (
              <span className="text-red-500">{formErrors.email}</span>
            )}
          </div>
          <div className="password flex flex-col items-start text-left text-wc-green">
            <div className="flex w-full items-center rounded border bg-white">
              <input
                type={showPassword ? "text" : "password"}
                className="h-[2.5rem] w-full rounded text-lg text-black outline-none pl-2"
                placeholder=" Password"
                onChange={(e) => {
                  setLoginCredentials((prev) => ({
                    ...prev,
                    password: e.target.value,
                  }));
                }}
              />
              {showPassword ? (
                <LuEye
                  size="24"
                  className="cursor-pointer"
                  onClick={() => {
                    setShowPassword(false);
                  }}
                />
              ) : (
                <LuEyeOff
                  size="24"
                  className="cursor-pointer"
                  onClick={() => {
                    setShowPassword(true);
                  }}
                />
              )}
            </div>
            {formErrors.password && (
              <span className="text-red-500">{formErrors.password}</span>
            )}

            <button
              className={`border-0 border-black ${
                props.type === "ADMIN" ? "bg-teal-reef" : "bg-blue-reef"
              } mb-6 mt-6 h-button w-full cursor-pointer rounded text-white hover:opacity-80`}
              type="submit"
              onClick={(e) => {
                signInUser(e);
              }}
            >
              Sign in
            </button>

            <div className="mb-6 flex justify-center">
              <div className="flex gap-4">
                <button
                  className="bg-gray-white rounded underline hover:text-blue-500"
                  onClick={(e) => {
                    props.setLoginController((prev) => ({
                      ...prev,
                      loginType: "ADMIN",
                    }));
                  }}
                >
                  Sign in as admin
                </button>
                <div className="flex gap-1">
                  <input type="checkbox" />
                  <span className="">Remember me</span>
                </div>
              </div>

              {props.type === "CUSTOMER" && (
                <>
                  <span
                    onClick={() => {
                      props.openShowRegister();
                    }}
                    className="ml-5 cursor-pointer underline hover:text-blue-500"
                  >
                    Register a new account
                  </span>
                </>
              )}
            </div>
            <div className="flex gap-2">
              <div className="flex cursor-pointer gap-1 hover:text-blue-400">
                <span
                  className="underline"
                  onClick={() => {
                    navigate("forgotPassword/customer");
                  }}
                >
                  Forgot your password?
                </span>
              </div>
              <div className="flex justify-center gap-1">
                <span>If you need help please</span>
                <a
                  href="https://www.acwd.org/forms.aspx?fid=116"
                  className="cursor-pointer underline hover:text-blue-400"
                >
                  contact us.
                </a>
              </div>
            </div>
          </div>
        </form>
      )}
    </motion.div>
  );
};

export default LoginForm;
