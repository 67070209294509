import React from "react";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
const axios = require("axios").default;

axios.defaults.headers.common["Client"] = "ACWD";

const initStateValue = {
  applications: [],
  customerApplications: [],
  applicationStatus: "idle",
  applicationError: null,
  status: "idle",
  error: null,
  count: null,
  countStatus: "idle",
  countError: null,
};

const prod_base_url = "https://expressproxy-prod.azurewebsites.net/application";
const local_base_url = "http://localhost:3000/application";
const active_base_url =
  process.env.REACT_APP_PRODUCTION === "true" ? prod_base_url : local_base_url;

export const fetchApplications = createAsyncThunk(
  "application/fetchApplications",
  async () => {
    try {
      const res = await axios.post(
        `${active_base_url}/fetch-all`,
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const applications = res.data.data;
      return applications;
    } catch (err) {
      console.log(err);
    }
  }
);

export const fetchApplicationCountByProgram = createAsyncThunk(
  "application/count-by-program",
  async (payload) => {
    try {
      const res = await axios.post(
        `${active_base_url}/count-by-program`,
        payload,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const count = res.data.data;
      return count;
    } catch (err) {
      console.log(err);
    }
  }
);

export const fetchPropertyModalApplications = createAsyncThunk(
  "application/count-by-program",
  async (payload) => {
    try {
      const res = await axios.post(
        `${active_base_url}/fetch-property-modal`,
        payload,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const applications = res.data.data;
      return applications;
    } catch (err) {
      console.log(err);
    }
  }
);

export const fetchCustomerModalApplications = createAsyncThunk(
  "application/count-by-program",
  async (payload) => {
    try {
      const res = await axios.post(
        `${active_base_url}/fetch-customer-modal`,
        payload,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const applications = res.data.data;
      return applications;
    } catch (err) {
      console.log(err);
    }
  }
);

export const applicationByPropertyId = createAsyncThunk(
  "application/fetch-by-property-id",
  async (payload) => {
    try {
      const res = await axios.post(
        `${active_base_url}/fetch-by-property-id`,
        payload,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const application = res.data.data;
      return application;
    } catch (err) {
      console.log(err);
    }
  }
);

export const applicationByProgramId = createAsyncThunk(
  "application/fetch-by-program-id",
  async (payload) => {
    try {
      const res = await axios.post(
        `${active_base_url}/fetch-by-program-id`,
        payload,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const application = res.data.data;
      return application;
    } catch (err) {
      console.log(err);
    }
  }
);

export const getApplicationById = createAsyncThunk(
  "application/fetch-application-by-id",
  async (payload) => {
    try {
      const res = await axios.post(
        `${active_base_url}/fetch-by-application-id`,
        payload,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const application = res.data.data;
      return application;
    } catch (err) {
      console.log(err);
    }
  }
);

export const paginateByStatusName = createAsyncThunk(
  "application/paginate-by-status-name",
  async (payload) => {
    try {
      const res = await axios.post(
        `${active_base_url}/paginate-by-status-name`,

        payload,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const application = res.data.data;
      return application;
    } catch (err) {
      console.log(err);
    }
  }
);

export const searchByFilterText = createAsyncThunk(
  "application/search-by-filter-text",
  async (payload) => {
    try {
      const res = await axios.post(
        `${active_base_url}/table/search-by-filter-text`,

        payload,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const application = res.data.data;
      return application;
    } catch (err) {
      console.log(err);
    }
  }
);

export const fetchInitialApplications = createAsyncThunk(
  "application/addApplication",
  async () => {
    try {
      const res = await axios.post(
        `${active_base_url}/table/initial-rows`,
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const applications = res.data.data;
      return applications;
    } catch (err) {
      console.log(err);
    }
  }
);

export const searchByAssignmentId = createAsyncThunk(
  "application/search-by-assignment-id",
  async (payload) => {
    try {
      const res = await axios.post(
        `${active_base_url}/table/search-by-assignment-id`,

        payload,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const application = res.data.data;
      return application;
    } catch (err) {
      console.log(err);
    }
  }
);

export const applicationById = createAsyncThunk(
  "application/fetchApplication",
  async (id) => {
    try {
      const res = await axios.post(`${active_base_url}/fetch-by-id`, id, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      const application = res.data.data;
      return application;
    } catch (err) {
      console.log(err);
    }
  }
);

export const addApplication = createAsyncThunk(
  "application/addApplication",
  async (application) => {
    try {
      console.log("applications", application);
      const res = await axios.post(
        `${active_base_url}/add`,

        application,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const addApplication = res.data.data;
      return addApplication;
    } catch (err) {
      console.log(err);
    }
  }
);

export const fetchAssociatedApplications = createAsyncThunk(
  "application/fetch-associated",
  async (payload) => {
    try {
      const res = await axios.post(
        `${active_base_url}/fetch-associated`,
        payload,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const applications = res.data.data;
      return applications;
    } catch (err) {
      console.log(err);
    }
  }
);

export const fetchDisplayData = createAsyncThunk(
  "application/fetch-display-data",
  async () => {
    try {
      const res = await axios.post(
        `${active_base_url}/fetch-display-data`,

        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data = res.data.data;
      return data;
    } catch (err) {
      console.log(err);
    }
  }
);

export const exportApplicationByProgramId = createAsyncThunk(
  "application/export-by-program-id",
  async (payload) => {
    try {
      const res = await axios.post(
        `${active_base_url}/export-by-program-id`,
        payload,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const application = res.data.data;
      return application;
    } catch (err) {
      console.log(err);
    }
  }
);

export const fetchApplicationChunks = createAsyncThunk(
  "application/fetchApplicationChunks",
  async (payload) => {
    try {
      const res = await axios.post(
        `${active_base_url}/fetch-in-chunks`,
        payload,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      console.log("fetch-application: ", res.data.data);
      const application = res.data.data;
      return application;
    } catch (err) {
      console.log(err);
    }
  }
);
export const fetchApplicationCount = createAsyncThunk(
  "application/count",
  async () => {
    try {
      const res = await axios.post(
        `${active_base_url}/count`,
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const count = res.data.data;
      return count;
    } catch (err) {
      console.log(err);
    }
  }
);

export const applicationSlice = createSlice({
  name: "application",
  initialState: { value: initStateValue },
  reducers: {
    clearApplications: (state, action) => {
      state.value = initStateValue;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchApplications.pending, (state, action) => {
        state.value.status = "loading";
        state.value.applications = action.payload;
      })
      .addCase(fetchApplications.fulfilled, (state, action) => {
        state.value.status = "succeeded";
        state.value.applications = action.payload;
      })
      .addCase(fetchApplications.rejected, (state, action) => {
        state.value.status = "failed";
        state.value.error = action.error.message;
      })
      .addCase(fetchApplicationCount.pending, (state, action) => {
        state.value.countStatus = "loading";
        state.value.count = action.payload;
      })
      .addCase(fetchApplicationCount.fulfilled, (state, action) => {
        state.value.countStatus = "succeeded";
        state.value.count = action.payload;
      })
      .addCase(fetchApplicationCount.rejected, (state, action) => {
        state.value.countStatus = "failed";
        state.value.countError = action.error.message;
      })
      .addCase(applicationById.pending, (state, action) => {
        state.value.applicationStatus = "loading";
        state.value.customerApplications = action.payload;
      })
      .addCase(applicationById.fulfilled, (state, action) => {
        state.value.applicationStatus = "succeeded";
        state.value.customerApplications = action.payload;
      })
      .addCase(applicationById.rejected, (state, action) => {
        state.value.applicationStatus = "failed";
        state.value.applicationError = action.error.message;
      })
      .addCase(fetchInitialApplications.pending, (state, action) => {
        state.value.initialApplicationsStatus = "loading";
        state.value.initialApplications = action.payload;
      })
      .addCase(fetchInitialApplications.fulfilled, (state, action) => {
        state.value.initialApplicationsStatus = "succeeded";
        state.value.initialApplications = action.payload;
      })
      .addCase(fetchInitialApplications.rejected, (state, action) => {
        state.value.initialApplicationsStatus = "failed";
        state.value.initialApplicationsError = action.error.message;
      });
  },
});

export const selectAllApplications = (state) =>
  state.application.value.applications;
export const getApplicationStatus = (state) => state.application.value.status;
export const selectAllCustomerApplications = (state) =>
  state.application.value.customerApplications;
export const getCustomerApplicationStatus = (state) =>
  state.application.value.applicationStatus;
export const selectApplicationCount = (state) => state.application.value.count;
export const getApplicationCountStatus = (state) =>
  state.application.value.countStatus;
export const selectInitialApplications = (state) =>
  state.application.value.initialApplications;
export const getInitialApplicationsStatus = (state) =>
  state.application.value.initialApplicationsStatus;

export const { clearApplications } = applicationSlice.actions;

export default applicationSlice.reducer;
