import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import {
  addProgram,
  fetchPrograms,
  updateProgram,
} from "../../features/program";
import { addBudget } from "../../features/programBudgetLog";
import { checkEmptyFields } from "../../utils/FormValidation";
import { useDispatch } from "react-redux";
import { setToast } from "../../utils/ToastNotification";
import { fetchApplications } from "../../features/application";
import { fetchApplicationStatus } from "../../features/applicationStatus";
import { fetchStatusList } from "../../features/status";
import CurrencyInput from "react-currency-input-field";
import ReactQuill, { Quill } from "react-quill";
import { BsFillTrashFill } from "react-icons/bs";
import {
  referenceById,
  downloadReference,
  deleteReference,
} from "../../features/fileReference";
import { Button } from "../Ui/button";
import { MdFileUpload } from "react-icons/md";
import defaultPicture from "../../assets/Pictures-and-organization-logo-default-program-photo.jpg";
import { MdCancel } from "react-icons/md";
import ProgramImageModal from "./ProgramImageModal";

const axios = require("axios").default;

axios.defaults.headers.common["Client"] = "ACWD";

axios.defaults.headers.common["Client"] = "ACWD";

const slideIn = {
  hidden: { x: "100vh", opacity: 0 },
  visible: {
    x: "0",
    opacity: 1,
    transition: {
      duration: 0.5,
      type: "spring",
      damping: 100,
      stiffness: 500,
    },
  },
  exit: { x: "-100vh", opacity: 0 },
};

const ProgramInformation = ({ props }) => {
  const dispatch = useDispatch();
  console.log("programInformation", props.program);
  const initialProgram = {
    programName: "",
    defaultUnitCost: "",
    defaultUnit: "per application",
    programBudget: "",
    defaultUnitWaterSavings: "",
    singleFamilyHome: false,
    multiFamilyComplex: false,
    residential: false,
    landscape: false,
    commercial: false,
    programStart: "",
    programEnd: "",
    description: null,
    userId: parseInt(localStorage.getItem("user_id")),
    grantFunding: null,
    thirdParty: false,
  };

  const [program, setProgram] = useState(initialProgram);
  const [newProgramId, setNewProgramId] = useState(null);
  const [picture, setPicture] = useState(null);
  const [description, setDescription] = useState("");
  const [formErrors, setFormErrors] = useState({});

  const setD = async (description) => {
    try {
      let finalDescription = description.replace(
        /href="(?!http:\/\/|https:\/\/)(.*?)"/g,
        'href="https://$1"'
      );

      console.log("final description: ", finalDescription);
      await setProgram((prev) => ({
        ...prev,
        description: finalDescription,
      }));
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProgram((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  function updateHrefWithoutProtocol(inputString) {
    const regex =
      /<a\s+(?:[^>]*?\s+)?href=(["'])(?:(?!\1|http[s]?)[^\s>]+)\1(.*?)>/gi;

    const updatedString = inputString.replace(
      regex,
      (match, quote, hrefValue, offset, string) => {
        // Check if hrefValue doesn't start with 'http://' or 'https://'
        if (
          !hrefValue.startsWith("http://") &&
          !hrefValue.startsWith("https://")
        ) {
          const updatedHrefValue = `http://${hrefValue}`;
          return `<a href="${updatedHrefValue}">`;
        }
        return match; // No modification needed
      }
    );

    return updatedString;
  }

  useEffect(() => {
    if (program.description) {
      let protocolString = null;

      protocolString = program.description.replace(
        /href="(?!http:\/\/|https:\/\/)(.*?)"/g,
        'href="https://$1"'
      );
    }
  }, [program.description]);

  const createBlobUrl = (byteStream) => {
    if (!byteStream) return null;
    const blob = new Blob([byteStream], { type: "image/jpeg" });
    return URL.createObjectURL(blob);
  };
  const [reference, setReference] = useState(null);
  const [refImage, setRefImage] = useState(null);
  const getProgramImage = async (payload) => {
    try {
      closeDeleteImage();

      const res = await dispatch(referenceById({ id: payload }));
      const reference = res?.payload[0];
      if (reference) {
        const res1 = await dispatch(downloadReference(reference.fileName));
        const refBlob = createBlobUrl(res1.payload);

        setRefImage(refBlob);
        setPicture(reference);
        setReference(reference);
      } else {
        setRefImage("");
        setReference({
          fileName: "default image will be used",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (props.program) {
      getProgramImage(props.program[0].id);
    }
  }, [props.program, props]);

  const submitProgram = async (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();

      if (checkEmptyFields(program).empty === false) {
        const temp = program;
        temp["description"] = program.description.replace(/"/g, ""); //removed double quotes to accept hyperlinks

        const res = await dispatch(addProgram(temp));
        setNewProgramId(res.payload.id);

        const initProgramBudget = {
          userId: parseInt(localStorage.getItem("user_id")),
          programId: res.payload.id,
          comment: "",
          value: parseInt(program.programBudget),
        };

        await dispatch(addBudget(initProgramBudget));
        await dispatch(fetchPrograms());

        if (picture) {
          uploadPhoto([picture, res.payload.id]);
        }

        setProgram(initialProgram);
        setProgram(initialProgram);
        props.closeProgram();

        await dispatch(fetchApplications());
        await dispatch(fetchStatusList());
        await dispatch(fetchApplicationStatus());
      } else {
        setFormErrors(checkEmptyFields(program).errors);

        const toastPayload = {
          index: 1,
          description: 1,
          type: "program",
        };
        setToast(toastPayload);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const editProgram = async (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();

      if (checkEmptyFields(program).empty === false) {
        if (props.program[0].programBudget !== program.programBudget) {
          const initProgramBudget = {
            userId: parseInt(localStorage.getItem("user_id")),
            programId: props.program[0].id,
            comment: "",
            value: parseInt(program.programBudget),
          };
          await dispatch(addBudget(initProgramBudget));
        }

        let processedProgram = { ...program };
        console.log("processed program: ", processedProgram);
        processedProgram["defaultUnitWaterSavings"] =
          processedProgram["defaultUnitWaterSavings"] === ""
            ? 0
            : processedProgram["defaultUnitWaterSavings"];

        processedProgram["defaultUnitCost"] =
          processedProgram["defaultUnitCost"] === ""
            ? 0
            : processedProgram["defaultUnitCost"];

        processedProgram["programBudget"] =
          processedProgram["programBudget"] === ""
            ? 0
            : processedProgram["programBudget"];

        processedProgram["grantFunding"] =
          processedProgram["grantFunding"] === ""
            ? 0
            : processedProgram["grantFunding"];

        console.log("processed program2: ", processedProgram);
        await dispatch(updateProgram(processedProgram));
        await dispatch(fetchPrograms());
        if (!reference["fileName"]) {
          const res = await dispatch(deleteReference({ id: reference.id }));
        }
        if (picture) {
          uploadPhoto([picture, "edit"]);
        }

        setFormErrors({});
        props.closeProgram();

        // getProgramImage({id: reference.id})
        // setProgram(initialProgram);
        // props.closeProgram();
      } else {
        setFormErrors(checkEmptyFields(program).errors);

        const toastPayload = {
          index: 1,
          description: 1,
          type: "program",
        };
        setToast(toastPayload);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const [deleteImage, setDeleteImage] = useState(false);
  const openDeleteImage = () => {
    setRefImage(null);
    let temp = { ...reference };
    temp["fileName"] = null;
    setReference(temp);
    setDeleteImage(true);
  };
  const closeDeleteImage = () => {
    setDeleteImage(false);
  };

  const handleDeleteClick = (event) => {
    // Reset the associated file input by clearing its value
    const fileInput = event.target
      .closest(".flex")
      .querySelector("input[type='file']");
    if (fileInput) {
      fileInput.value = "";
    }
    const updateEvent = new Event("change", {
      bubbles: true,
      cancelable: true,
    });
    fileInput.dispatchEvent(updateEvent);
  };

  const uploadPhoto = async (input) => {
    const formData = new FormData();
    formData.append("file", input[0]);
    // upload the document
    try {
      const url =
        process.env.REACT_APP_PRODUCTION === "true"
          ? `https://expressproxy-prod.azurewebsites.net/blobstorage/upload`
          : `http://localhost:3000/blobstorage/upload`;

      const res = await axios.post(url, formData, {
        headers: {
          "Content-Type": "application/octet-stream",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      console.log("uploadPhotosubmit: ", res.data);
      formData.append("fileLocation", res.data.name);

      // check if a previous image exists. if it does delete it
      //if performing a submit then previous image does not exist.
      if (input[1] === "edit") {
        let oldPhoto = await getPhotoLink(
          props.program[0].id ? props.program[0].id : newProgramId //fix
        );
        deletePhotoLink(oldPhoto);
        setPhotoLink([res.data, props.program[0].id]);
      } else {
        setPhotoLink([res.data, input[1]]);
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const setPhotoLink = async (data) => {
    // add the links to the document
    try {
      const url =
        process.env.REACT_APP_PRODUCTION === "true"
          ? `https://expressproxy-prod.azurewebsites.net/reference/add`
          : `http://localhost:3000/reference/add`;

      const res = await axios.post(
        url,
        {
          referenceId: data[1],
          referenceType: "program_picture",
          secondaryId: null,
          fileName: data[0].name,
          fileLocation: data[0].name,
          uploader: localStorage.getItem("user_id"),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const getPhotoLink = async (input) => {
    try {
      const url =
        process.env.REACT_APP_PRODUCTION === "true"
          ? `https://expressproxy-prod.azurewebsites.net/reference/fetch-by-type`
          : `http://localhost:3000/reference/fetch-by-type`;

      const res = await axios.post(
        url,
        {
          referenceId: input,
          referenceType: "program_picture",
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      return res.data?.data?.[0]; // info on link for photo
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  const deletePhotoLink = async (input) => {
    console.log("Deleting file.");
    console.log(input);
    if (!input?.id) return false;
    try {
      const url =
        process.env.REACT_APP_PRODUCTION === "true"
          ? `https://expressproxy-prod.azurewebsites.net/reference/delete`
          : `http://localhost:3000/reference/delete`;

      const res = await axios.post(
        url,
        {
          id: input.id,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const [uploadActive, setUploadActive] = useState(false);
  const openUpload = () => {
    setUploadActive(true);
  };
  const closeUpload = () => {
    setUploadActive(false);
  };

  useEffect(() => {
    if (props.edit) {
      setProgram(props.program[0]);
      setDescription(props.program[0].description);
    } else {
      setProgram(initialProgram);
    }
  }, [props.program, props.edit]);

  return (
    <motion.div
      className="flex flex-col gap-8 bg-gray-50 dark:bg-gray-800 dark:text-gray-50 rounded md p-8 shadow-md w-full relative "
      variants={slideIn}
      initial="hidden"
      animate="visible"
      exit="exit"
      transition={{ duration: 0.5 }}
    >
      {program && (
        <div className="flex flex-col gap-2 ">
          <h2 className="absolute top-0 left-0 right-0 rounded-t tracking-wide p-2 text-start  bg-[#7d7d7d] text-white ">
            Program Configuration
          </h2>

          <div className="grid grid-cols-[23%_75%] gap-4 justify-between mt-[2rem]">
            <div className="">
              <label>Program Name</label>
              {formErrors.programName && (
                <span className="text-red-500">{formErrors.programName}</span>
              )}
            </div>
            <div className="">
              <input
                type="text"
                value={program.programName}
                className="w-full border-gray-200 border-2 rounded dark:bg-gray-600 h-[2.5rem] pl-2"
                name="programName"
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            </div>
          </div>
          <div className="grid grid-cols-[50%_50%] gap-4 justify-between">
            <div class="flex w-full">
              <div class="w-1/2">
                <div className="">
                  <label>Default Unit Water Savings (gallons/year)</label>
                  {formErrors.defaultUnitWaterSavings && (
                    <span className="text-red-500">
                      {formErrors.defaultUnitWaterSavings}
                    </span>
                  )}
                </div>
                <div>
                  <label className="text-gray-500 italic">
                    Leave blank if unknown
                  </label>
                </div>
              </div>

              <div className=" flex items-center">
                <CurrencyInput
                  id="input-example"
                  name="input-name"
                  className="w-full border-gray-200 border-2 rounded h-[2.5rem]  dark:bg-gray-600 pl-4"
                  value={
                    program.defaultUnitWaterSavings
                      ? program.defaultUnitWaterSavings
                          .toString()
                          .replace(/[!,]/g, "")
                      : ""
                  }
                  onChange={(e) => {
                    setProgram((prev) => ({
                      ...prev,
                      defaultUnitWaterSavings: e.target.value
                        ? e.target.value.toString().replace(/[!,]/g, "")
                        : "",
                    }));
                  }}
                  allowDecimals={true}
                  decimalsLimit={2}
                />
              </div>
            </div>
            <div class="flex w-full">
              <div class="w-1/2">
                {" "}
                <div className="">
                  <label>Default Unit Cost</label>
                  {formErrors.defaultUnitCost && (
                    <span className="text-red-500">
                      {formErrors.defaultUnitCost}
                    </span>
                  )}
                  {formErrors.defaultUnit && (
                    <span className="text-red-500">
                      {formErrors.defaultUnit}
                    </span>
                  )}
                </div>
                <div>
                  <label className="text-gray-500 italic">
                    Leave blank if unknown
                  </label>
                </div>
              </div>
              <div class="w-1/2">
                <div className="flex w-4/12 items-center gap-4">
                  <div className=" flex items-center">
                    <span className="pointer-events-none absolute pl-1  ">
                      $
                    </span>
                    <CurrencyInput
                      id="input-example"
                      name="input-name"
                      className="w-full border-gray-200 border-2 rounded h-[2.5rem]  dark:bg-gray-600 pl-4"
                      value={
                        program.defaultUnitCost
                          ? program.defaultUnitCost
                              .toString()
                              .replace(/[!,]/g, "")
                          : ""
                      }
                      onChange={(e) => {
                        setProgram((prev) => ({
                          ...prev,
                          defaultUnitCost: e.target.value
                            ? e.target.value.toString().replace(/[!,]/g, "")
                            : "",
                        }));
                      }}
                      allowDecimals={true}
                      decimalsLimit={2}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="flex w-full">
              <div class="w-1/2">
                {" "}
                <div className="flex flex-col">
                  <label>Current Program Budget</label>
                  {formErrors.programBudget && (
                    <span className="text-red-500">
                      {formErrors.programBudget}
                    </span>
                  )}
                </div>
              </div>
              <div class="w-1/2 flex items-center">
                <span className="pointer-events-none absolute pl-1 ">$</span>
                <CurrencyInput
                  id="input-example"
                  name="input-name"
                  className="w-full border-gray-200 border-2 rounded h-[2.5rem] dark:bg-gray-600 pl-4"
                  value={
                    program?.programBudget
                      ? program.programBudget.toString().replace(/[!,]/g, "")
                      : ""
                  }
                  onChange={(e) => {
                    setProgram((prev) => ({
                      ...prev,
                      programBudget: e.target.value
                        ? e.target.value.toString().replace(/[!,]/g, "")
                        : "",
                    }));
                  }}
                  allowDecimals={true}
                  decimalsLimit={2}
                />
              </div>
            </div>
            <div class="flex w-full">
              <div class="w-1/2">
                {" "}
                <span className="flex ">Total Grant Funding Available:</span>
                {formErrors.grantFunding && (
                  <span className="text-red-500">
                    {formErrors.grantFunding}
                  </span>
                )}
              </div>
              <div class="w-1/2">
                {" "}
                <div className="flex gap-5">
                  <div className="w-full flex items-center">
                    <span className="pointer-events-none absolute pl-1  ">
                      $
                    </span>
                    <CurrencyInput
                      id="input-example"
                      name="input-name"
                      className="w-full border-gray-200 border-2 rounded h-[2.5rem]  dark:bg-gray-600 pl-4"
                      value={
                        program?.grantFunding
                          ? program.grantFunding.toString().replace(/[!,]/g, "")
                          : ""
                      }
                      onChange={(e) => {
                        setProgram((prev) => ({
                          ...prev,
                          grantFunding: e.target.value
                            ? e.target.value.toString().replace(/[!,]/g, "")
                            : "",
                        }));
                      }}
                      allowDecimals={true}
                      decimalsLimit={2}
                    />
                  </div>
                </div>
                <div className="w-full flex"></div>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4 justify-between">
              <div className="">
                <label>Third Party Program</label>
              </div>
              <div className="">
                <input
                  type="checkbox"
                  name="thirdParty"
                  value={program.thirdParty}
                  checked={program.thirdParty}
                  className="border-gray-200 border-2 rounded dark:bg-gray-600 h-5 w-5"
                  onChange={(e) => {
                    setProgram((prev) => ({
                      ...prev,
                      thirdParty: !prev.thirdParty,
                    }));
                  }}
                />
              </div>
            </div>
          </div>

          {props.edit && (
            <span
              className="font-medium text-blue-600 dark:text-blue-500 hover:underline cursor-pointer"
              onClick={() => {
                props.budgetLogController.openBudgetLog(program.id);
              }}
            >
              View History of Program Budget Changes
            </span>
          )}
          <hr className="my-4" />
          <div className="program-eligibility flex flex-col gap-2">
            <span className="mb-4">Program Eligibility</span>
            <div className="flex">
              <div className="flex gap-2 basis-6/12">
                <input
                  type="checkbox"
                  checked={program.singleFamilyHome}
                  className="h-5 w-5 rounded"
                  onChange={(e) => {
                    setProgram((prev) => ({
                      ...prev,
                      singleFamilyHome: !prev.singleFamilyHome,
                    }));
                  }}
                />
                <label>Single Family Home</label>
              </div>
              <div className="flex gap-2 basis-6/12">
                <input
                  type="checkbox"
                  checked={program.multiFamilyComplex}
                  className="h-5 w-5 rounded"
                  onChange={(e) => {
                    setProgram((prev) => ({
                      ...prev,
                      multiFamilyComplex: !prev.multiFamilyComplex,
                    }));
                  }}
                />
                <label>Multi Family Home or HOA</label>
              </div>
            </div>
            <div className="flex ">
              <div className="flex gap-2 basis-6/12">
                <input
                  type="checkbox"
                  checked={program.residential}
                  className="h-5 w-5 rounded"
                  onChange={(e) => {
                    setProgram((prev) => ({
                      ...prev,
                      residential: !prev.residential,
                    }));
                  }}
                />
                <label>Mixed Use Residential/Commercial</label>
              </div>
              <div className="flex gap-2 basis-6/12">
                <input
                  type="checkbox"
                  checked={program.landscape}
                  className="h-5 w-5 rounded"
                  onChange={() => {
                    setProgram((prev) => ({
                      ...prev,
                      landscape: !prev.landscape,
                    }));
                  }}
                />
                <label>Landscape (Irrigation Account)</label>
              </div>
            </div>
            <div className="flex gap-2">
              <input
                type="checkbox"
                checked={program.commercial}
                className="h-5 w-5 rounded"
                onChange={(e) => {
                  setProgram((prev) => ({
                    ...prev,
                    commercial: !prev.commercial,
                  }));
                }}
              />
              <label>Commercial</label>
            </div>
          </div>
          <hr className="my-4" />
          <div className="flex flex-col gap-4">
            <div className="flex gap-4">
              <div className="flex gap-4 items-center">
                <label>Program Start</label>
                <input
                  type="date"
                  className="border-2 border-gray-200 rounded h-[2.5rem] dark:bg-gray-600"
                  defaultValue={program.programStart}
                  onChange={(e) => {
                    setProgram((prev) => ({
                      ...prev,
                      programStart: e.target.value,
                    }));
                  }}
                />
                {formErrors.programStart && (
                  <span className="text-red-500">
                    {formErrors.programStart}
                  </span>
                )}
              </div>
              <div className="flex gap-4 items-center">
                <label>Program End</label>
                <input
                  type="date"
                  className="border-2 border-gray-200 rounded h-[2.5rem] dark:bg-gray-600"
                  defaultValue={program.programEnd}
                  onChange={(e) => {
                    setProgram((prev) => ({
                      ...prev,
                      programEnd: e.target.value,
                    }));
                  }}
                />
                {formErrors.programEnd && (
                  <span className="text-red-500">{formErrors.programEnd}</span>
                )}
              </div>
            </div>
            <hr className="" />
            <span>Program Picture</span>

            <div className="flex gap-4">
              <div className="flex flex-col gap-1 bg-white p-2 rounded">
                {refImage && !deleteImage ? (
                  <img
                    src={refImage}
                    onClick={() => {
                      props.openPiModal(refImage);
                    }}
                    alt={program.programName}
                    className="w-full object-cover object-center rounded cursor-pointer"
                    style={{ height: "6rem" }}
                  />
                ) : (
                  <div className="w-full h-16 flex justify-center border border-dotted border-gray-300 items-center rounded p-8">
                    <span className="text-900">no image available</span>
                  </div>
                )}
                <span className="text-sm mx-auto">{reference?.fileName}</span>
              </div>
              <div className="flex gap-4 items-center">
                {!uploadActive ? (
                  <Button
                    variant="outline"
                    className="bg-green-300 text-white rounded shadow"
                    onClick={() => {
                      openUpload();
                    }}
                  >
                    <MdFileUpload size="18" />
                  </Button>
                ) : (
                  <Button
                    variant="outline"
                    className="bg-gray-300 text-white rounded shadow"
                    onClick={() => {
                      closeUpload();
                    }}
                  >
                    <MdCancel size="18" />
                  </Button>
                )}
                {uploadActive && (
                  <>
                    <input
                      type="file"
                      className="border border-gray-100 rounded  dark:bg-gray-600 bg-gray-50"
                      onChange={(e) => {
                        setPicture(e.target.files[0]);
                      }}
                    />
                  </>
                )}
                <BsFillTrashFill
                  size="18"
                  className="cursor-pointer dark:text-white hover:text-red-400"
                  onClick={() => {
                    if (
                      reference?.fileName &&
                      reference?.fileName !== "default image will be used"
                    ) {
                      openDeleteImage();
                    }
                  }}
                />
              </div>
            </div>
            <hr className="my-4" />
            <div className="flex flex-col gap-4 ">
              <span>Program Description</span>
              {formErrors.description && (
                <span className="text-red-500">{formErrors.description}</span>
              )}

              <ReactQuill
                theme="snow"
                className="w-full rounded h-[12rem] mb-4"
                value={program.description}
                onChange={setD}
              />
            </div>
          </div>
          <div className="footer flex p-8 gap-1 justify-center ">
            <button
              className="text-white bg-gray-500 hover:bg-gray-700 focus:ring-4 focus:ring-gray-300 font-medium rounded text-sm px-5 py-2 mr-2 dark:bg-gray-500 dark:hover:bg-gray-500 focus:outline-none dark:focus:ring-gray-500"
              onClick={() => {
                props.closeProgram();
              }}
            >
              Cancel
            </button>
            <button
              className="text-white bg-teal-reef hover:bg-teal-700 focus:ring-4 focus:ring-teal-300 font-medium rounded text-sm px-5 py-2 mr-2 dark:bg-teal-400 dark:hover:bg-teal-reef focus:outline-none dark:focus:ring-teal-500"
              onClick={(e) => {
                props.edit ? editProgram(e) : submitProgram(e);
              }}
            >
              Save
            </button>
            {props.edit && (
              <button
                className="text-white bg-blue-reef hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm px-5 py-2 mr-2 dark:bg-blue-400 dark:hover:bg-blue-reef focus:outline-none dark:focus:ring-blue-500"
                onClick={(e) => {
                  props.workflowController.openModal();
                }}
              >
                Customize Program
              </button>
            )}
          </div>
        </div>
      )}
    </motion.div>
  );
};

export default ProgramInformation;
