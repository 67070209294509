import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Backdrop from "../Misc/Backdrop";
import { useDispatch, useSelector } from "react-redux";
import { addApplication, applicationById } from "../../features/application";
import {
  addApplicationValue,
  deleteApplicationValue,
  updateApplicationValue,
  fetchApplicationValues,
} from "../../features/applicationValue";

import { Button } from "../Ui/button";
// import { addWaterSavings } from "../../features/waterSavingsLog";
import {
  addApplicationStatus,
  selectAllStatus,
  fetchApplicationStatus,
} from "../../features/applicationStatus";
import { selectAllDropdownValues } from "../../features/dropdownValue";
import { fetchStatusList } from "../../features/status";
import { fetchApplicationValueById } from "../../features/applicationValue";
import { selectStatusList } from "../../features/status";
import { setToast } from "../../utils/ToastNotification";
import { formatFullAddress } from "../../utils/formatAddress";
import { addStatusLog } from "../../features/status";
import ReactQuill, { Quill } from "react-quill";
import { selectAllPrograms } from "../../features/program";
import { BsFillTrashFill } from "react-icons/bs";
import DeleteModal from "../Misc/DeleteModal";
import { IoIosAdd } from "react-icons/io";
import { getSubmissionEmailTemplate } from "../../features/emailTemplate";
// import { featureFlags } from "../../utils/featureFlags";

const axios = require("axios").default;

axios.defaults.headers.common["Client"] = "ACWD";

const slideIn = {
  hidden: { x: "100vh", opacity: 0 },
  visible: {
    x: "0",
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: { x: "-100vh", opacity: 0 },
};
const CustomerUnlockApplicationModal = ({ props }) => {
  const dispatch = useDispatch();

  const statusList = useSelector(selectStatusList);
  const [visibleUpload, setVisibleUpload] = useState({});
  // const appValues = useSelector(selectAllValues);
  const [appFieldValues, setAppFieldValues] = useState(null);
  const programs = useSelector(selectAllPrograms);

  const [appValues, setAppValues] = useState(null);

  useEffect(() => {
    if (!appValues) {
      const fetchApplicationValues = async () => {
        const res = await dispatch(
          fetchApplicationValueById({ id: props.Ids.appId })
        );

        setAppValues(res.payload);
        setAppFieldValues(res.payload);
      };

      fetchApplicationValues();
    }
  }, [props]);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteModal2, setShowDeleteModal2] = useState(false);
  const [deleteEvent, setDeleteEvent] = useState(null);
  const [deleteEvent2, setDeleteEvent2] = useState(null);

  const hideDeleteModal = (e) => {
    e.stopPropagation();
    setShowDeleteModal(false);
  };
  const openDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const hideDeleteModal2 = (e) => {
    if (e) e.stopPropagation();
    setShowDeleteModal2(false);
  };
  const openDeleteModal2 = () => {
    setShowDeleteModal2(true);
  };

  const appStatusList = useSelector(selectAllStatus);

  const dropdownValues = useSelector(selectAllDropdownValues);
  const [uploads, setUploads] = useState(null);

  const inputType = {
    BOOLEAN: "checkbox",
    STRING: "text",
    DATE: "date",
    NUMBER: "text",
  };

  const placeholders = {
    BOOLEAN: "",
    STRING: "Enter Answer",
    DATE: "",
    NUMBER: "Enter Number",
  };

  const [fields, setFields] = useState(null);

  const [values, setValues] = useState(null);
  const [initValues, setInitValues] = useState(null);

  const [fileUploads, setFileUploads] = useState({});
  const [curDocumentList, setCurDocumentList] = useState(null);
  useEffect(() => {}, [values]);
  const [fieldNames, setFieldNames] = useState([]);
  const [propertyId, setPropertyId] = useState(null);
  const [program, setProgram] = useState(null);

  const [checkValues, setCheckValues] = useState({});
  const [initCheckValues, setInitCheckValues] = useState({});

  const handleCheckboxChange = async (e, id) => {
    setCheckValues((prev) => ({
      ...prev,
      [id]: e.target.checked,
    }));
  };

  useEffect(() => {
    const temp =
      props.programs?.filter((program) => {
        return program?.id === props.Ids.programId;
      }).length > 0
        ? setProgram(
            props.programs?.filter((program) => {
              return program?.id === props.Ids.programId;
            })[0]
          )
        : null;
  }, []);

  const [currentAppStatusId, setCurrentAppStatusId] = useState(null);
  const [nextAppStatus, setNextAppStatus] = useState(null);

  useEffect(() => {
    const currentAppStatus =
      appStatusList?.filter((status) => {
        return status.applicationId === props?.Ids?.appId;
      })?.length > 0
        ? appStatusList?.filter((status) => {
            return status.applicationId === props?.Ids?.appId;
          })[0]?.id
        : null;

    const currentAppStatusId = currentAppStatus ? currentAppStatus : null;

    setCurrentAppStatusId(currentAppStatusId);
    console.log("props.ids: ", props.Ids.nextStatus);
    const nextApplicationStatus = props.Ids.nextStatus;

    setNextAppStatus(nextApplicationStatus);
  }, [appStatusList]);

  const defaultSelected = (payload, latestValues) => {
    let defaultOption = true;
    payload.every((el) => {
      if (
        latestValues?.filter((x) => {
          return x.fieldId === el.fieldId;
        }).length > 0 &&
        latestValues?.filter((appVal) => {
          return appVal.fieldId === el.fieldId;
        })[0]?.value === el.value
      ) {
        defaultOption = false;
        return false;
      }
    });

    return defaultOption;
  };
  ////
  const [lookupSearch, setLookupSearch] = useState("");
  const [lookupOptions, setLookupOptions] = useState(null);
  const [searchList, setSearchList] = useState(null);
  const [myList, setMylist] = useState([]);
  const [myListFieldId, setMyListFieldId] = useState(null);
  const [useMultiplier, setUseMultiplier] = useState(false);
  const [requiredUploads, setRequiredUploads] = useState({});

  const [sumInputLabel, setSumInputLabel] = useState(0);
  const [sumOf, setSumOf] = useState("");
  const [newItem, setNewItem] = useState({
    lookupName1: "",
    lookupName2: "",
    inputLabel: "",
    multiplier: "",
  });
  const [addList, setAddList] = useState([]);
  const [deleteList, setDeleteList] = useState([]);

  const getFieldNameFromKey = (key) => {
    let parts = key.split("-");

    let keyFieldName = "";
    if (parts.length > 3) {
      parts.splice(-2);
      keyFieldName = parts.join("-");
    } else {
      keyFieldName = parts[0];
    }

    return keyFieldName;
  };
  // const getCheckboxFieldNameFromKey = (key) => {
  //   let parts = key.split("-");

  //   let keyFieldName = "";
  //   if (parts.length > 4) {
  //     parts.splice(-3);
  //     keyFieldName = parts.join("-");
  //   } else {
  //     keyFieldName = parts[0];
  //   }

  //   return keyFieldName;
  // };

  // const getCheckValueIdFromKey = (key) => {
  //   return parseInt(key.split("-")[key.split("-").length - 3]);
  // };

  // const getCheckFieldIdFromKey = (key) => {
  //   return parseInt(key.split("-")[key.split("-").length - 2]);
  // };

  const getRequiredFromKey = (key) => {
    return key.split("-")[key.split("-").length - 1];
  };

  const getFieldIdFromKey = (key) => {
    return parseInt(key.split("-")[key.split("-").length - 2]);
  };

  const getSecondaryId = (key) => {
    return parseInt(key.split("-")[key.split("-").length - 2]);
  };

  const addToMyList = (payload) => {
    const toAdd = [...addList];
    const newList = [...myList];
    const index = myList.length;

    if (payload) {
      const tempObj = {
        id: index,
        index: index,
        lookupName1: payload.lookupName1,
        lookupName2: payload.lookupName2,
        inputLabel: payload.inputLabel,
        multiplier: payload.multiplier,
      };
      newList.push(tempObj);
      setMylist([...newList]);
      toAdd.push(tempObj);
      setAddList([...toAdd]);
    } else {
      const tempObj = {
        id: index,
        index: index,
        lookupName1: newItem.lookupName1,
        lookupName2: newItem.lookupName2,
        inputLabel: newItem.inputLabel,
        multiplier: newItem.multiplier,
      };
      newList.push(tempObj);
      setMylist(newList);
      toAdd.push(tempObj);
      setAddList([...toAdd]);
    }
  };

  useEffect(() => {
    let total = 0;
    let sumString = "";
    if (myList.length > 0) {
      if (!useMultiplier) {
        myList?.forEach((x, index) => {
          total += parseInt(x.inputLabel) * 1;
          if (index !== myList.length - 1) {
            sumString += ` [${x.inputLabel} * 1] +`;
          } else {
            sumString += ` [${x.inputLabel} * 1]`;
          }
        });
      } else {
        myList?.forEach((x, index) => {
          total += parseInt(x.inputLabel) * parseInt(x.multiplier);
          if (index !== myList.length - 1) {
            sumString += ` [${x.inputLabel} * ${x.multiplier}] +`;
          } else {
            sumString += ` [${x.inputLabel} * ${x.multiplier}]`;
          }
        });
      }
    }
    setSumInputLabel(total);
    setSumOf(sumString);
  }, [myList]);

  const setLookupInput = (lo, e) => {
    const temp = [...lookupOptions];

    temp.forEach((x) => {
      if (
        x.lookupName1 === lo.lookupName1 &&
        x.lookupName2 === lo.lookupName2
      ) {
        x.inputLabel = e.target.value;
      }
    });
    setLookupOptions([...temp]);
  };

  const deleteFromMyList = (index, id) => {
    let tempDelete = [...deleteList];
    let temp = [...myList];

    tempDelete.push(id);
    setDeleteList(tempDelete);

    if (
      temp?.filter((y) => {
        return y.index === index;
      }).length > 0
    ) {
      const removeIndex = temp.findIndex((obj) => obj.id === index);

      temp.splice(removeIndex, 1);
      setMylist([...temp]);
    } else {
      const toastPayload = {
        index: 2,
        description: 16,
        type: "application",
      };
      setToast(toastPayload);
    }
  };

  const generateList = () => {
    if (lookupOptions && lookupOptions.length > 0 && lookupSearch.length > 0) {
      const temp = lookupOptions.filter((lo) => {
        return (
          lo["lookupName1"]
            .toLowerCase()
            .includes(lookupSearch.toLowerCase()) ||
          lo["lookupName2"].toLowerCase().includes(lookupSearch.toLowerCase())
        );
      });

      if (temp.length > 0) {
        setSearchList([...temp]);
      } else {
        setSearchList(0);
      }
    }
  };

  const submitLater = async () => {
    try {
      if (values) {
        for (const [key, value] of Object.entries(values)) {
          if (value !== "") {
            if (initValues[key] !== value) {
              const valueInfo = {
                value: value.toString(),
                applicationId: parseInt(props.Ids.appId),
                fieldId: getFieldIdFromKey(key),
                checkValueId: null,
                multiplier: null,
                lookupName1: null,
                lookupName2: null,
                calcResult: null,
              };
              await dispatch(addApplicationValue(valueInfo));
            }
          }
        }
      }

      for (const [key, value] of Object.entries(checkValues)) {
        if (initCheckValues[key] !== value) {
          const valueInfo = {
            id: key.split("-")[0],
            value: value,
          };

          await dispatch(updateApplicationValue(valueInfo));
        }
      }

      if (deleteList.length > 0) {
        deleteList?.forEach(async (x) => {
          await dispatch(deleteApplicationValue({ id: x }));
          await dispatch(fetchApplicationValues()); //not updating in time tmp fix in forloop
        });
      }

      if (addList.length > 0) {
        addList?.forEach(async (x) => {
          const tempObj = {
            applicationId: parseInt(props.Ids.appId),
            fieldId: myListFieldId,
            multiplier: x.multiplier ? x.multiplier : 1,
            lookupName1: x.lookupName1,
            lookupName2: x.lookupName2,
            calcResult: useMultiplier
              ? x.multiplier * parseInt(x.inputLabel)
              : parseInt(x.inputLabel),
            value: x.inputLabel,
            checkValueId: null,
          };
          await dispatch(addApplicationValue(tempObj));
          await dispatch(fetchApplicationValues()); //not updating in time tmp fix in forloop
        });
      }

      // save files as well
      saveFiles();

      props.closeUnlockModal();

      const toastPayload = {
        index: 0,
        description: 14,
        type: "application",
      };
      setToast(toastPayload);
      await dispatch(fetchApplicationValues());
      await dispatch(applicationById({ id: localStorage.getItem("user_id") }));
    } catch (err) {
      console.log(err);
    }
  };

  const [booleanFieldValues, setBooleanFieldValues] = useState(null); //read-only boolean field values

  useEffect(() => {
    const applicationValues = appValues;

    const latestValues = [];
    const latestSet = {};
    for (let i = 0; i < applicationValues?.length; i++) {
      latestSet[applicationValues[i].fieldId] = applicationValues[i];
    }
    for (const [key, value] of Object.entries(latestSet)) {
      latestValues.push(value);
    }

    const customerFields = props.fields?.filter((cust) => {
      return cust.programId === props.Ids.programId && cust.deleted !== true;
    });

    const boolFields = customerFields?.filter((x) => {
      return x.fieldType === "BOOLEAN";
    });

    const boolFieldValues = boolFields?.map((x) => {
      const value =
        latestValues?.filter((y) => {
          return y.fieldId === x?.id;
        }).length > 0
          ? latestValues?.filter((y) => {
              return y.fieldId === x?.id;
            })[0].value
          : null;

      if (value) {
        const valueConvert = value === "true" ? true : false;
        return { fieldId: x?.id, value: valueConvert };
      } else {
        return { fieldId: x?.id, value: false };
      }
    });
    const temp = boolFieldValues;

    setBooleanFieldValues(temp);
  }, [appValues, props.fields]);

  useEffect(() => {
    const checkApplicationValues = appValues?.filter((x) => {
      return x.checkValueId;
    });

    const customerFields = props.fields?.filter((cust) => {
      return cust.programId === props.Ids.programId && cust.deleted !== true;
    });

    const tempCheck = {};
    for (let i = 0; i < customerFields.length; i++) {
      if (
        customerFields[i].internalFacing === false &&
        customerFields[i].fieldType === "CHECK" &&
        customerFields[i].checkValues.length > 0
      ) {
        customerFields[i].checkValues.forEach((val) => {
          const cav = checkApplicationValues?.filter((x) => {
            return (
              x.fieldId === customerFields[i].id && x.checkValueId === val.id
            );
          })[0];
          if (cav) {
            tempCheck[`${cav.id}-${cav.fieldId}`] =
              cav.value === "true" ? true : false;
          }
        });
      }
    }
    setCheckValues({ ...tempCheck });
    setInitCheckValues({ ...tempCheck });
  }, [appValues]);

  useEffect(() => {
    const allApplicationValues = appValues?.filter((value) => {
      return value.applicationId === props.Ids.appId;
    });

    const applicationValues = allApplicationValues?.filter((x) => {
      return !x.checkValueId;
    });

    const checkApplicationValues = allApplicationValues?.filter((x) => {
      return x.checkValueId;
    });

    const latestValues = [];
    const latestSet = {};
    for (let i = 0; i < applicationValues?.length; i++) {
      latestSet[applicationValues[i].fieldId] = applicationValues[i];
    }
    for (const [key, value] of Object.entries(latestSet)) {
      latestValues.push(value);
    }

    const customerFields = props.fields?.filter((cust) => {
      return cust.programId === props.Ids.programId && cust.deleted !== true;
    });

    const obj = {};
    customerFields.map((field) => {
      if (field.fieldType === "DROPDOWN") {
        const temp = dropdownValues?.filter((value) => {
          return value.fieldId === field?.id;
        });

        if (temp.length > 0) {
          obj[`${field.fieldName}`] = temp;
        }
      }
    });

    for (let i = 0; i < customerFields.length; i++) {
      if (customerFields[i].fieldType === "LOOKUP" && lookupOptions === null) {
        if (customerFields[i].lookupOptions.length > 0) {
          const cav = applicationValues?.filter((x) => {
            return x.fieldId === customerFields[i].id;
          });
          let listArr = [];
          cav?.forEach((x, index) => {
            const temp = {
              valueId: x.id,
              id: index,
              index: index,
              lookupName1: x.lookupName1,
              lookupName2: x.lookupName2,
              inputLabel: x.value,
              multiplier: x.multiplier,
            };

            listArr.push(temp);
          });
          setMylist(listArr);
          setMyListFieldId(customerFields[i].lookupOptions[0].fieldId);
          const lookupTemp = [];
          customerFields[i].lookupOptions.map((x) => {
            const tempObj = {};
            tempObj["id"] = x.id;
            tempObj["fieldId"] = x.fieldId;
            tempObj["lookupName1"] = x.lookupName1;
            tempObj["lookupName2"] = x.lookupName2;
            tempObj["multiplier"] = x.multiplier;
            tempObj["inputLabel"] = "";
            lookupTemp.push(tempObj);
          });
          setLookupOptions([...lookupTemp]);

          setUseMultiplier(
            customerFields[i].lookupCharacteristics.useMultiplier
          );
        }
      }
    }

    const temp = customerFields
      ?.filter((x) => {
        return !x.internalFacing;
      })
      .sort((a, b) => {
        return a.applicationOrder - b.applicationOrder;
      })
      .map((field, index) => {
        return (
          <div className="flex flex-col w-full gap-1" key={index}>
            <div
              className={`flex gap-2 ${
                field.fieldType === "SECTION" ? "border-b-8" : "border-b"
              }`}
            >
              <label
                className={`font-bold  font-sans ${
                  field.fieldType === "SECTION"
                    ? "text-4xl text-green-reef"
                    : ""
                }`}
              >
                {field.fieldName}
              </label>
              {field.required && <span className="text-red-500">*</span>}
            </div>
            <span>{field.fieldDescription.replace(/<br\s*\/?>/gi, "\n")}</span>
            {field.fieldType === "NUMBER" && (
              <input
                type={`${inputType[field.fieldType]}`}
                placeholder={`${placeholders[field.fieldType]}`}
                disabled={props.type === "read-only" && true}
                className={`border-gray-200 border p-2 rounded
                ${field.fieldType === "DATE" ? "w-3/12" : ""}
                ${field.fieldType === "BOOLEAN" ? "w-5" : ""}
              
              `}
                defaultValue={
                  latestValues?.filter((appVal) => {
                    return appVal.fieldId === field?.id;
                  }).length > 0
                    ? latestValues?.filter((appVal) => {
                        return appVal.fieldId === field?.id;
                      })[0].value
                    : null
                }
                checked={
                  booleanFieldValues?.filter((appVal) => {
                    return appVal.fieldId === field?.id;
                  }).length > 0
                    ? booleanFieldValues?.filter((appVal) => {
                        return appVal.fieldId === field?.id;
                      })[0].value === true
                      ? true
                      : false
                    : false
                }
                onChange={(e) => {
                  if (
                    field.fieldType === "NUMBER" ||
                    field.fieldType === "STRING" ||
                    field.fieldType === "DATE"
                  ) {
                    setValues((prev) => ({
                      ...prev,
                      [`${field.fieldName}-${field?.id}${
                        field.required ? "-t" : "-f"
                      }`]: e.target.value,
                    }));
                  } else {
                    const tempFields = booleanFieldValues?.map((x) => {
                      if (x.fieldId === field.id) {
                        return {
                          fieldId: x.fieldId,
                          value: e.target.checked,
                        };
                      }
                      return x;
                    });

                    setBooleanFieldValues(tempFields);
                    setValues((prev) => ({
                      ...prev,
                      [`${field.fieldName}-${field?.id}${
                        field.required ? "-t" : "-f"
                      }`]: e.target.checked,
                    }));
                  }
                }}
              />
            )}
            {field.fieldType === "STRING" && (
              <input
                type={`${inputType[field.fieldType]}`}
                placeholder={`${placeholders[field.fieldType]}`}
                disabled={props.type === "read-only" && true}
                className={`border-gray-200 border p-2 rounded
                ${field.fieldType === "DATE" ? "w-3/12" : ""}
                ${field.fieldType === "BOOLEAN" ? "w-5" : ""}
              
              `}
                defaultValue={
                  latestValues?.filter((appVal) => {
                    return appVal.fieldId === field?.id;
                  }).length > 0
                    ? latestValues?.filter((appVal) => {
                        return appVal.fieldId === field?.id;
                      })[0].value
                    : null
                }
                checked={
                  booleanFieldValues?.filter((appVal) => {
                    return appVal.fieldId === field?.id;
                  }).length > 0
                    ? booleanFieldValues?.filter((appVal) => {
                        return appVal.fieldId === field?.id;
                      })[0].value === true
                      ? true
                      : false
                    : false
                }
                onChange={(e) => {
                  if (
                    field.fieldType === "NUMBER" ||
                    field.fieldType === "STRING" ||
                    field.fieldType === "DATE"
                  ) {
                    setValues((prev) => ({
                      ...prev,
                      [`${field.fieldName}-${field?.id}${
                        field.required ? "-t" : "-f"
                      }`]: e.target.value,
                    }));
                  } else {
                    const tempFields = booleanFieldValues?.map((x) => {
                      if (x.fieldId === field.id) {
                        return {
                          fieldId: x.fieldId,
                          value: e.target.checked,
                        };
                      }
                      return x;
                    });

                    setBooleanFieldValues(tempFields);
                    setValues((prev) => ({
                      ...prev,
                      [`${field.fieldName}-${field?.id}${
                        field.required ? "-t" : "-f"
                      }`]: e.target.checked,
                    }));
                  }
                }}
              />
            )}
            {field.fieldType === "DATE" && (
              <input
                type={`${inputType[field.fieldType]}`}
                placeholder={`${placeholders[field.fieldType]}`}
                disabled={props.type === "read-only" && true}
                className={`border-gray-200 border p-2 rounded
                ${field.fieldType === "DATE" ? "w-3/12" : ""}
                ${field.fieldType === "BOOLEAN" ? "w-5" : ""}
              
              `}
                defaultValue={
                  latestValues?.filter((appVal) => {
                    return appVal.fieldId === field?.id;
                  }).length > 0
                    ? latestValues?.filter((appVal) => {
                        return appVal.fieldId === field?.id;
                      })[0].value
                    : null
                }
                checked={
                  booleanFieldValues?.filter((appVal) => {
                    return appVal.fieldId === field?.id;
                  }).length > 0
                    ? booleanFieldValues?.filter((appVal) => {
                        return appVal.fieldId === field?.id;
                      })[0].value === true
                      ? true
                      : false
                    : false
                }
                onChange={(e) => {
                  if (
                    field.fieldType === "NUMBER" ||
                    field.fieldType === "STRING" ||
                    field.fieldType === "DATE"
                  ) {
                    setValues((prev) => ({
                      ...prev,
                      [`${field.fieldName}-${field?.id}${
                        field.required ? "-t" : "-f"
                      }`]: e.target.value,
                    }));
                  } else {
                    const tempFields = booleanFieldValues?.map((x) => {
                      if (x.fieldId === field.id) {
                        return {
                          fieldId: x.fieldId,
                          value: e.target.checked,
                        };
                      }
                      return x;
                    });

                    setBooleanFieldValues(tempFields);
                    setValues((prev) => ({
                      ...prev,
                      [`${field.fieldName}-${field?.id}${
                        field.required ? "-t" : "-f"
                      }`]: e.target.checked,
                    }));
                  }
                }}
              />
            )}
            {field.fieldType === "BOOLEAN" && (
              <input
                type={`${inputType[field.fieldType]}`}
                placeholder={`${placeholders[field.fieldType]}`}
                disabled={props.type === "read-only" && true}
                className={`border-gray-200 border p-2 rounded
                ${field.fieldType === "DATE" ? "w-3/12" : ""}
                ${field.fieldType === "BOOLEAN" ? "w-5" : ""}
              
              `}
                defaultValue={
                  latestValues?.filter((appVal) => {
                    return appVal.fieldId === field?.id;
                  }).length > 0
                    ? latestValues?.filter((appVal) => {
                        return appVal.fieldId === field?.id;
                      })[0].value
                    : null
                }
                checked={
                  booleanFieldValues?.filter((appVal) => {
                    return appVal.fieldId === field?.id;
                  }).length > 0
                    ? booleanFieldValues?.filter((appVal) => {
                        return appVal.fieldId === field?.id;
                      })[0].value === true
                      ? true
                      : false
                    : false
                }
                onChange={(e) => {
                  if (
                    field.fieldType === "NUMBER" ||
                    field.fieldType === "STRING" ||
                    field.fieldType === "DATE"
                  ) {
                    setValues((prev) => ({
                      ...prev,
                      [`${field.fieldName}-${field?.id}${
                        field.required ? "-t" : "-f"
                      }`]: e.target.value,
                    }));
                  } else {
                    const tempFields = booleanFieldValues?.map((x) => {
                      if (x.fieldId === field.id) {
                        return {
                          fieldId: x.fieldId,
                          value: e.target.checked,
                        };
                      }
                      return x;
                    });

                    setBooleanFieldValues(tempFields);
                    setValues((prev) => ({
                      ...prev,
                      [`${field.fieldName}-${field?.id}${
                        field.required ? "-t" : "-f"
                      }`]: e.target.checked,
                    }));
                  }
                }}
              />
            )}
            {field.fieldType === "DROPDOWN" && (
              <select
                className="border border-gray-200 rounded w-6/12 p-2"
                disabled={props.type === "read-only" && true}
                onChange={(e) => {
                  setValues((prev) => ({
                    ...prev,
                    [`${field.fieldName}-${field?.id}${
                      field.required ? "-t" : "-f"
                    }`]: e.target.value,
                  }));
                }}
              >
                {defaultSelected(obj[field.fieldName], latestValues) && (
                  <option selected={true}>{""}</option>
                )}
                {obj[field.fieldName] &&
                  obj[field.fieldName].map((el) => {
                    return (
                      <option
                        value={el.value}
                        selected={
                          latestValues?.filter((x) => {
                            return x.fieldId === el.fieldId;
                          }).length > 0 &&
                          latestValues?.filter((appVal) => {
                            return appVal.fieldId === el.fieldId;
                          })[0]?.value === el.value
                            ? true
                            : false
                        }
                      >
                        {el.value}
                      </option>
                    );
                  })}
              </select>
            )}
            {field.fieldType === "CHECK" && (
              <div className="flex flex-col gap-2">
                {field?.checkValues.map((x) => {
                  const cav = checkApplicationValues?.filter((y) => {
                    //check application value
                    return y.fieldId === field.id && y.checkValueId === x.id;
                  })[0];
                  if (cav) {
                    return (
                      <div className="flex gap-2 items-center">
                        <input
                          type="checkbox"
                          className="w-4 h-4"
                          disabled={props.type === "read-only" && true}
                          defaultChecked={
                            checkValues[`${cav.id}-${cav.fieldId}`]
                          }
                          onClick={(e) => {
                            handleCheckboxChange(e, `${cav.id}-${cav.fieldId}`);
                          }}
                        />
                        <label>{x.value}</label>
                      </div>
                    );
                  }
                  return null;
                })}
                {field.checkOther && (
                  <>
                    <span className="mt-2">Other</span>

                    <input
                      type="text"
                      disabled={props.type === "read-only" && true}
                      defaultValue={
                        latestValues?.filter((appVal) => {
                          return appVal.fieldId === field?.id;
                        }).length > 0
                          ? latestValues?.filter((appVal) => {
                              return appVal.fieldId === field?.id;
                            })[0].value
                          : null
                      }
                      onChange={(e) => {
                        setValues((prev) => ({
                          ...prev,
                          [`${field.fieldName}-${field.id}${
                            field.required ? "-t" : "-f"
                          }`]: e.target.value,
                        }));
                      }}
                      className={`border-gray-200 border rounded p-2`}
                    />
                  </>
                )}
              </div>
            )}
            {field.fieldType === "LOOKUP" && (
              <div className="flex flex-col gap-4 mt-4">
                <div className="flex flex-col gap-2">
                  <span className="font-bold">My List</span>

                  <span className="">{`Total ${
                    field.lookupCharacteristics.useMultiplier
                      ? field.lookupCharacteristics.multiplierLabel
                      : ""
                  }: ${sumInputLabel}`}</span>
                  <table className="table-auto w-full text-left shadow">
                    <thead>
                      <tr className="bg-blue-reef text-white">
                        <th className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                          {field.lookupCharacteristics.name1Label}
                        </th>
                        <th className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                          {"Name2Label"}
                        </th>
                        <th className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                          {"InputLabel"}
                        </th>
                        {field.lookupCharacteristics.useMultiplier && (
                          <th className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                            {"MultiplierLabel"}
                          </th>
                        )}

                        {props.type === "read-write" && (
                          <th className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                            {"Actions"}
                          </th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {myList?.map((x) => {
                        return (
                          <tr className="h-[2.5rem]">
                            <td className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                              {x.lookupName1}
                            </td>
                            <td className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                              {x.lookupName2}
                            </td>

                            <td className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                              {x.inputLabel}
                            </td>
                            {field.lookupCharacteristics.useMultiplier && (
                              <td className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                                {x.multiplier * x.inputLabel}
                              </td>
                            )}

                            {props.type === "read-write" && (
                              <td className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                                {
                                  <BsFillTrashFill
                                    size="20"
                                    className="text-red-500 cursor-pointer hover:text-gray-200"
                                    onClick={(e) => {
                                      deleteFromMyList(x.id, x.valueId);
                                    }}
                                  />
                                }
                              </td>
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                {props.type === "read-write" && (
                  <div className="flex flex-col gap-4">
                    <div className="flex flex-col">
                      <span className="font-bold">Search the Lookup</span>
                      <span>
                        {field.lookupCharacteristics.searchDescription}
                      </span>
                    </div>
                    <div className="flex gap-2">
                      <input
                        type="text"
                        className="`border-gray-200 border rounded p-2 w-8/12"
                        onChange={(e) => {
                          setLookupSearch(e.target.value);
                        }}
                      />
                      <button
                        className="bg-green-reef p-2 rounded text-white shadow"
                        onClick={() => {
                          generateList();
                        }}
                      >
                        Search
                      </button>
                    </div>

                    <table className="table-auto w-full text-left shadow">
                      <thead>
                        <tr className="bg-blue-reef text-white">
                          <th className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                            {"Name1Label"}
                          </th>
                          <th className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                            {"Name2Label"}
                          </th>

                          {field.lookupCharacteristics.useMultiplier && (
                            <th className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                              {"MultiplierLabel"}
                            </th>
                          )}
                          <th className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                            {"InputLabel"}
                          </th>
                          <th className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                            {"Actions"}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {searchList !== 0 ? (
                          searchList?.map((x, index) => {
                            return (
                              <tr key={index} className="h-[2.5rem]">
                                <td className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                                  {x.lookupName1}
                                </td>
                                <td className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                                  {x.lookupName2}
                                </td>

                                {field.lookupCharacteristics.useMultiplier && (
                                  <td className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                                    {x.multiplier}
                                  </td>
                                )}
                                <td className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50 w-3/12">
                                  <input
                                    className="`border-gray-200 border rounded p-2 w-full"
                                    onChange={(e) => {
                                      //update lookup options by filtering and finding lookup option by lookupName1 + lookupName2 and then updating the inputLabel key value pair
                                      setLookupInput(x, e);
                                    }}
                                  />
                                </td>
                                <td className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                                  <button
                                    className="bg-green-reef rounded shadow hover:bg-white hover:border hover:text-gray-900 text-gray-50"
                                    onClick={() => {
                                      addToMyList(x, index);
                                    }}
                                  >
                                    <IoIosAdd size="24" />
                                  </button>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr key={index} className="h-[2.5rem]">
                            <td className="p-2 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                              {"No search results"}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
                {props.type === "read-write" && (
                  <div className="flex flex-col">
                    <span className="font-bold">
                      Add new item not found in search
                    </span>
                    <span>{field.lookupCharacteristics.addDescription}</span>
                    <div className="flex gap-4">
                      <input
                        className="`border-gray-200 border rounded p-2 w-8/12"
                        placeholder="Name1Label"
                        onChange={(e) => {
                          setNewItem((prev) => ({
                            ...prev,
                            lookupName1: e.target.value,
                          }));
                        }}
                      />
                      <input
                        className="`border-gray-200 border rounded p-2 w-8/12"
                        placeholder="Name2Label"
                        onChange={(e) => {
                          setNewItem((prev) => ({
                            ...prev,
                            lookupName2: e.target.value,
                          }));
                        }}
                      />
                      {field.lookupCharacteristics.useMultiplier && (
                        <input
                          className="`border-gray-200 border rounded p-2 w-8/12"
                          placeholder="MultiplierLabel"
                          onChange={(e) => {
                            setNewItem((prev) => ({
                              ...prev,
                              multiplier: e.target.value,
                            }));
                          }}
                        />
                      )}
                      <input
                        className="`border-gray-200 border rounded p-2 w-8/12"
                        placeholder="InputLabel"
                        onChange={(e) => {
                          setNewItem((prev) => ({
                            ...prev,
                            inputLabel: e.target.value,
                          }));
                        }}
                      />
                      <button
                        className="bg-green-reef p-4 rounded text-white shadow"
                        onClick={() => {
                          addToMyList();
                        }}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        );
      });
    setFields(temp);

    const temp2 = props.fields.map((field) => {
      return field.fieldName;
    });
    setFieldNames(temp2);
  }, [
    props.fields,
    appValues,
    booleanFieldValues,
    lookupSearch,
    searchList,
    myList,
    lookupOptions,
    sumInputLabel,
    newItem,
    useMultiplier,
    myListFieldId,
    addList,
    deleteList,
  ]);

  //required fields validation
  useEffect(() => {
    const customerFields = props.fields?.filter((cust) => {
      return cust.programId === props.Ids.programId && cust.deleted !== true;
    });

    console.log("CUSATOMS: ", customerFields);

    const applicationValues = appValues?.filter((value) => {
      return value.applicationId === props.Ids.appId && !value.checkValueId;
    });

    const latestValues = [];
    const latestSet = {};
    for (let i = 0; i < applicationValues?.length; i++) {
      latestSet[applicationValues[i].fieldId] = applicationValues[i];
    }
    for (const [key, value] of Object.entries(latestSet)) {
      latestValues.push(value);
    }

    for (let i = 0; i < customerFields?.length; i++) {
      const cf = customerFields[i];
      const latestValue =
        latestValues?.filter((x) => {
          return x.fieldId === cf?.id;
        }).length > 0
          ? latestValues?.filter((x) => {
              return x.fieldId === cf?.id;
            })[0]
          : null;
      if (
        cf.fieldType !== "CHECK" &&
        cf.fieldType !== "LOOKUP" &&
        cf.internalFacing === false
      ) {
        latestValue
          ? setValues((prev) => ({
              ...prev,
              [`${cf.fieldName}-${cf?.id}${cf.required ? "-t" : "-f"}`]:
                latestValue.value,
            }))
          : setValues((prev) => ({
              ...prev,
              [`${cf.fieldName}-${cf?.id}${cf.required ? "-t" : "-f"}`]: "",
            }));

        latestValue
          ? setInitValues((prev) => ({
              ...prev,
              [`${cf.fieldName}-${cf?.id}${cf.required ? "-t" : "-f"}`]:
                latestValue.value,
            }))
          : setInitValues((prev) => ({
              ...prev,
              [`${cf.fieldName}-${cf?.id}${cf.required ? "-t" : "-f"}`]: "",
            }));
      } else if (cf.fieldType === "CHECK" && cf.internalFacing === false) {
        latestValue
          ? setValues((prev) => ({
              ...prev,
              [`${cf.fieldName}-${cf?.id}${cf.required ? "-t" : "-f"}`]:
                latestValue.value,
            }))
          : setValues((prev) => ({
              ...prev,
              [`${cf.fieldName}-${cf?.id}${cf.required ? "-t" : "-f"}`]: "",
            }));

        latestValue
          ? setInitValues((prev) => ({
              ...prev,
              [`${cf.fieldName}-${cf?.id}${cf.required ? "-t" : "-f"}`]:
                latestValue.value,
            }))
          : setInitValues((prev) => ({
              ...prev,
              [`${cf.fieldName}-${cf?.id}${cf.required ? "-t" : "-f"}`]: "",
            }));
      } else if (cf.fieldType === "LOOKUP" && cf.internalFacing === false) {
        setValues((prev) => ({
          ...prev,
          [`${"LOOKUP*FIELD"}-${cf?.id}${cf.required ? "-t" : "-f"}`]: "",
        }));

        setInitValues((prev) => ({
          ...prev,
          [`${"LOOKUP*FIELD"}-${cf?.id}${cf.required ? "-t" : "-f"}`]: "",
        }));
      }
    }
  }, [props.fields, appValues]);

  const [customerProperties, setCustomerProperties] = useState(null);

  useEffect(() => {
    if (props.properties) {
      const temp = props.properties?.filter((property) => {
        if (property?.customerProperty?.length > 0) {
          return property?.customerProperty.some(
            (cur) => cur.customerId == parseInt(localStorage.getItem("user_id"))
          );
        }
      });
      setCustomerProperties(temp);
    }
  }, [props.properties]);

  const validateRequired = () => {
    const customerFields = props.fields?.filter((cust) => {
      return cust.programId === props.Ids.programId && cust.deleted !== true;
    });
    if (!values) return true;
    for (const key in requiredUploads) {
      if (requiredUploads[key] === false) {
        return false;
      }
    }

    for (const [key, value] of Object.entries(values)) {
      if (
        (getRequiredFromKey(key) === "t" && value === "") ||
        (getRequiredFromKey(key) === "t" &&
          props.fields.filter((x) => {
            return x.fieldName === getFieldNameFromKey(key);
          })[0]?.fieldType === "BOOLEAN" &&
          value == "false")
      ) {
        if (key.split("-")[0] === "LOOKUP*FIELD" && myList.length > 0) {
          continue;
        }

        const currentField = customerFields.filter((x) => {
          return x.fieldName === getFieldNameFromKey(key);
        })[0];

        if (currentField?.fieldType === "CHECK") {
          let checkFound = false;
          for (const [key2, value2] of Object.entries(checkValues)) {
            if (parseInt(key2.split("-")[1]) === currentField.id) {
              if (value2 === true) {
                checkFound = true;
                break;
              }
            }
          }
          if (checkFound) {
            continue;
          }
        }
        return false;
      }
    }
    return true;
  };
  useEffect(() => {
    const customerUploads = props.uploads?.filter((up) => {
      return up.programId === props.Ids.programId && up.deleted === false;
    });
    const temp = customerUploads;
    setUploads(temp);
    const requiredUploads = {};
    temp.forEach((x) => {
      if (x.required) requiredUploads[x.id] = false;
    });
    setRequiredUploads(requiredUploads);
  }, [props.uploads, visibleUpload]);

  useEffect(() => {
    const customerUploads = props.uploads?.filter((up) => {
      return up.programId === props.Ids.programId && up.deleted === false;
    });
    customerUploads?.map((upload) => {
      // set a flag that shows the upload input
      let tmpArr = visibleUpload;
      tmpArr[upload.id] = true;
      setVisibleUpload(tmpArr);
    });
  }, []);

  useEffect(() => {
    // load files based off uploads
    getDocumentList();
  }, [uploads]);

  const checkVisible = () => {
    console.log(visibleUpload);
  };

  window["tt"] = checkVisible;

  const getDocumentList = async () => {
    try {
      const url =
        process.env.REACT_APP_PRODUCTION === "true"
          ? `https://expressproxy-prod.azurewebsites.net/reference/fetch-by-type`
          : `http://localhost:3000/reference/fetch-by-type`;

      const res = await axios.post(
        url,
        {
          referenceId: props.Ids?.appId,
          referenceType: "application",
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      window["tt2"] = res.data;

      //setDocumentList(res.data.data.filter((x)=>));
      const documentList = res.data.data;
      documentList.forEach((x) => {
        const secondaryid = x.secondaryId;
        if (secondaryid) {
          setRequiredUploads((prev) => ({
            ...prev,
            [secondaryid]: true,
          }));
        }
      });
      setCurDocumentList(res.data.data);
      res.data.data.forEach((x) => {
        // Find the DOM element with a matching secondaryId
        const domElement = document.querySelector(
          `[secondaryid="${x.secondaryId}"]`
        );

        if (domElement) {
          domElement.innerHTML = x.fileName;
          domElement.classList.add("cursor-pointer");
          domElement.classList.add("underline");
          domElement.onclick = function () {
            downloadFile(x.fileName);
          };

          // set the flag that shows the input field to be false
          let tmp = visibleUpload;
          tmp[x.secondaryId] = false;
          setVisibleUpload(tmp);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  // the function that saves all of the files. we must loop over all of the entries inside of fileUploads and run "uploadDocument"
  // which uploads the file and then calls "setDocumentLink" which creates the reference in the fileReference table
  const saveFiles = () => {
    Object.entries(fileUploads)?.map((x) => {
      let tmpObj = x[1];
      console.log(tmpObj);
      uploadDocument(tmpObj);
    });
  };

  const downloadFile = async (input) => {
    let tmpObj = {
      fileName: input,
    };
    try {
      const url =
        process.env.REACT_APP_PRODUCTION === "true"
          ? `https://expressproxy-prod.azurewebsites.net/blobstorage/download`
          : `http://localhost:3000/blobstorage/download`;

      const res = await axios.post(url, tmpObj, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        responseType: "arraybuffer",
      });

      createDownload(res.data, input);
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const createDownload = (byteStream, filename) => {
    const blob = new Blob([byteStream], { type: "application/octet-stream" });
    const blobUrl = URL.createObjectURL(blob);

    const anchor = document.createElement("a");
    anchor.href = blobUrl;
    anchor.download = filename;

    document.body.appendChild(anchor);
    anchor.click();

    // Remove the anchor from the DOM
    document.body.removeChild(anchor);

    // Clean up the URL
    URL.revokeObjectURL(blobUrl);
  };

  const handleFileNameChange = (e) => {
    const secondaryId = e.target?.dataset?.secondaryid;
    const fileName = e.target?.files?.[0]?.name;
    const tempRequiredUploads = requiredUploads;
    tempRequiredUploads[secondaryId] = true;
    setRequiredUploads((prev) => ({
      ...prev,
      [secondaryId]: fileName ? true : false,
    }));
    let tmpObj = {
      file: e.target?.files?.[0],
      appId: props.Ids.appId,
      secondaryId: e.target?.dataset?.secondaryid,
    };
    setFileUploads((prev) => ({
      ...prev, // spread operator to copy existing properties
      [e.target?.dataset?.secondaryid]: tmpObj, // add new property
    }));
  };

  const handleDeleteClick = (event) => {
    // Reset the associated file input by clearing its value
    hideDeleteModal();
    if (event.id) event = deleteEvent; // quick change to make the delete modal work with this
    const fileInput = event.target
      .closest(".flex")
      .querySelector("input[type='file']");
    if (fileInput) {
      fileInput.value = "";
    }

    const updateEvent = new Event("change", {
      bubbles: true,
      cancelable: true,
    });

    fileInput.dispatchEvent(updateEvent);

    /* this shouldnt be visible anymore so commenting out
    // delete the span that contains a link to the preexisting file if it exists
    const fileSpan = event.target.closest(".file_div").querySelector("span");
    if (fileSpan) {
      fileSpan.remove();
    }
    */
  };

  const handleDeleteClick2 = (event) => {
    // Reset the associated file input by clearing its value
    hideDeleteModal2();
    if (event.id) event = deleteEvent2; // quick change to make the delete modal work with this

    const fileSpan = event.target.closest(".file_div").querySelector("span");

    // create blank space for document at that location
    let tmpObj = {
      appId: props.Ids.appId,
      file: null,
      secondaryId: fileSpan?.dataset.secondaryid,
    };

    setFileUploads((prev) => ({
      ...prev, // spread operator to copy existing properties
      [fileSpan?.dataset.secondaryid]: tmpObj, // add new property
    }));

    // show the file input now
    let tmp = visibleUpload;
    tmp[fileSpan?.dataset.secondaryid] = true;
    setVisibleUpload(tmp);
  };

  const uploadDocument = async (input) => {
    // if no input file and they clicked delete on the file just delete the old link
    if (!input.file) {
      curDocumentList?.map((x) => {
        if (
          x.secondaryId == input.secondaryId &&
          visibleUpload[x.secondaryId] == true
        ) {
          console.log(x);
          deleteDocumentLink(x);
        }
      });
      return false;
    }

    const formData = new FormData(); //referenceId, fileName, fileLocation, uploader, referenceType
    formData.append("file", input.file);
    formData.append("appId", props.Ids.appId);
    formData.append("fileName", input.file.fileName);
    formData.append("referenceId", props.Ids.appId);
    formData.append("referenceType", "application");
    formData.append("uploader", localStorage.getItem("user_id"));

    // upload the document
    try {
      const url =
        process.env.REACT_APP_PRODUCTION === "true"
          ? `https://expressproxy-prod.azurewebsites.net/blobstorage/upload`
          : `http://localhost:3000/blobstorage/upload`;

      const res = await axios.post(url, formData, {
        headers: {
          "Content-Type": "application/octet-stream",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      formData.append("fileLocation", res.data.name);
      let tmpAns = res.data;
      tmpAns["secondaryId"] = input.secondaryId;

      // delete all old links to the entry
      curDocumentList?.map((x) => {
        if (x.secondaryId == input.secondaryId) {
          console.log(x);
          deleteDocumentLink(x);
        }
      });

      // set the new document link
      setDocumentLink(tmpAns);
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const setDocumentLink = async (data) => {
    // add the links to the document
    try {
      const url =
        process.env.REACT_APP_PRODUCTION === "true"
          ? `https://expressproxy-prod.azurewebsites.net/reference/add`
          : `http://localhost:3000/reference/add`;

      const res = await axios.post(
        url,
        {
          referenceId: props.Ids.appId,
          referenceType: "application",
          secondaryId: data.secondaryId,
          fileName: data.name,
          fileLocation: data.name,
          uploader: localStorage.getItem("user_id"),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      //getDocumentList();
    } catch (err) {
      console.log(err);
    }

    // update the document list
  };

  const deleteDocumentLink = async (input) => {
    console.log(input);
    if (!input?.id) return false;

    try {
      const url =
        process.env.REACT_APP_PRODUCTION === "true"
          ? `https://expressproxy-prod.azurewebsites.net/reference/delete`
          : `http://localhost:3000/reference/delete`;

      const res = await axios.post(
        url,
        {
          id: input.id,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const resubmit = async () => {
    try {
      console.log("resubmit", staffMessage);
      if (validateRequired()) {
        console.log("validate");
        const url =
          process.env.REACT_APP_PRODUCTION === "true"
            ? `https://expressproxy-prod.azurewebsites.net/application/update`
            : `http://localhost:3000/application/update`;

        const res = await axios.post(
          url,
          {
            id: props.Ids.appId,
            unlocked: false,
            propertyId: props.Ids.propertyId,
            updateDate: staffMessage ? false : true,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        const app = res.data.data;

        const url_status =
          process.env.REACT_APP_PRODUCTION === "true"
            ? `https://expressproxy-prod.azurewebsites.net/application/status/update`
            : `http://localhost:3000/application/status/update`;
        //update to next status
        console.log("NEXT APP STATUS: ", nextAppStatus);
        await axios.post(
          url_status,
          {
            id: currentAppStatusId,
            applicationId: props.Ids.appId,
            statusId: nextAppStatus,
            adminRespond: false,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (values) {
          for (const [key, value] of Object.entries(values)) {
            if (initValues[key] !== value && value !== "") {
              const valueInfo = {
                value: value,
                applicationId: parseInt(app?.id),
                fieldId: getFieldIdFromKey(key),
                checkValueId: null,
                multiplier: null,
                lookupName1: null,
                lookupName2: null,
                calcResult: null,
              };

              await dispatch(addApplicationValue(valueInfo));
            }
          }
        }

        for (const [key, value] of Object.entries(checkValues)) {
          if (initCheckValues[key] !== value) {
            const valueInfo = {
              id: key.split("-")[0],
              value: value,
            };
            await dispatch(updateApplicationValue(valueInfo));
          }
        }

        if (deleteList.length > 0) {
          deleteList?.forEach(async (x) => {
            await dispatch(deleteApplicationValue({ id: x }));
            await dispatch(fetchApplicationValues()); //not updating in time tmp fix in forloop
          });
        }

        if (addList.length > 0) {
          addList?.forEach(async (x) => {
            const tempObj = {
              applicationId: parseInt(props.Ids.appId),
              fieldId: myListFieldId,
              multiplier: x.multiplier ? x.multiplier : 1,
              lookupName1: x.lookupName1,
              lookupName2: x.lookupName2,
              calcResult: useMultiplier
                ? x.multiplier * parseInt(x.inputLabel)
                : parseInt(x.inputLabel),
              value: x.inputLabel,
              checkValueId: null,
            };
            await dispatch(addApplicationValue(tempObj));
            await dispatch(fetchApplicationValues()); //not updating in time tmp fix in forloop
          });
        }

        const temp = statusList?.filter((el) => {
          return el.programId === props.Ids.programId;
        });

        console.log("STATUSLIST*TEMP: ", temp);
        if (temp?.length > 0) {
          const milestones = temp.filter((status) => {
            return status.milestone === true;
          });

          console.log("STATUS*LIST*milestones: ", milestones);
          if (milestones?.length > 0) {
            //status log for submitlater vs customer-unlock should differ. Submit later will use existing logic while customer unlock should use application next_status
            const statusIdArray = milestones?.map((status) => {
              return parseInt(status.statusId);
            });
            console.log("status-id-array: ", statusIdArray);
            const minId = Math.min(...statusIdArray);
            const status = //status log entries are only mile stones, confirm with C.H.
              milestones?.filter((x) => {
                return parseInt(x.statusId) === parseInt(nextAppStatus);
              }).length > 0
                ? milestones?.filter((x) => {
                    return parseInt(x.statusId) === parseInt(nextAppStatus);
                  })[0]
                : null;

            console.log("STATUS: ", status);
            if (status) {
              const statusLog = {
                statusName: status.name,
                statusNumber: status.number,
                userId: null,
                comment: "",
                applicationId: props.Ids.appId,
                staffAssignmentId: null,
              };
              const res = await dispatch(addStatusLog(statusLog));
            }
          }
        }

        saveFiles();

        props.closeUnlockModal();

        // await dispatch(fetchApplications());
        await dispatch(
          applicationById({ id: localStorage.getItem("user_id") })
        );
        await dispatch(fetchStatusList());
        await dispatch(fetchApplicationStatus());
        await dispatch(fetchApplicationValues());

        let res1 = await dispatch(
          getSubmissionEmailTemplate({ id: props.Ids.programId })
        );

        console.log("applicationID: ", props.Ids.appId);
        const programCost = programs.filter((p) => {
          return p.id === props.Ids.programId;
        })[0].defaultUnitCost;

        console.log(
          "program-1: ",
          programs.filter((p) => {
            return p.id === props.Ids.programId;
          })
        );

        let cp = customerProperties.filter((x) => {
          return x.id === props.Ids.propertyId;
        })[0];

        console.log("customer-property: ", cp);

        const fullAddress = formatFullAddress([
          cp.mfcStreetNo,
          cp.streetPfxDir,
          cp.mfcStreetName,
          cp.streetNmSfx,
          cp.streetSfxDir,
          cp.secAddrId,
          cp.secAddrRange,
          cp.city,
          cp.provinceCd,
          cp.postalCode,
        ]);

        console.log("applicationID; ", props.Ids.appId);
        console.log("fullAddress: ", fullAddress);
        console.log("program-cost: ", programCost);

        console.log("program-cost: ", programCost);
        let lcVars = res1.payload.data.bodyText
          .replace("[Application ID]", "[application id]")
          .replace("[Cost]", "[cost]")
          .replace("[Property Address]", "[property address]");

        let bodyTextFinal = lcVars
          .replace("[application id]", props.Ids.appId)
          .replace("[cost]", `$${programCost}`)
          .replace("[property address]", fullAddress);

        if (process.env.REACT_APP_PRODUCTION === "true") {
          const response1 = await axios.post(
            `https://expressproxy-prod.azurewebsites.net/bawsca/emails/send`,
            {
              subject: `Application ${props.Ids.appId} (${
                programs?.filter((x) => {
                  return x?.id === props.Ids.programId;
                })[0].programName
              }) has been submitted in the Water Savings Center`,
              body: `Application ${props.Ids.appId} (${
                programs?.filter((x) => {
                  return x?.id === props.Ids.programId;
                })[0].programName
              }) has been submitted in the Water Savings Center`,
              to: ["water.cons@acwd.com"],
              fromName: "ACWD Water Savings Center",
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
        }

        // if (process.env.REACT_APP_PRODUCTION === "true") {
        //   const response1 = await axios.post(
        //     `https://expressproxy-prod.azurewebsites.net/bawsca/emails/send`,
        //     {
        //       subject: `Application ${props.Ids.appId} (${
        //         programs?.filter((x) => {
        //           return x?.id === props.Ids.programId;
        //         })[0].programName
        //       }) has been submitted in the Water Savings Center`,
        //       body: `Application ${props.Ids.appId} (${
        //         programs?.filter((x) => {
        //           return x?.id === props.Ids.programId;
        //         })[0].programName
        //       }) has been submitted in the Water Savings Center`,
        //       to: ["test@test.com"], //water.cons@acwd.com
        //       fromName: "ACWD Water Savings Center",
        //     },
        //     {
        //       headers: {
        //         Authorization: `Bearer ${localStorage.getItem("token")}`,
        //       },
        //     }
        //   );
        // }
        const toastPayload = {
          index: 0,
          description: 8,
          type: "application",
        };
        setToast(toastPayload);
      } else {
        const toastPayload = {
          index: 1,
          description: 1,
          type: "application",
        };
        setToast(toastPayload);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const submit = async (e) => {
    try {
      if (validateRequired()) {
        if (propertyId) {
          const app = await dispatch(
            addApplication({
              customerId: parseInt(localStorage.getItem("user_id")),
              propertyId: parseInt(propertyId),
              programId: props.program?.id,
            })
          );

          for (const [key, value] of Object.entries(values)) {
            const valueInfo = {
              value: value,
              applicationId: parseInt(app.payload?.id),
              fieldId: getFieldIdFromKey(key),
            };
            const addRes = await dispatch(addApplicationValue(valueInfo));
          }

          //not required for resubmit
          // await dispatch( //customer should not add water savings
          //   addWaterSavings({
          //     applicationId: parseInt(app.payload?.id),
          //     value: props.program.defaultUnitWaterSavings,
          //     userId: parseInt(localStorage.getItem("user_id")),
          //     comment: "",
          //   })
          // );
          const statusPayload = {
            statusId: 0,
            applicationId: app.payload?.id,
            adminRespond: true,
          };
          await dispatch(addApplicationStatus(statusPayload));
          // await dispatch(fetchApplications());
          await dispatch(
            applicationById({ id: localStorage.getItem("user_id") })
          );

          await dispatch(fetchStatusList());
          props.closeUnlockModal();
        } else {
          const toastPayload = {
            index: 1,
            description: 0,
            type: "application",
          };
          setToast(toastPayload);
        }
      } else {
        const toastPayload = {
          index: 1,
          description: 1,
          type: "application",
        };
        setToast(toastPayload);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const [staffMessage, setStaffMessage] = useState("");

  const getCommunicationLogs = async () => {
    try {
      const url =
        process.env.REACT_APP_PRODUCTION === "true"
          ? `https://expressproxy-prod.azurewebsites.net/application/communication-history/fetch-by-id`
          : `http://localhost:3000/application/communication-history/fetch-by-id`;

      const res = await axios.post(
        url,
        { id: props.Ids.appId },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const communication_log = res.data.data;

      // if (communication_log.length > 0) {
      //   const sorted_logs = communication_log.sort((a, b) => {
      //     return b.id - a.id;
      //   });
      //   setStaffMessage(sorted_logs[0].value);
      // }
      const unlockNotes = communication_log.filter((x) => {
        return x.unlockNote;
      });
      if (unlockNotes.length > 0) {
        const sorted_logs = unlockNotes.sort((a, b) => {
          return b.id - a.id;
        });
        setStaffMessage(sorted_logs[0].value);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getCommunicationLogs();
  }, [props.Ids.appId]);

  return (
    <Backdrop
      onClick={() => {
        props.openWarningModal(props.type);
      }}
    >
      <motion.div
        className=" rounded-md w-8/12 max-h-[66.67%] overflow-auto bg-white dark:bg-wc-gradient bg-cover text-wc-green dark:text-white shadow-md font-sans relative z-100"
        onClick={(e) => e.stopPropagation()}
        variants={slideIn}
        initial="hidden"
        animate="visible"
        exit="exit"
        transition={{ duration: 0.5 }}
      >
        <div className="w-full flex flex-col line-clamp-7 min-w-12">
          <div className="flex gap-2 items-center justify-center rounded-t sticky top-0 z-10">
            <h2 className=" text-start w-full rounded-t tracking-wider  text-white bg-blue-reef p-4 ">
              {program && program.programName} Application
            </h2>
          </div>
          <div class="p-8">
            {props.type === "read-write" && staffMessage && (
              <div className="w-full h-32 mb-12 rounded shadow-sm p-2">
                <ReactQuill
                  disabled
                  className="w-full rounded h-full bg-yellow-50 text-black shadow-sm"
                  value={staffMessage}
                  readOnly={true}
                  modules={{ toolbar: false }}
                />
              </div>
            )}

            <div className="flex flex-col items-start gap-1">
              <span className="text-blue-reef font-bold">
                Select the property associated with this application
              </span>
              <select
                className="border-gray-200 border focus:outline-none rounded h-[2.5rem] text-lg dark:text-black  w-6/12 "
                disabled
                onChange={(e) => {
                  setPropertyId(e.target.value);
                }}
              >
                {/* <option disable selected>
                  Select A Property
                </option> */}
                {customerProperties &&
                  customerProperties?.length > 0 &&
                  customerProperties?.map((el, index) => {
                    return (
                      <option
                        selected={el?.id === props.Ids.propertyId}
                        key={index}
                        value={el?.id}
                      >
                        {formatFullAddress([
                          el.mfcStreetNo,
                          el.streetPfxDir,
                          el.mfcStreetName,
                          el.streetNmSfx,
                          el.streetSfxDir,
                          el.secAddrId,
                          el.secAddrRange,
                          el.city,
                          el.provinceCd,
                          el.postalCode,
                        ])}
                      </option>
                    );
                    // }
                  })}
              </select>
              <div className="w-6/12 mb-2">
                <span className="text-gray-400">
                  You must first register your property to your account in "My
                  Profile" to start an application. If your property is not
                  showing in the dropdown, it may not meet the program
                  eligibility property type or the account may be inactive.
                </span>
              </div>
            </div>

            {program && (
              <div className="pb-12">
                <ReactQuill
                  disabled
                  theme="snow"
                  className="w-full rounded mb-4 cursor-pointer"
                  value={program.description}
                  readOnly={true}
                  modules={{ toolbar: false }}
                />
              </div>
            )}
            <div className="flex items-center  gap-2 mb-4">
              <span className="text-red-500">*</span>
              <span>Indicates a Required Field or Upload</span>
            </div>
            <div className="flex flex-col gap-6">
              <div className="flex flex-wrap gap-6">
                {fields && appValues && checkValues && fields}
              </div>
              <div className="flex flex-col gap-6">
                {uploads?.map((upload, index) => {
                  return (
                    <div className="flex flex-col">
                      <div className="flex">
                        {upload.required && (
                          <span className="text-red-500">*</span>
                        )}
                        <span className="font-bold">{upload.name}</span>
                      </div>

                      <label>
                        {upload.description.replace(/<br\s*\/?>/gi, "\n")}
                      </label>

                      {props.type === "read-only" && (
                        <div className="file_div">
                          <div>
                            <span
                              className="cursor-pointer"
                              secondaryid={upload.id}
                            ></span>
                          </div>
                        </div>
                      )}
                      {props.type === "read-write" && (
                        <div className="file_div">
                          <div className="flex gap-4">
                            {!visibleUpload[upload.id] && (
                              <>
                                <span
                                  secondaryid={upload.id}
                                  data-secondaryid={upload.id}
                                ></span>
                                <BsFillTrashFill
                                  className="cursor-pointer dark:text-white hover:text-red-400 mt-auto mb-auto"
                                  onClick={(e) => {
                                    setDeleteEvent2(e);
                                    openDeleteModal2();
                                  }}
                                />
                              </>
                            )}
                          </div>
                          {visibleUpload[upload.id] && (
                            <div className="flex gap-4 items-center">
                              <input
                                type="file"
                                className="flex flex-col border-gray-200 border w-6/12 p-2 rounded cursor-pointer"
                                onChange={handleFileNameChange}
                                data-secondaryid={upload.id}
                                secondaryid={upload.id}
                              />
                              <BsFillTrashFill
                                className="cursor-pointer dark:text-white hover:text-red-400"
                                onClick={(e) => {
                                  setDeleteEvent(e);
                                  openDeleteModal();
                                }}
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          {props.type === "read-only" && (
            <div className="w-full flex p-8">
              <Button
                variant="outline"
                className="bg-blue-reef w-2/12 text-white rounded mx-auto "
                onClick={() => {
                  props.closeUnlockModal();
                }}
              >
                Close
              </Button>
            </div>
          )}

          {props.type === "read-write" && (
            <div className="flex justify-center gap-1 mb-4 p-8">
              <Button
                variant="outline"
                className="bg-gray-400  text-white rounded"
                onClick={() => {
                  console.log("submit later");
                  submitLater();
                }}
              >
                Save for later
              </Button>
              <Button
                variant="outline"
                className="bg-green-reef  text-white rounded"
                onClick={() => {
                  resubmit();
                }}
              >
                Submit
              </Button>
            </div>
          )}
        </div>
      </motion.div>
      {/* </motion.div> */}
      {showDeleteModal && (
        <DeleteModal
          handleClose={hideDeleteModal}
          deleteFunc={handleDeleteClick}
          id={deleteEvent}
          heading={"Are you sure you want to delete this document?"}
        />
      )}
      {showDeleteModal2 && (
        <DeleteModal
          handleClose={hideDeleteModal2}
          deleteFunc={handleDeleteClick2}
          id={deleteEvent2}
          heading={"Are you sure you want to delete this document?"}
        />
      )}
    </Backdrop>
  );
};

export default CustomerUnlockApplicationModal;
