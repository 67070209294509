import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
const axios = require("axios").default;

axios.defaults.headers.common["Client"] = "ACWD";

const prod_base_url = "https://expressproxy-prod.azurewebsites.net/password";
const local_base_url = "http://localhost:3000/password";
const active_base_url =
  process.env.REACT_APP_PRODUCTION === "true" ? prod_base_url : local_base_url;

const initialState = {};

export const passwordResetByUrl = createAsyncThunk(
  "password/reset-by-url",
  async (sp) => {
    try {
      const res = await axios.post(`${active_base_url}/reset-by-url`, sp);
      const updatedSP = res.data;
      return updatedSP;
    } catch (err) {
      return err.response.data;
    }
  }
);

export const passwordResetSlice = createSlice({
  name: "passwordReset",
  initialState,
  reducers: {},
  extraReducers(builder) {},
});

export const validatePasswordReset = createAsyncThunk(
  "password/validate-reset",
  async (sp) => {
    try {
      const res = await axios.post(`${active_base_url}/validate-reset`, sp);
      const updatedSP = res.data;
      return updatedSP;
    } catch (err) {
      return err.response.data;
    }
  }
);

export const createPasswordReset = createAsyncThunk(
  "password/create-reset",
  async (sp) => {
    try {
      const res = await axios.post(`${active_base_url}/create-reset`, sp);
      const updatedSP = res.data;
      return updatedSP;
    } catch (err) {
      return err.response.data;
    }
  }
);

export default passwordResetSlice.reducer;
