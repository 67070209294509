import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { useState } from "react";
import { useDispatch } from "react-redux";
import Backdrop from "../Misc/Backdrop";
import ReactQuill, { Quill } from "react-quill";

import { Button } from "../Ui/button";

import {
  createEmailTemplate,
  updateEmailTemplate,
} from "../../features/emailTemplate";

const dropIn = {
  hidden: { y: "-100vh", opacity: 0 },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: { y: "100vh", opacity: 0 },
};
const AddEmailTemplateModal = ({ props }) => {
  const dispatch = useDispatch();

  const [template, setTemplate] = useState({
    emailName: "",
    subjectLine: "",
    programId: props.programId,
  });

  const [bodyText, setBodyText] = useState("");

  useEffect(() => {
    console.log("AddTemplateModal: ", props);
    if (props.mode.type === "edit") {
      setTemplate({
        id: props.mode.id,
        emailName: props.mode.emailName,
        subjectLine: props.mode.subjectLine,
      });

      setBodyText(props.mode.bodyText);
    }
  }, [props]);

  useEffect(() => {
    console.log("Template: ", template);
    console.log("Body Text: ", bodyText);
  }, [template, bodyText]);

  const submit = async (e) => {
    try {
      e.stopPropagation();
      e.preventDefault();
      const emailTemplate = template;
      emailTemplate["bodyText"] = bodyText.replace(/"/g, "");
      const res =
        props.mode.type === "add"
          ? await dispatch(createEmailTemplate(emailTemplate))
          : await dispatch(updateEmailTemplate(emailTemplate));
      props.getEmailTemplates();
      props.handleClose();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Backdrop onClick={props.handleClose}>
      <motion.div
        className="ab rounded w-[45rem] bg-white dark:bg-wc-gradient bg-cover text-wc-green dark:text-white shadow-md font-sans"
        onClick={(e) => e.stopPropagation()}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
        transition={{ duration: 0.5 }}
      >
        <div className="w-full p-8 flex flex-col gap-4 line-clamp-7 ">
          <div className=" rounded w-full flex gap-2 items-center justify-start ">
            <h2 className="text-lg font-bold text-center flex gap-2 text-blue-reef">
              Add New Email Template
            </h2>
          </div>
          <div className="flex flex-col ">
            <span className=" text-blue-reef">Email Name</span>
            <input
              type="text"
              className="text-black rouh-text-inputnded  border-2 border-gray-300 rounded h-[2.5rem] pl-2"
              defaultValue={template["emailName"]}
              onChange={(e) => {
                setTemplate((prev) => ({
                  ...prev,
                  emailName: e.target.value,
                }));
              }}
            />
          </div>
          <div className="flex flex-col ">
            <span className=" text-blue-reef">Subject Line</span>
            <input
              type="text"
              className="text-black rouh-text-inputnded  border-2 border-gray-300 rounded h-[2.5rem] pl-2"
              placeholder=""
              defaultValue={template["subjectLine"]}
              onChange={(e) => {
                setTemplate((prev) => ({
                  ...prev,
                  subjectLine: e.target.value,
                }));
              }}
            />
          </div>
          <div className="flex flex-col mb-12">
            <span className=" text-blue-reef">Email Body Text</span>
            {/* <textarea
              className="text-black rouh-text-inputnded  border-2 border-gray-300 resize-none h-32 rounded p-2"
              rows={3}
              defaultValue={template["bodyText"]}
              onChange={(e) => {
                setTemplate((prev) => ({
                  ...prev,
                  bodyText: e.target.value,
                }));
              }}
            /> */}
            <ReactQuill
              theme="snow"
              className="w-full text-black rounded h-[8rem]"
              value={bodyText}
              onChange={setBodyText}
            />
          </div>
          {/* <div className="flex flex-col ">
            <div className="flex gap-4">
              <label>Required Field ?</label>
              <input
                type="checkbox"
                onChange={(e) => {
                  setUpload((prev) => ({
                    ...prev,
                    required: e.target.checked,
                  }));
                }}
              ></input>
            </div>
          </div> */}

          <Button
            variant="outline"
            className="bg-green-reef text-white rounded ml-auto w-2/12"
            onClick={(e) => {
              submit(e);
            }}
          >
            Save
          </Button>
        </div>
      </motion.div>
    </Backdrop>
  );
};

export default AddEmailTemplateModal;
