import React, { useState, useEffect } from "react";
import { selectAllCustomers } from "../../features/customer";
import { useDispatch, useSelector } from "react-redux";

const axios = require("axios").default;

axios.defaults.headers.common["Client"] = "ACWD";

const Customer = ({ props }) => {
  const getAccount = async (input) => {
    console.log("get-account-input", input);
    const url =
      process.env.REACT_APP_PRODUCTION === "true"
        ? `https://expressproxy-prod.azurewebsites.net/account/fetch-by-id`
        : `http://localhost:3000/account/fetch-by-id`;

    const res = await axios.post(
      url,
      { id: parseInt(input) },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    return res.data;
  };

  const setAccountStatusText = async () => {
    if (props.customer.accountNo) {
      let tmp = await getAccount(props?.customer?.accountNo);
      setAccountStatus(
        tmp.data?.cutoffDt &&
          tmp.data?.cutoffDt !== "NULL" &&
          tmp.data?.cutoffDt !== ""
          ? "Not Active at Property"
          : "Active at Property"
      );
    } else {
      setAccountStatus("N/A");
    }
  };

  // setAccountStatusText();
  useEffect(() => {
    props?.customer?.accountNo && setAccountStatusText();
  }, [props]);

  const [accountStatus, setAccountStatus] = useState("Loading...");

  const customers = useSelector(selectAllCustomers);
  const [customer, setCustomer] = useState(null);
  useEffect(() => {
    const currentCustomer =
      customers?.filter((el) => {
        return el.id === props?.customer?.customerId;
      }).length > 0
        ? customers.filter((el) => {
            return el.id === props?.customer?.customerId;
          })[0]
        : null;
    currentCustomer && setCustomer(currentCustomer);
  }, [customers, props.customer]);

  function numberComma(input) {
    console.log(input);
    let nf = new Intl.NumberFormat("en-US");
    input = nf.format(input);
    return input;
  }

  return (
    <tr
      className={`dark:text-white text-black text-start p-3 cursor-pointer hover:bg-blue-50`}
      onClick={() => {
        props.openPopupModal(props?.customer?.customerId);
      }}
    >
      <td className="p-3 dark:text-white underline text-blue-reef whitespace-nowrap">
        {props.customer && props?.customer?.customerId}
      </td>

      <td className="p-3 dark:text-white whitespace-nowrap">
        {customer && customer.firstName}
      </td>

      <td className="p-3 dark:text-white whitespace-nowrap">
        {customer && customer.lastName}
      </td>
      <td className="p-3 dark:text-white r whitespace-nowrap">
        {props.customer && props?.customer?.accountNo}
      </td>
      <td className="p-3 dark:text-white whitespace-nowrap">
        {accountStatus && props?.customer?.accountNo ? accountStatus : "N/A"}
      </td>
      <td className="p-3 dark:text-white whitespace-nowrap flex flex-col">
        <span>
          {props.customer &&
            props?.customer?.singleHomeFamily &&
            "Single Family Home"}
        </span>
        <span>
          {props.customer &&
            props?.customer?.multiFamilyComplex &&
            "Multi Family Complex or HOA"}
        </span>
        <span>
          {props.customer &&
          props?.customer?.mfcUnitNo &&
          props?.customer?.mfcUnitNo != "0"
            ? `Unit: ${props?.customer?.mfcUnitNo}`
            : ""}
        </span>
        <span>{`${props?.customer?.commercial ? "Commercial" : ""}`}</span>
        <span>
          {props?.customer?.typeofCommercialProperty &&
          props?.customer?.typeofCommercialProperty != "null"
            ? props.customer.typeofCommercialProperty
            : ""}
        </span>
        <span>
          {props.customer &&
            props?.customer?.isHoa &&
            `HOA Name: ${props?.customer?.hoAname}`}
        </span>
        <span>
          {props.customer &&
            props?.customer?.landscape &&
            "Landscape (Irrigation Account)"}
        </span>
        <span>
          {props.customer &&
            props?.customer?.residential &&
            "Mixed Use Commercial / Residential"}
        </span>
      </td>
      <td className="p-3 dark:text-white whitespace-nowrap">
        {props.customer && (
          <input
            type="checkbox"
            checked={props?.customer?.owner ? true : false}
          />
        )}
      </td>
      <td className="p-3 dark:text-white whitespace-nowrap">
        {props.customer && (
          <input
            type="checkbox"
            checked={props?.customer?.tenant ? true : false}
          />
        )}
      </td>
      <td className="p-3 dark:text-white whitespace-nowrap">
        {props.customer && (
          <input
            type="checkbox"
            checked={props?.customer?.isAccountHolder ? true : false}
          />
        )}
      </td>
      <td className="p-3 dark:text-white whitespace-nowrap">
        {props.customer && (
          <div className="flex flex-col items-start justify-center">
            <input
              type="checkbox"
              checked={props?.customer?.isLandscaper ? true : false}
            />
            <span> {props?.customer?.landscaperContact}</span>
          </div>
        )}
      </td>
      <td className="p-3 dark:text-white whitespace-nowrap">
        {props.customer && (
          <input
            type="checkbox"
            checked={props?.customer?.isPropertyManager ? true : false}
          />
        )}
      </td>
      <td className="p-3 dark:text-white whitespace-nowrap">
        {props.customer && (
          <input
            type="checkbox"
            checked={props?.customer?.isLandlord ? true : false}
          />
        )}
      </td>
      <td className="p-3 dark:text-white whitespace-nowrap">
        <input
          type="checkbox"
          checked={props?.customer?.isOtherRelationship ? true : false}
        />
        <span> {props.customer && props?.customer?.otherRelationshipText}</span>
      </td>
    </tr>
  );
};

export default Customer;
