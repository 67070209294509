import React, { useEffect, useState } from "react";
import customer from "../../features/customer";
import { formatFullAddress } from "../../utils/formatAddress";
import { selectAllProperties } from "../../features/properties";
import { useSelector } from "react-redux";

const axios = require("axios").default;

axios.defaults.headers.common["Client"] = "ACWD";

const CustomerProperty = ({ props, index }) => {
  const properties = useSelector(selectAllProperties);
  const [customerProperty, setCustomerProperty] = useState(null);
  const [property, setProperty] = useState(null);
  useEffect(() => {
    props.property?.customerProperty?.length > 1
      ? setCustomerProperty(
          props.property?.customerProperty?.filter((el) => {
            return el.customerId === parseInt(localStorage.getItem("user_id"));
          })[0]
        )
      : setCustomerProperty(props.property.customerProperty[0]);
  }, [props]);

  useEffect(() => {
    const propertyMap = properties?.filter((x) => {
      return x.id === customerProperty?.propertyId;
    })[0];
    setProperty(propertyMap);
  }, [customerProperty]);
  const [applications, setApplications] = useState(null);
  useEffect(() => {
    const temp = props.applications?.filter((app) => {
      return app.propertyId === props.property.id;
    });

    setApplications(temp);
  }, [props.applications]);

  const checkBoxChecked = () => {
    return (
      <input
        type="checkbox"
        className="mr-3"
        checked={true} // TODO add boolean for if the property doesnt have a billing or figure out how to put in db
        disabled={true}
      />
    );
  };

  const checkBoxUnchecked = () => {
    return (
      <input
        type="checkbox"
        className="mr-3"
        checked={false} // TODO add boolean for if the property doesnt have a billing or figure out how to put in db
        disabled={true}
      />
    );
  };
  const [accountStatus, setAccountStatus] = useState("Loading...");

  const getAccount = async (input) => {
    try {
      console.log("get-account-input: ", input);
      const url =
        process.env.REACT_APP_PRODUCTION === "true"
          ? `https://expressproxy-prod.azurewebsites.net/account/fetch-by-id`
          : `http://localhost:3000/account/fetch-by-id`;

      const res = await axios.post(
        url,
        { id: parseInt(input) },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      return res.data;
    } catch (err) {
      console.log("get-account-err: ", err);
    }
  };

  const setAccountStatusText = async (accountNo) => {
    let tmp = await getAccount(props.property.customerProperty[0]?.accountNo);
    console.log("setAccountStatusText: ", tmp);
    setAccountStatus(
      tmp.data
        ? tmp.data?.cutoffDt &&
          tmp.data?.cutoffDt !== "NULL" &&
          tmp.data?.cutoffDt !== ""
          ? "Not Active at Property"
          : "Active at Property"
        : "N/A"
    );
  };

  useEffect(() => {
    console.log("customer-property-4321", props.property);
    props.property.customerProperty[0]?.accountNo && setAccountStatusText();
  }, [props]);

  const propertyAddress = () => {
    let target = props.property;
    if (target) {
      let x = target.location;
      if (!x) return "";
      return (
        (x.houseNo ? x.houseNo : "") +
        " " +
        (x.streetPfxDir ? x.streetPfxDir : "") +
        " " +
        (x.streetNm ? x.streetNm : "") +
        " " +
        (x.streetNmSfx ? x.streetNmSfx : "") +
        " " +
        (x.streetSfxDir ? x.streetSfxDir : "") +
        " " +
        (x.city ? x.city : "") +
        " " +
        (x.provinceCd ? x.provinceCd : "")
      )
        .replace(/\s+/g, " ")
        .trim();
    } else return "N/A";
  };

  return (
    <tr
      className={`rounded-lg dark:text-white text-black text-start cursor-pointer hover:bg-blue-50 `}
    >
      {/* Property Address */}
      <td className="p-3 text-sm whitespace-nowrap">
        {/* {property?.manualEntry ? (
          <span>{`${
            property.mfcStreetNo ? property.mfcStreetNo : ""
          } ${property.mfcStreetName.trim()}, ${property.city.trim()}, ${property.provinceCd.trim()} ${property.postalCode.trim()}`}</span>
        ) : (
          formatFullAddress([
            props.property?.location?.houseNo,
            props.property?.location?.streetPfxDir,
            props.property?.location?.streetNm,
            props.property?.location?.streetNmSfx,
            props.property?.location?.streetSfxDir,
            props.property?.location?.secAddrId,
            props.property?.location?.secAddrRange,
            props.property?.location?.city,
            props.property?.location?.provinceCd,
            props.property?.postalCode,
          ])
        )} */}
        {formatFullAddress([
          property?.mfcStreetNo,
          property?.streetPfxDir,
          property?.mfcStreetName,
          property?.streetNmSfx,
          property?.streetSfxDir,
          property?.secAddrId,
          property?.secAddrRange,
          property?.city,
          property?.provinceCd,
          property?.postalCode,
        ])}
      </td>
      {/* Account ID */}
      <td className="p-3 text-sm whitespace-nowrap">
        {customerProperty && customerProperty?.accountNo
          ? `${customerProperty?.accountNo}`
          : "N/A"}
      </td>
      {applications && (
        <td className="p-3 text-sm whitespace-nowrap">
          {accountStatus && customerProperty?.accountNo ? accountStatus : "N/A"}
        </td>
      )}
      {/* 
      Property ID commented out by CH request
      <td className="p-3 text-sm whitespace-nowrap">
        {props.property?.id ? props.property.id : "Null"}
      </td>
      */}
      <td className="p-3 text-sm whitespace-nowrap">
        <div>
          {customerProperty?.singleHomeFamily && <div>Single Family Home</div>}
          {customerProperty?.multiFamilyComplex && (
            <>
              <div>Multi Family Complex or HOA</div>
              {customerProperty?.multiFamilyComplex && (
                <div>Unit: {customerProperty?.mfcUnitNo}</div>
              )}
              {customerProperty?.hoAname && (
                <div>HOA Name: {customerProperty?.hoAname}</div>
              )}
            </>
          )}
          {customerProperty?.residential && (
            <div>Mixed Use Comm / Residential</div>
          )}
          {customerProperty?.commercial && (
            <>
              <div>Commercial</div>
              {customerProperty?.commercialUse && (
                <div>Use: {customerProperty?.commercialUse}</div>
              )}
            </>
          )}
          {customerProperty?.landscape && (
            <div>Landscape (Irrigation Account)</div>
          )}
        </div>
      </td>

      {/* The following properties need to be confirmed with CH */}
      <td className="p-3 text-sm whitespace-nowrap">
        {customerProperty?.owner ? checkBoxChecked() : checkBoxUnchecked()}
      </td>
      <td className="p-3 text-sm whitespace-nowrap">
        {customerProperty?.tenant ? checkBoxChecked() : checkBoxUnchecked()}
      </td>
      <td className="p-3 text-sm whitespace-nowrap">
        {customerProperty?.isAccountHolder
          ? checkBoxChecked()
          : checkBoxUnchecked()}
      </td>
      <td className="p-3 text-sm whitespace-nowrap">
        {customerProperty?.isLandscaper
          ? checkBoxChecked()
          : checkBoxUnchecked()}
        {customerProperty?.isLandscaper
          ? customerProperty.landscaperContact
          : ""}
      </td>
      <td className="p-3 text-sm whitespace-nowrap">
        {customerProperty?.isPropertyManager
          ? checkBoxChecked()
          : checkBoxUnchecked()}
      </td>
      <td className="p-3 text-sm whitespace-nowrap">
        {customerProperty?.isLandlord ? checkBoxChecked() : checkBoxUnchecked()}
      </td>
      <td className="p-3 text-sm whitespace-nowrap">
        {customerProperty?.isOtherRelationship
          ? checkBoxChecked()
          : checkBoxUnchecked()}
        {customerProperty?.isOtherRelationship
          ? customerProperty.otherRelationshipText
          : ""}
      </td>
    </tr>
  );
};

export default CustomerProperty;
