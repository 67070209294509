import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { useState } from "react";
import { useDispatch } from "react-redux";
import Backdrop from "../Misc/Backdrop";
import { setToast } from "../../utils/ToastNotification";

import { fetchFields, updateField } from "../../features/programField";
import { addDropdownValue } from "../../features/dropdownValue";
import { addCheckValue } from "../../features/checkValue";
import {
  deleteLookupOption,
  createLookupOption,
  updateLookupOption,
} from "../../features/lookupOption";

import DropdownInput from "./DropdownInput";
import { Button } from "../Ui/button";
import { updateLookupCharacteristic } from "../../features/lookupCharacteristic";
import { MdDelete } from "react-icons/md";

const axios = require("axios").default;

axios.defaults.headers.common["Client"] = "ACWD";

const dropIn = {
  hidden: { y: "-100vh", opacity: 0 },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: { y: "100vh", opacity: 0 },
};
const UpdateFieldModal = ({ props }) => {
  const dispatch = useDispatch();

  const [field, setField] = useState({
    fieldName: props.fieldController.currentField.fieldName,
    fieldDescription: props.fieldController.currentField.fieldDescription,
    required: props.fieldController.currentField.required,
    fieldType: props.fieldController.currentField.fieldType,
    internalFacing: props.fieldController.currentField.internalFacing,
    applicationOrder: props.fieldController.currentField.applicationOrder,
    id: props.fieldController.currentField.id,
    checkOther: props.fieldController.currentField.checkOther,
  });

  const [lookupCharacteristic, setLookupCharacteristic] = useState(null);

  const [dropdownInput, setDropdownInput] = useState({
    1: "",
    2: "",
    3: "",
    4: "",
    5: "",
    6: "",
    7: "",
    8: "",
    9: "",
    10: "",
  });
  const [dropdownValues, setDropdownValues] = useState(null);
  const [limit, setLimit] = useState(null);
  const [lookupOptions, setLookupOptions] = useState([]);

  const addLookupOptionRow = () => {
    const temp = lookupOptions;
    temp.push({});
    setLookupOptions([...temp]);
  };
  const removeLookupOptionRow = (index) => {
    const temp = lookupOptions;
    if (temp[index]["id"]) {
      dispatch(deleteLookupOption({ id: temp[index]["id"] }));
    }
    temp.splice(index, 1);
    setLookupOptions([...temp]);
  };

  const updateLookupFunction = (index, key, val) => {
    const temp = lookupOptions;
    temp[index][key] = val;
    setLookupOptions([...temp]);
  };

  useEffect(() => {
    if (dropdownValues) {
      const dropLimit = 10 - dropdownValues.length;
      setLimit(dropLimit);
    } else {
      setLimit(10);
    }
  }, [dropdownValues]);

  const [incrementVal, setIncrementVal] = useState(0);

  const incrementAdd = () => {
    if (incrementVal < limit) {
      setIncrementVal(incrementVal + 1);
    } else {
      const toastPayload = {
        index: 1,
        description: 0,
        type: "dropdown",
      };
      setToast(toastPayload);
    }
  };

  const removeAdd = () => {
    if (incrementVal > 0) {
      setIncrementVal(incrementVal - 1);
    }
  };

  const getFieldValues = async () => {
    try {
      if (field.fieldType === "DROPDOWN" || field.fieldType === "CHECK") {
        const fieldType = field.fieldType === "DROPDOWN" ? "dropdown" : "check";
        const url =
          process.env.REACT_APP_PRODUCTION === "true"
            ? `https://expressproxy-prod.azurewebsites.net/${fieldType}/value/fetch-by-id`
            : `http://localhost:3000/${fieldType}/value/fetch-by-id`;

        let res = await axios.post(
          url,
          { id: props.fieldController.currentField.id },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        let values = res.data.data;
        if (values.length > 0) {
          values.sort((a, b) => {
            return a.id - b.id;
          });
          setDropdownValues(null);
          setDropdownValues(values);
        }
      } else if (field.fieldType === "LOOKUP") {
        let url =
          process.env.REACT_APP_PRODUCTION === "true"
            ? `https://expressproxy-prod.azurewebsites.net/lookup-characteristic/fetch-by-id`
            : `http://localhost:3000/lookup-characteristic/fetch-by-id`;

        let res = await axios.post(
          url,
          { id: props.fieldController.currentField.id },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        let values = res.data.data;
        setLookupCharacteristic(values);

        url =
          process.env.REACT_APP_PRODUCTION === "true"
            ? `https://expressproxy-prod.azurewebsites.net/lookup-option/fetch-by-id`
            : `http://localhost:3000/lookup-option/fetch-by-id`;

        res = await axios.post(
          url,
          { id: props.fieldController.currentField.id },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        const fieldLookupOptions = res.data.data;
        if (fieldLookupOptions.length > 0) {
          fieldLookupOptions.sort((a, b) => {
            return a.id - b.id;
          });
          console.log("Field Options: ", fieldLookupOptions);
          setLookupOptions([...fieldLookupOptions]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getFieldValues();
  }, [props]);

  const deleteDropdownValue = async (id) => {
    try {
      const fieldType = field.fieldType === "DROPDOWN" ? "dropdown" : "check";

      const url =
        process.env.REACT_APP_PRODUCTION === "true"
          ? `https://expressproxy-prod.azurewebsites.net/${fieldType}/value/delete`
          : `http://localhost:3000/${fieldType}/value/delete`;

      const res = await axios.post(
        url,
        { id: id },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      getFieldValues();
    } catch (err) {
      console.log(err);
    }
  };

  const updateDropdown = async (obj) => {
    try {
      const fieldType = field.fieldType === "DROPDOWN" ? "dropdown" : "check";

      const url =
        process.env.REACT_APP_PRODUCTION === "true"
          ? `https://expressproxy-prod.azurewebsites.net/${fieldType}/value/update`
          : `http://localhost:3000/${fieldType}/value/update`;

      await axios.post(
        url,
        { id: obj.id, value: obj.value },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const submit = async () => {
    try {
      await dispatch(updateField(field));
      if (field.fieldType === "DROPDOWN" || field.fieldType === "CHECK") {
        const arrayConvert = Object.values(dropdownInput);
        const temp = arrayConvert.filter((el) => {
          return el !== "";
        });
        if (temp.length > 0) {
          for (var i = 0; i < temp.length; i++) {
            const reduxPayload = {
              value: temp[i],
              fieldId: props.fieldController.currentField.id,
            };
            field.fieldType === "DROPDOWN"
              ? await dispatch(addDropdownValue(reduxPayload))
              : await dispatch(addCheckValue(reduxPayload));
          }
        }
      } else if (field.fieldType === "LOOKUP") {
        const updateLc = {
          id: lookupCharacteristic["id"],
          name1Label: lookupCharacteristic["name1Label"],
          name2Label: lookupCharacteristic["name2Label"],
          useMultiplier: lookupCharacteristic["useMultiplier"],
          multiplierLabel: lookupCharacteristic["multiplierLabel"],
          inputLabel: lookupCharacteristic["inputLabel"],
          searchDescription: lookupCharacteristic["searchDescription"],
          addDescription: lookupCharacteristic["addDescription"],
        };
        await dispatch(updateLookupCharacteristic(updateLc));

        const addLookup = lookupOptions.filter((lo) => {
          return !lo["id"];
        });
        const updateLookup = lookupOptions.filter((lo) => {
          return lo["id"];
        });

        addLookup.forEach(async (lo) => {
          const addLo = {
            fieldId: props.fieldController.currentField.id,
            lookupName1: lo["lookupName1"],
            lookupName2: lo["lookupName2"],
            multiplier: lo["multiplier"],
          };
          await dispatch(createLookupOption(addLo));
        });

        updateLookup.forEach(async (lo) => {
          const udpateLo = {
            id: lo["id"],
            lookupName1: lo["lookupName1"],
            lookupName2: lo["lookupName2"],
            multiplier: lo["multiplier"],
          };
          await dispatch(updateLookupOption(udpateLo));
        });
      }
      await dispatch(fetchFields());

      props.fieldController.closeUpdateModal();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Backdrop
      onClick={() => {
        props.fieldController.closeUpdateModal();
      }}
    >
      <motion.div
        className=" rounded w-[80rem] bg-white dark:bg-wc-gradient bg-cover text-wc-green dark:text-white shadow-md font-sans max-h-[60rem] overflow-auto"
        onClick={(e) => e.stopPropagation()}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
        transition={{ duration: 0.5 }}
      >
        <div className="w-full flex flex-col gap-2 line-clamp-7 min-w-12 p-8">
          <div className=" rounded w-full flex gap-2 items-center justify-start border-b mb-8">
            <h2 className="flex gap-2 text-blue-reef w-full font-medium text-lg">
              Edit Field
            </h2>
          </div>

          <div className="flex flex-col gap-2">
            <div className="flex flex-col">
              <span className=" text-blue-reef">Field Name</span>
              <input
                type="text"
                className="password pl-2 text-black rounded  border-2 border-gray-300 h-[2.5rem]"
                placeholder=" Name"
                defaultValue={field.fieldName}
                name="fieldName"
                onChange={(e) => {
                  setField((prev) => ({
                    ...prev,
                    fieldName: e.target.value,
                  }));
                }}
              />
            </div>
            <div className="flex flex-col mb-4">
              <span className=" text-blue-reef">Field Description</span>
              <textarea
                type="text"
                className="password text-black rounded border-2 border-gray-300 h-[8rem] p-2"
                placeholder=" Description"
                defaultValue={field.fieldDescription.replace(
                  /<br\s*\/?>/gi,
                  "\n"
                )}
                name="fieldName"
                onChange={(e) => {
                  setField((prev) => ({
                    ...prev,
                    fieldDescription: e.target.value,
                  }));
                }}
              />
            </div>
            <div className="flex flex-col mb-4">
              <span className=" text-blue-reef">Application Order</span>
              <input
                className="w-[4rem] pl-2 border-2 border-gray-300 h-[2.5rem] rounded"
                type="text"
                defaultValue={field.applicationOrder}
                onChange={(e) => {
                  setField((prev) => ({
                    ...prev,
                    applicationOrder: e.target.value,
                  }));
                }}
              />
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex gap-2 items-center">
                <input
                  className="h-4 w-4"
                  type="checkbox"
                  checked={field.required}
                  onChange={(e) => {
                    setField((prev) => ({
                      ...prev,
                      required: e.target.checked,
                    }));
                  }}
                />
                <label>Required Field ?</label>
              </div>
              <div className="flex gap-2 items-center">
                <input
                  className="h-4 w-4"
                  type="checkbox"
                  checked={field.internalFacing}
                  onChange={(e) => {
                    setField((prev) => ({
                      ...prev,
                      internalFacing: e.target.checked,
                    }));
                  }}
                />
                <label>Internal-facing? (hide from customer) ?</label>
              </div>
            </div>
            <div className="flex flex-col">
              <label htmlFor="">Field Type</label>
              <div className="flex flex-col gap-2">
                <select
                  name="role"
                  id="role"
                  className=" text-black rounded border-2 border-gray-300 h-[2.5rem] p-2"
                  defaultValue={field.fieldType}
                  onChange={(e) => {
                    setField((prev) => ({
                      ...prev,
                      fieldType: e.target.value,
                    }));
                  }}
                >
                  <option defaultValue>Select Field Type</option>
                  <option value="BOOLEAN">Bool</option>
                  <option value="CHECK">Check all that apply</option>
                  <option value="DATE">Date</option>
                  <option value="DROPDOWN">Dropdown</option>
                  <option value="LOOKUP">Lookup</option>
                  <option value="NUMBER">Number</option>
                  <option value="STRING">String</option>
                  <option value="SECTION">Section Header</option>
                </select>
                <div className="flex flex-col gap-2">
                  {dropdownValues &&
                    dropdownValues?.map((value) => {
                      return (
                        <DropdownInput
                          props={{
                            value: value,
                            updateDropdown: updateDropdown,
                            deleteDropdownValue: deleteDropdownValue,
                            getFieldValues: getFieldValues,
                          }}
                        />
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-2 rounded ">
              {incrementVal >= 1 && (
                <input
                  className="border-2 border-gray-300 rounded w-11/12 text-lg"
                  onChange={(e) => {
                    setDropdownInput((prev) => ({
                      ...prev,
                      1: e.target.value,
                    }));
                  }}
                />
              )}
              {incrementVal >= 2 && (
                <input
                  className="border-2 border-gray-300 rounded w-11/12 text-lg"
                  onChange={(e) => {
                    setDropdownInput((prev) => ({
                      ...prev,
                      2: e.target.value,
                    }));
                  }}
                />
              )}
              {incrementVal >= 3 && (
                <input
                  className="border-2 border-gray-300 rounded w-11/12 text-lg"
                  onChange={(e) => {
                    setDropdownInput((prev) => ({
                      ...prev,
                      3: e.target.value,
                    }));
                  }}
                />
              )}
              {incrementVal >= 4 && (
                <input
                  className="border-2 border-gray-300 rounded w-11/12 text-lg"
                  onChange={(e) => {
                    setDropdownInput((prev) => ({
                      ...prev,
                      4: e.target.value,
                    }));
                  }}
                />
              )}
              {incrementVal >= 5 && (
                <input
                  className="border-2  border-gray-300 rounded w-11/12 text-lg"
                  onChange={(e) => {
                    setDropdownInput((prev) => ({
                      ...prev,
                      5: e.target.value,
                    }));
                  }}
                />
              )}
              {incrementVal >= 6 && (
                <input
                  className="border-2 border-gray-300 rounded w-11/12 text-lg"
                  onChange={(e) => {
                    setDropdownInput((prev) => ({
                      ...prev,
                      6: e.target.value,
                    }));
                  }}
                />
              )}
              {incrementVal >= 7 && (
                <input
                  className="border-2 border-gray-300 rounded w-11/12 text-lg"
                  onChange={(e) => {
                    setDropdownInput((prev) => ({
                      ...prev,
                      7: e.target.value,
                    }));
                  }}
                />
              )}
              {incrementVal >= 8 && (
                <input
                  className="border-2 border-gray-300 rounded w-11/12 text-lg"
                  onChange={(e) => {
                    setDropdownInput((prev) => ({
                      ...prev,
                      8: e.target.value,
                    }));
                  }}
                />
              )}
              {incrementVal >= 9 && (
                <input
                  className="border-2 border-gray-300 rounded w-11/12 text-lg"
                  onChange={(e) => {
                    setDropdownInput((prev) => ({
                      ...prev,
                      9: e.target.value,
                    }));
                  }}
                />
              )}
              {incrementVal >= 10 && (
                <input
                  className="border-2 border-gray-300 rounded w-11/12 text-lg"
                  onChange={(e) => {
                    setDropdownInput((prev) => ({
                      ...prev,
                      10: e.target.value,
                    }));
                  }}
                />
              )}
            </div>

            {field["checkOther"] !== null && (
              <div className="flex gap-2 items-center mt-2">
                <input
                  type="checkbox"
                  className="w-4 h-4"
                  checked={field["checkOther"]}
                  onChange={(e) => {
                    setField((prev) => ({
                      ...prev,
                      checkOther: !field["checkOther"],
                    }));
                  }}
                />
                <span>Include "Other" option for customer</span>
              </div>
            )}

            {field.fieldType === "LOOKUP" && lookupCharacteristic && (
              <div className="flex flex-col gap-4 items-start">
                <input
                  type="text"
                  placeholder="Label for First Reference"
                  className="text-black rounded border-2 border-gray-300 w-11/12 h-[2.5rem] pl-2"
                  defaultValue={lookupCharacteristic["name1Label"]}
                  onChange={(e) => {
                    setLookupCharacteristic((prev) => ({
                      ...prev,
                      name1Label: e.target.value,
                    }));
                  }}
                />
                <input
                  type="text"
                  placeholder="Label for Second Reference"
                  className="text-black rounded border-2 border-gray-300 w-11/12 h-[2.5rem] pl-2"
                  defaultValue={lookupCharacteristic["name2Label"]}
                  onChange={(e) => {
                    setLookupCharacteristic((prev) => ({
                      ...prev,
                      name2Label: e.target.value,
                    }));
                  }}
                />
                <input
                  type="text"
                  placeholder="Label for Customer Input"
                  className="text-black rounded border-2 border-gray-300 w-11/12 h-[2.5rem] pl-2"
                  defaultValue={lookupCharacteristic["inputLabel"]}
                  onChange={(e) => {
                    setLookupCharacteristic((prev) => ({
                      ...prev,
                      inputLabel: e.target.value,
                    }));
                  }}
                />
                <div className="flex gap-2">
                  <input
                    type="checkbox"
                    className="w-4"
                    defaultChecked={lookupCharacteristic["useMultiplier"]}
                    onClick={(e) => {
                      setLookupCharacteristic((prev) => ({
                        ...prev,
                        useMultiplier: e.target.checked,
                      }));
                    }}
                  />
                  <label>Use multiplier</label>
                </div>
                {lookupCharacteristic["useMultiplier"] && (
                  <input
                    type="text"
                    placeholder="Label for Multiplier"
                    className="text-black rounded border-2 border-gray-300 w-11/12 h-[2.5rem]"
                    defaultValue={lookupCharacteristic["multiplierLabel"]}
                    onChange={(e) => {
                      setLookupCharacteristic((prev) => ({
                        ...prev,
                        multiplierLabel: e.target.value,
                      }));
                    }}
                  />
                )}
                <div className="flex flex-col w-full">
                  <label>Search Description</label>
                  <span>
                    Enter the instructions for using the search that should be
                    displayed to customers.
                  </span>
                  <input
                    type="text"
                    className="text-black rounded border-2 border-gray-300 w-11/12 h-[2.5rem] pl-2"
                    defaultValue={lookupCharacteristic["searchDescription"]}
                    onChange={(e) => {
                      setLookupCharacteristic((prev) => ({
                        ...prev,
                        searchDescription: e.target.value,
                      }));
                    }}
                  />
                </div>
                <div className="flex flex-col w-full">
                  <label>Add Additional Description</label>
                  <span>
                    Enter the instructions displayed to customers for how to use
                    the "add additional" items not in the search list. If left
                    blank, this section will not be displayed.
                  </span>
                  <input
                    type="text"
                    className="text-black rounded border-2 border-gray-300 w-11/12 h-[2.5rem] pl-2"
                    defaultValue={lookupCharacteristic["addDescription"]}
                    onChange={(e) => {
                      setLookupCharacteristic((prev) => ({
                        ...prev,
                        addDescription: e.target.value,
                      }));
                    }}
                  />
                </div>
                <div className="w-full flex flex-col">
                  <span>Lookup Values</span>
                  <table className="table-auto w-full text-left mt-4 shadow">
                    <thead>
                      <tr className="border-b border-gray-100 bg-blue-400 text-gray-50 ">
                        <th className="p-3 text-sm tracking-wide text-start whitespace-nowrap ">
                          Reference Column 1
                        </th>
                        <th className="p-3 text-sm tracking-wide text-start whitespace-nowrap ">
                          Reference Column 2
                        </th>
                        {lookupCharacteristic["useMultiplier"] && (
                          <th className="p-3 text-sm tracking-wide text-start whitespace-nowrap ">
                            Multiplier
                          </th>
                        )}
                        <th className="p-3 text-sm tracking-wide text-start whitespace-nowrap ">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {lookupOptions?.map((lo, index) => {
                        return (
                          <tr className="border-b border-gray-100  text-gray-900">
                            <td className="p-2 text-sm tracking-wide text-start whitespace-nowrap ">
                              <input
                                value={lo["lookupName1"]}
                                className="text-black rounded border-2 border-gray-300 w-full h-[2.5rem] pl-2"
                                onChange={(e) => {
                                  updateLookupFunction(
                                    index,
                                    "lookupName1",
                                    e.target.value
                                  );
                                }}
                              />
                            </td>
                            <td className="p-2 text-sm tracking-wide text-start whitespace-nowrap ">
                              <input
                                value={lo["lookupName2"]}
                                className="text-black rounded border-2 border-gray-300 w-full h-[2.5rem] pl-2"
                                onChange={(e) => {
                                  updateLookupFunction(
                                    index,
                                    "lookupName2",
                                    e.target.value
                                  );
                                }}
                              />
                            </td>
                            {lookupCharacteristic["useMultiplier"] && (
                              <td className="p-2 text-sm tracking-wide text-start whitespace-nowrap ">
                                <input
                                  value={lo["multiplier"]}
                                  className="text-black rounded border-2 border-gray-300 w-full h-[2.5rem] pl-2"
                                  onChange={(e) => {
                                    updateLookupFunction(
                                      index,
                                      "multiplier",
                                      e.target.value
                                    );
                                  }}
                                />
                              </td>
                            )}
                            <td className="p-3 text-sm tracking-wide text-start whitespace-nowrap ">
                              <MdDelete
                                className="text-red-500 cursor-pointer"
                                size="24"
                                onClick={(e) => removeLookupOptionRow(index)}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <Button
                    variant="outline"
                    className="rounded bg-blue-500 text-white mr-auto text-sm p-4 mt-4"
                    onClick={(e) => {
                      addLookupOptionRow();
                    }}
                  >
                    Add Lookup Row
                  </Button>
                </div>
              </div>
            )}

            <div className="flex gap-2">
              {/* disabled by request of CH */}
              {incrementVal < limit && field.fieldType === "DROPDOWN" && (
                <button
                  className="text-white w-4/12 bg-blue-reef hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm px-5 py-2 mr-2 dark:bg-blue-400 dark:hover:bg-blue-reef focus:outline-none dark:focus:ring-blue-500"
                  onClick={() => {
                    incrementAdd();
                  }}
                >
                  Add Another Field
                </button>
              )}

              {incrementVal < limit && field.fieldType === "CHECK" && (
                <button
                  className="text-white w-4/12 bg-blue-reef hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm px-5 py-2 mr-2 dark:bg-blue-400 dark:hover:bg-blue-reef focus:outline-none dark:focus:ring-blue-500"
                  onClick={() => {
                    incrementAdd();
                  }}
                >
                  Add Another Field
                </button>
              )}

              {incrementVal !== 0 && (
                <button
                  className="bg-red-400 rounded text-white w-4/12 p-2"
                  onClick={() => {
                    removeAdd();
                  }}
                >
                  Remove
                </button>
              )}
            </div>

            <Button
              variant="outline"
              className="rounded bg-green-reef text-white text-sm p-4 ml-auto"
              onClick={(e) => {
                submit(e);
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </motion.div>
    </Backdrop>
  );
};

export default UpdateFieldModal;
