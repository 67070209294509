import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar, Doughnut, Pie, Line } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const LineChart = ({ props }) => {
  console.log("LineChart: ", props);
  // console.log(props.chartData);
  const [chartData, setChartData] = useState({
    datasets: [],
  });

  const tmpData = {
    programs: [],
  };

  // console.log("PROPS: ", props);

  function subtractMonths(date, months) {
    var day = date.getDate();
    date.setMonth(date.getMonth() - months);
    if (date.getDate() != day) date.setDate(0);
    return date;
  }

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const labels = [0, 1, 2, 3, 4, 5]
    .map((x) => {
      function getMonthName(monthNumber) {
        // const date = new Date();
        // date.setMonth(monthNumber);

        // Using the browser's default locale.
        // console.log("Mpmt: ", date.toLocaleString([], { month: "long" }));
        // return date.toLocaleString([], { month: "long" });
        return monthNames[monthNumber];
      }

      let tmpDate = new Date();
      // console.log(
      //   "tempDate1: ",
      //   getMonthName(
      //     new Date(tmpDate.setMonth(tmpDate.getMonth() - x)).getMonth()
      //   )
      // );
      // console.log(
      //   "tempDate: ",
      //   getMonthName(
      //     new Date(tmpDate.setMonth(tmpDate.getMonth() - x)).getMonth()
      //   )
      // );
      // let tmpDate2 = new Date(tmpDate.setMonth(tmpDate.getMonth() - x));
      let tmpDate2 = subtractMonths(tmpDate, x);

      return getMonthName(tmpDate2.getMonth());
    })
    .reverse(); // quick change done to flip x axis

  // safety check for colors
  const getRandomRGB = () => {
    const randomBetween = (min, max) =>
      min + Math.floor(Math.random() * (max - min + 1));
    const r = randomBetween(0, 255);
    const g = randomBetween(0, 255);
    const b = randomBetween(0, 255);
    const rgb = `rgb(${r},${g},${b})`;
    return rgb;
  };

  const [chartOptions, setChartOptions] = useState({});

  let tmpChartData = [
    {
      label: "Program 1",
      data: labels.map(() => Math.floor(Math.random() * 100)),
      backgroundColor: "rgb(72 123 170)",
    },
    {
      label: "Program 2",
      data: labels.map(() => Math.floor(Math.random() * 100)),
      backgroundColor: "rgb(188 99 40)",
    },
    {
      label: "Program 3",
      data: labels.map(() => Math.floor(Math.random() * 100)),
      backgroundColor: "rgb(138 138 138)",
    },
    {
      label: "Program 4",
      data: labels.map(() => Math.floor(Math.random() * 100)),
      backgroundColor: "rgb(211 159 7)",
    },
    {
      label: "Program 5",
      data: labels.map(() => Math.floor(Math.random() * 100)),
      backgroundColor: "rgb(60 99 170)",
    },
  ];

  // hack to make sure we have props
  //props = props ? props : {};

  props.datasets = props?.datasets ? props?.datasets : tmpChartData;

  const quickColors = props?.data?.names?.map((x) => getRandomRGB());

  const [appData, setAppData] = useState(null);

  // useEffect(() => {
  //   console.log("PROPS-Line-chart", props.data);
  //   if (props.data) {
  //     console.log("PROPS-Line-chart2", props.data);

  //     const temp = props?.data?.programIds?.map((x, i) => {
  //       console.log("app-data: ", props.data.hits);
  //       return {
  //         label: props.data?.names[i],
  //         data: props.data?.hits
  //           .map((y) => y.filter((z) => z == x).length)
  //           .reverse(), // quick change done to flip x axis
  //         backgroundColor: quickColors[i],
  //       };
  //     });

  //     setAppData(temp);
  //   }
  // }, [props.data]);

  useEffect(() => {
    console.log("PROPS-Line-chart", props.data);
    if (props.data) {
      console.log("PROPS-Line-chart2", props.data);

      const temp = props?.data?.programIds?.map((x, i) => {
        console.log("app-data: ", props.data.hits);
        return {
          label: props.data?.names[i],
          data: props.data?.hits
            .map((y) => (Array.isArray(y) ? y.filter((z) => z == x).length : 0))
            .reverse(), // quick change done to flip x axis
          backgroundColor: quickColors[i],
        };
      });
      console.log("Appdata: temp, ", temp);
      setAppData(temp);
    }
  }, [props.data]);

  // const appData = props?.data?.programIds?.map((x, i) => {
  //   console.log("app-data: ", props.data);
  //   return {
  //     label: props.data?.names[i],
  //     data: props.data?.hits
  //       .map((y) => y?.filter((z) => z == x).length)
  //       .reverse(), // quick change done to flip x axis
  //     backgroundColor: quickColors[i],
  //   };
  // });

  useEffect(() => {
    console.log("chart-data1`23: ", chartData);
  }, [chartData]);
  useEffect(() => {
    console.log("set-chart-data: ", appData);
    setChartData({
      labels: labels,
      datasets: appData ? appData : [],
    });

    setChartOptions({
      plugins: {
        title: {
          display: false,
        },
        legend: {
          position: "right",
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true,
        },
      },
    });
  }, [appData]);

  return (
    <div className="sm:w-full lg:w-6/12 shadow rounded">
      <div className="w-full top-0 bg-blue-reef p-3 rounded-t flex justify-center items-center text-lg">
        <h2 className="text-white tracking-wide  pl-1 pr-1">
          {props?.title
            ? props?.title
            : "Applications Received by Month by Program (Last 6 Months)"}
        </h2>
      </div>
      <div className="w-10/12 relativ bg-white dark:bg-gray-500 ">
        {chartData && chartOptions ? (
          <Bar options={chartOptions} data={chartData} className="" />
        ) : (
          <span>loading chart...</span>
        )}
      </div>
    </div>
  );
};

export default LineChart;
