import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Backdrop from "../Misc/Backdrop";
import WaterSavingsTable from "./WaterSavingsTable";

const axios = require("axios").default;

axios.defaults.headers.common["Client"] = "ACWD";

const dropIn = {
  hidden: { y: "-100vh", opacity: 0 },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: { y: "100vh", opacity: 0 },
};
const ImportReport = ({ props }) => {
  // const dispatch = useDispatch();

  return (
    <Backdrop
      onClick={() => {
        // props.waterSavingsController.closeWaterSavingsModal();
        // getWaterSavings();
        props.setTriggerRefresh(true);
        props.closeImportModal();
      }}
    >
      <motion.div
        className=" rounded-md w-[50rem] max-h-[50rem] bg-white dark:bg-wc-gradient bg-cover text-wc-green dark:text-white shadow-md font-sans overflow-auto"
        onClick={(e) => e.stopPropagation()}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
        transition={{ duration: 0.5 }}
      >
        <div className="w-full p-8 flex flex-col gap-4 line-clamp-7 min-w-12">
          <div className=" rounded w-full flex gap-2 items-center justify-start ">
            <h2 className="text-lg font-bold text-center flex gap-2 text-blue-reef">
              Import Report
            </h2>
          </div>

          {props.report.map((el) => {
            return (
              <div>
                <span>{el}</span>
              </div>
            );
          })}
          {/* {waterSavings && (
            <WaterSavings props={{ waterSavings: waterSavings }} />
          )} */}
          {/* {budgets && (
            <ProgramBudgetTable
              props={{ programBudgets: budgets, getBudgets: getBudgets }}
            />
          )} */}
          {/* {waterSavings && (
            <WaterSavingsTable props={{ waterSavings: waterSavings }} />
          )} */}
        </div>
      </motion.div>
    </Backdrop>
  );
};

export default ImportReport;
