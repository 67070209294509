import React, { useState, useEffect, lazy, Suspense } from "react";
import { motion } from "framer-motion";
import { selectAllProperties } from "../../features/properties";
import HashLoader from "react-spinners/HashLoader";
import { AnimatePresence } from "framer-motion";
// import ApplicationButton from "./ApplicationButton";
import CloseWarningModal from "../Misc/CloseWarningModal";
import ApplicationForm from "./ApplicationForm";
import { useSelector } from "react-redux";

const axios = require("axios").default;

axios.defaults.headers.common["Client"] = "ACWD";

const StartApplication = ({ props }) => {
  const properties = useSelector(selectAllProperties);
  const [applicationModal, setApplicationModal] = useState(false);
  const [modalFieldsInfo, setModalFieldsInfo] = useState(null);
  const [modalUploadsInfo, setModalUploadsInfo] = useState(null);
  const [programInfo, setProgramInfo] = useState(null);
  const [displayPrograms, setDisplayPrograms] = useState(null);
  const ApplicationButton = lazy(() => import("./ApplicationButton"));

  useEffect(() => {
    const validPrograms = props.programs.filter((program) => {
      const startDate = new Date(program.programStart);
      const endDate = new Date(program.programEnd);
      const currentDate = new Date();
      return currentDate >= startDate && currentDate <= endDate;
    });
    setDisplayPrograms(validPrograms);

    const user_id = parseInt(localStorage.getItem("user_id"));
    if (properties) {
      const customerProperties = properties.filter((property) => {
        return (
          property.customerProperty?.filter((el) => {
            return el.customerId === user_id;
          }).length > 0
        );
      });

      let customerPropertyType = {
        singleHomeFamily: false,
        multiFamilyComplex: false,
        commercial: false,
        landscape: false,
        residential: false,
      };

      customerProperties.forEach((cp) => {
        const temp = cp.customerProperty.filter((x) => {
          return x.customerId === user_id;
        })[0];
        if (
          temp["singleHomeFamily"] === true &&
          customerPropertyType["singleHomeFamily"] === false
        ) {
          customerPropertyType["singleHomeFamily"] = true;
        }
        if (
          temp["multiFamilyComplex"] === true &&
          customerPropertyType["multiFamilyComplex"] === false
        ) {
          customerPropertyType["multiFamilyComplex"] = true;
        }
        if (
          temp["commercial"] === true &&
          customerPropertyType["commercial"] === false
        ) {
          customerPropertyType["commercial"] = true;
        }
        if (
          temp["landscape"] === true &&
          customerPropertyType["landscape"] === false
        ) {
          customerPropertyType["landscape"] = true;
        }
        if (
          temp["residential"] === true &&
          customerPropertyType["residential"] === false
        ) {
          customerPropertyType["residential"] = true;
        }
      });

      let finalPrograms = new Set();

      validPrograms.forEach((vp) => {
        if (
          vp["singleFamilyHome"] === true &&
          customerPropertyType["singleHomeFamily"] === true
        ) {
          finalPrograms.add(vp);
          finalPrograms.add(vp);
        }
        if (
          vp["multiFamilyComplex"] === true &&
          customerPropertyType["multiFamilyComplex"] === true
        ) {
          finalPrograms.add(vp);
          finalPrograms.add(vp);
        }
        if (
          vp["commercial"] === true &&
          customerPropertyType["commercial"] === true
        ) {
          finalPrograms.add(vp);
          finalPrograms.add(vp);
        }
        if (
          vp["landscape"] === true &&
          customerPropertyType["landscape"] === true
        ) {
          finalPrograms.add(vp);
          finalPrograms.add(vp);
        }
        if (
          vp["residential"] === true &&
          customerPropertyType["residential"] === true
        ) {
          finalPrograms.add(vp);
          finalPrograms.add(vp);
        }
      });

      finalPrograms = Array.from(finalPrograms);

      if (user_id !== 123) {
        setDisplayPrograms(finalPrograms);
        // getDisplayPictures(finalPrograms);
      }

      const customerPropertyArr = customerProperties.map((property) => {
        return property.customerProperty.filter((cp) => {
          return cp.customerId === user_id;
        });
      });

      const propertyTypeCheck = {
        commercial: false,
        landscape: false,
        multiFamilyComplex: false,
        residential: false,
        singleHomeFamily: false,
      };
      for (let i = 0; i < customerPropertyArr.length; i++) {
        if (customerPropertyArr[i].length > 0) {
          for (let j = 0; j < customerPropertyArr[i]?.length; i++) {
            if (customerPropertyArr[i][j]?.commercial === true) {
              propertyTypeCheck["commercial"] = true;
            }
            if (customerPropertyArr[i][j]?.landscape === true) {
              propertyTypeCheck["landscape"] = true;
            }
            if (customerPropertyArr[i][j]?.multiFamilyComplex === true) {
              propertyTypeCheck["multiFamilyComplex"] = true;
            }
            if (customerPropertyArr[i][j]?.residential === true) {
              propertyTypeCheck["residential"] = true;
            }
            if (customerPropertyArr[i][j]?.singleHomeFamily === true) {
              propertyTypeCheck["singleHomeFamily"] = true;
            }
          }
        }
      }

      let associatedProgram = [];
      for (const [key, value] of Object.entries(propertyTypeCheck)) {
        if (value) {
          const filterPrograms = props.programs.filter((el) => {
            return el[key] === value;
          });

          associatedProgram = [...filterPrograms];
        }
      }
    }
  }, [props.programs, properties]);

  const openApplicationModal = (program) => {
    const uploads = props.uploads?.filter((upload) => {
      return upload?.programId === program.id;
    });
    const fields = props.fields?.filter((field) => {
      return field?.programId === program.id;
    });

    setProgramInfo(program);
    setModalUploadsInfo(uploads);
    setModalFieldsInfo(fields);
    setApplicationModal(true);
  };

  const [closeWarningModal, setCloseWarningModal] = useState(false);
  const openCloseWarningModal = () => {
    setCloseWarningModal(true);
  };
  const closeCloseWarningModal = () => {
    setCloseWarningModal(false);
  };
  const closeApplicationModal = () => {
    setApplicationModal(false);
  };
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className={`application-table transition h-full bg-white font-sans duration-200 dark:bg-gray-900 dark:text-gray-50`}
    >
      <AnimatePresence exitBeforeEnter initial={false}>
        {modalFieldsInfo &&
          modalUploadsInfo &&
          applicationModal &&
          props.properties && (
            <ApplicationForm
              props={{
                handleClose: closeApplicationModal,
                fields: modalFieldsInfo,
                uploads: modalUploadsInfo,
                properties: props.properties,
                program: programInfo,
                openWarningModal: openCloseWarningModal,
              }}
            />
          )}

        {closeWarningModal && (
          <CloseWarningModal
            props={{
              closeWarningModal: closeCloseWarningModal,
              closeAppModal: closeApplicationModal,
            }}
          />
        )}
      </AnimatePresence>

      <div className="w-full ">
        <div className="flex w-full flex-col items-center justify-center gap-8 ">
          <div className="w-full rounded-md">
            <div className="application-container transition h-full p-6 font-sans duration-200 dark:bg-gray-900 dark:text-gray-50 mx-auto max-w-[2000px] rounded-lg">
              {localStorage.getItem("user_id") != 123 && (
                <h2 className="mb-4 w-full p-2 font-sans text-lg font-sans text-gray-900">
                  START APPLICATIONS
                </h2>
              )}
              {displayPrograms && props.uploads && props.fields ? (
                <div className="grid w-full grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 transition">
                  {displayPrograms.map((program, index) => {
                    return (
                      <Suspense key={index} fallback={<div>Loading...</div>}>
                        <ApplicationButton
                          props={{
                            program: program,
                            index: index,
                            openApplicationModal: openApplicationModal,
                          }}
                          key={index}
                        />
                      </Suspense>
                    );
                  })}
                </div>
              ) : (
                <div className="flex w-full items-center justify-center gap-2 ">
                  <span>Loading Application List</span>
                  <HashLoader size={40} color={"#A3AEB9"} />
                </div>
              )}
              {!props.programs && (
                <>
                  <div className="mx-2 my-1 flex w-full flex-col rounded-md bg-gray-50 p-4 tracking-wider shadow-md hover:bg-blue-50 dark:bg-wc-grey">
                    <span>
                      No applications are available. This may be because:
                    </span>
                    <div className="ml-4 mt-4 flex flex-col gap-4">
                      <span className="flex flex-row">
                        You must first register your property to your account in
                        "My Profile" to start an application. If your property
                        is not showing in the dropdown, it may not meet the
                        program eligibility property type or the account may be
                        inactive.
                      </span>
                      <span className="flex flex-row">
                        Your account associated with a previously registered
                        property may no longer be active (you can check in the
                        “My Profile” section).
                      </span>
                      <span className="flex flex-row">
                        Your registered property(ies) may not meet the property
                        types eligible for currently open conservation program
                        applications.
                      </span>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default StartApplication;
