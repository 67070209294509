import React from "react";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setToast } from "../utils/ToastNotification";
const axios = require("axios").default;

axios.defaults.headers.common["Client"] = "ACWD";

const prod_base_url = "https://expressproxy-prod.azurewebsites.net/program";
const local_base_url = "http://localhost:3000/program";

const active_base_url =
  process.env.REACT_APP_PRODUCTION === "true" ? prod_base_url : local_base_url;

const initStateValue = {
  menuSelect: [true, false, false],
  programs: [],
  status: "idle",
  error: null,
};

export const fetchPrograms = createAsyncThunk(
  "program/fetchPrograms",
  async () => {
    try {
      const res = await axios.post(
        `${active_base_url}/fetch-all`,
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      const programs = res.data.data;
      console.log("programs: ", programs);
      return programs;
    } catch (err) {
      console.log(err);
    }
  }
);
export const fetchProgramsPublic = createAsyncThunk(
  "program/fetchPrograms",
  async () => {
    try {
      console.log("fetchPrograms from program feature");
      console.log("activebaseurl: ", active_base_url);
      const res = await axios.post(
        `${active_base_url}/public/fetch-all`,
        {},
        {}
      );

      const programs = res.data.data;
      return programs;
    } catch (err) {
      console.log(err);
    }
  }
);

export const addProgram = createAsyncThunk(
  "program/addProgram",
  async (program) => {
    try {
      console.log("progra: ", active_base_url);
      console.log("payload: ", program);
      const res = await axios.post(`${active_base_url}/add`, program, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });

      console.log("add-program-res: ", res);

      const addProgram = res.data.data;

      console.log("addProgram", addProgram);
      if (addProgram) {
        const toastPayload = {
          index: 0,
          description: 0,
          type: "program",
        };
        setToast(toastPayload);
      }
      return addProgram;
    } catch (err) {
      console.log("error from addProgram", err);

      const toastPayload = {
        index: 1,
        description: 1,
        type: "program",
      };
      setToast(toastPayload);
    }
  }
);
export const updateProgram = createAsyncThunk(
  "program/updateProgram",
  async (program) => {
    try {
      console.log("program updates: ", program);
      const res = await axios.post(`${active_base_url}/update`, program, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });

      const updateProgram = res.data.data;
      console.log("REsult: ", updateProgram);
      if (updateProgram) {
        const toastPayload = {
          index: 0,
          description: 3,
          type: "program",
        };
        setToast(toastPayload);
      }
      return updateProgram;
    } catch (err) {
      console.log("error from updateProgram", err);

      const toastPayload = {
        index: 1,
        description: 1,
        type: "program",
      };
      setToast(toastPayload);
    }
  }
);

export const programSlice = createSlice({
  name: "program",
  initialState: { value: initStateValue },
  reducers: {
    clearPrograms: (state, action) => {
      state.value = initStateValue;
    },
    setMenuSelect: (state, action) => {
      const { menuState } = action.payload;
      state.value.menuSelect = menuState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPrograms.pending, (state, action) => {
        state.value.status = "loading";
        state.value.programs = action.payload;
      })
      .addCase(fetchPrograms.fulfilled, (state, action) => {
        state.value.status = "succeeded";
        state.value.programs = action.payload;
      })
      .addCase(fetchPrograms.rejected, (state, action) => {
        state.value.status = "failed";
        state.value.error = action.error.message;
      });
  },
});

export const selectAllPrograms = (state) => state.program.value.programs;
export const getProgramStatus = (state) => state.program.value.status;
export const selectMenuSelect = (state) => state.program.value.menuSelect;
export const { clearPrograms, setMenuSelect } = programSlice.actions;
export default programSlice.reducer;
