import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { useState } from "react";
import { useDispatch } from "react-redux";
import Backdrop from "../Misc/Backdrop";
import { setToast } from "../../utils/ToastNotification";
import { checkEmptyFields } from "../../utils/FormValidation";

import { addField, fetchFields } from "../../features/programField";
import { addDropdownValue } from "../../features/dropdownValue";
import { addCheckValue } from "../../features/checkValue";
import { createLookupCharacteristic } from "../../features/lookupCharacteristic";
import { createLookupOption } from "../../features/lookupOption";
import { MdDelete } from "react-icons/md";

import { Button } from "../Ui/button";

const dropIn = {
  hidden: { y: "-100vh", opacity: 0 },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: { y: "100vh", opacity: 0 },
};
const AddFieldModal = ({ props }) => {
  const dispatch = useDispatch();

  const initialField = {
    fieldName: "",
    fieldDescription: "",
    required: false,
    fieldType: "",
    internalFacing: false,
    programId: props.programId,
    applicationOrder: "",
    checkOther: null,
  };

  const [field, setField] = useState(initialField);
  const [lookupCharacteristic, setLookupCharacteristic] = useState({
    fieldId: null,
    name1Label: "",
    name2Label: "",
    useMultiplier: false,
    multiplierLabel: "",
    inputLabel: "",
    searchDescription: "",
    addDescription: "",
  });

  const [lookupOption, setLookupOption] = useState([]);

  const updateLookupFunction = (index, key, val) => {
    const temp = lookupOption;
    temp[index][key] = val;
    setLookupOption([...temp]);
  };
  const addLookupOptionRow = () => {
    console.log("temp: ", lookupOption);

    const temp = lookupOption;
    temp.push({});
    setLookupOption([...temp]);
  };

  const removeLookupOptionRow = (index) => {
    const temp = lookupOption;
    temp.splice(index, 1);
    setLookupOption([...temp]);
  };

  //dropdown
  const [numOfInput, setNumOfInput] = useState(3);
  const [dropdownInput, setDropdownInput] = useState({
    1: "",
    2: "",
    3: "",
    4: "",
    5: "",
    6: "",
    7: "",
    8: "",
    9: "",
    10: "",
  });

  //checkbox
  const [numOfCheck, setNumOfCheck] = useState(3);
  const [checkboxInput, setCheckboxInput] = useState({
    1: "",
    2: "",
    3: "",
    4: "",
    5: "",
    6: "",
    7: "",
    8: "",
    9: "",
    10: "",
  });

  const submit = async (e) => {
    try {
      e.stopPropagation();
      e.preventDefault();
      if (checkEmptyFields(field).empty === false) {
        console.log("Field-submit: ", field);
        let res = await dispatch(addField(field));
        console.log("break1");
        let newFieldId = res.payload.id;
        console.log("break2");
        if (res.payload) {
          const toastPayload = {
            index: 0,
            description: 0,
            type: "program",
          };
          await dispatch(fetchFields());
          setToast(toastPayload);
          if (field.fieldType === "DROPDOWN") {
            const arrayConvert = Object.values(dropdownInput);
            const temp = arrayConvert.filter((el) => {
              return el !== "";
            });

            for (var i = 0; i < temp.length; i++) {
              const reduxPayload = { value: temp[i], fieldId: newFieldId };
              await dispatch(addDropdownValue(reduxPayload));
            }
          }

          if (field.fieldType === "CHECK") {
            const checkboxInputArr = Object.values(checkboxInput);
            const temp = checkboxInputArr?.filter((el) => {
              return el !== "";
            });

            for (let i = 0; i < temp.length; i++) {
              const reduxPayload = { value: temp[i], fieldId: newFieldId };
              await dispatch(addCheckValue(reduxPayload));
            }
          }

          if (field.fieldType === "LOOKUP") {
            const payload = lookupCharacteristic;
            payload["fieldId"] = newFieldId;
            await dispatch(createLookupCharacteristic(payload));

            if (lookupOption.length > 0) {
              lookupOption.forEach(async (lo) => {
                const addObj = {
                  fieldId: newFieldId,
                  lookupName1: lo["lookupName1"],
                  lookupName2: lo["lookupName2"],
                  multiplier: lo["multiplier"] ? lo["multiplier"] : 1,
                };

                const res = await dispatch(createLookupOption(addObj));
              });
            }
          }
          props.handleClose();
        } else {
          const toastPayload = {
            index: 1,
            description: 1,
            type: "program",
          };
          setToast(toastPayload);
        }
      } else {
        const toastPayload = {
          index: 1,
          description: 1,
          type: "program",
        };
        setToast(toastPayload);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Backdrop onClick={props.handleClose}>
      <motion.div
        className=" rounded w-[80rem] bg-white dark:bg-wc-gradient bg-cover text-wc-green dark:text-white shadow-md font-sans max-h-[60rem] overflow-auto"
        onClick={(e) => e.stopPropagation()}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
        transition={{ duration: 0.5 }}
      >
        <div className="w-full p-8 flex flex-col gap-4 line-clamp-7 min-w-12">
          <div className=" rounded w-full flex gap-2 items-center justify-start mb-8 border-b">
            <h2 className="text-lg font-medium text-center flex gap-2 text-blue-reef">
              Add New Field
            </h2>
          </div>
          <div className="flex flex-col ">
            <span className=" text-blue-reef">Field Name</span>
            <input
              type="text"
              className="password text-black rounded  border-2 border-gray-300 h-[2.5rem] pl-2"
              placeholder=" Name"
              name="fieldName"
              onChange={(e) => {
                setField((prev) => ({
                  ...prev,
                  fieldName: e.target.value,
                }));
              }}
            />
          </div>
          <div className="flex flex-col ">
            <span className=" text-blue-reef">Field Description</span>
            <textarea
              type="text"
              className="password text-black rounded border-2 border-gray-300 h-[8rem] p-2"
              placeholder=" Description"
              name="fieldName"
              onChange={(e) => {
                setField((prev) => ({
                  ...prev,
                  fieldDescription: e.target.value,
                }));
              }}
            />
          </div>
          <div className="flex flex-col mb-4">
            <span className=" text-blue-reef">Application Order</span>
            <input
              className="w-[4rem] border-2 border-gray-300 h-[2.5rem] rounded pl-2"
              type="text"
              onChange={(e) => {
                setField((prev) => ({
                  ...prev,
                  applicationOrder: e.target.value,
                }));
              }}
            />
          </div>
          <div className="flex gap-2 items-center">
            <input
              className="h-4 w-4"
              type="checkbox"
              onChange={(e) => {
                setField((prev) => ({
                  ...prev,
                  required: e.target.checked,
                }));
              }}
            />
            <label>Required Field ?</label>
          </div>
          <div className="flex gap-2 items-center">
            <input
              className="h-4 w-4"
              type="checkbox"
              onChange={(e) => {
                setField((prev) => ({
                  ...prev,
                  internalFacing: e.target.checked,
                }));
              }}
            />
            <label>Internal-facing? (hide from customer)</label>
          </div>
          <div className="flex flex-col">
            <label htmlFor="">Field Type</label>
            <div className="flex flex-col gap-2">
              <select
                name="role"
                id="role"
                className=" text-black rounded border-2 border-gray-300 h-[2.5rem]"
                onChange={(e) => {
                  if (e.target.value === "CHECK") {
                    setField((prev) => ({
                      ...prev,
                      checkOther: false,
                    }));
                  } else {
                    setField((prev) => ({
                      ...prev,
                      checkOther: null,
                    }));
                  }
                  setField((prev) => ({
                    ...prev,
                    fieldType: e.target.value,
                  }));
                }}
              >
                <option defaultValue>Select Field Type</option>
                <option value="BOOLEAN">Bool</option>
                <option value="CHECK">Check all that apply</option>
                <option value="DATE">Date</option>
                <option value="DROPDOWN">Dropdown</option>
                <option value="LOOKUP">Lookup</option>
                <option value="NUMBER">Number</option>
                <option value="STRING">String</option>
                <option value="SECTION">Section Header</option>
              </select>
              {field.fieldType === "DROPDOWN" && (
                <div className="flex flex-col gap-2 items-start">
                  {numOfInput >= 1 && (
                    <input
                      type="text"
                      placeholder=" Add Dropdown Value"
                      className=" text-black rounded border-2 border-gray-300 w-11/12 h-[2.5rem]"
                      onChange={(e) => {
                        setDropdownInput((prev) => ({
                          ...prev,
                          1: e.target.value,
                        }));
                      }}
                    />
                  )}

                  {numOfInput >= 2 && (
                    <input
                      type="text"
                      placeholder=" Add Dropdown Value"
                      className=" text-black rounded border-2 border-gray-300 w-11/12 h-[2.5rem]"
                      onChange={(e) => {
                        setDropdownInput((prev) => ({
                          ...prev,
                          2: e.target.value,
                        }));
                      }}
                    />
                  )}
                  {numOfInput >= 3 && (
                    <input
                      type="text"
                      placeholder=" Add Dropdown Value"
                      className=" text-black rounded border-2 border-gray-300 w-11/12 h-[2.5rem]"
                      onChange={(e) => {
                        setDropdownInput((prev) => ({
                          ...prev,
                          3: e.target.value,
                        }));
                      }}
                    />
                  )}
                  {numOfInput >= 4 && (
                    <input
                      type="text"
                      placeholder=" Add Dropdown Value"
                      className=" text-black rounded border-2 border-gray-300 w-11/12 h-[2.5rem]"
                      onChange={(e) => {
                        setDropdownInput((prev) => ({
                          ...prev,
                          4: e.target.value,
                        }));
                      }}
                    />
                  )}
                  {numOfInput >= 5 && (
                    <input
                      type="text"
                      placeholder=" Add Dropdown Value"
                      className=" text-black rounded border-2 border-gray-300 w-11/12 h-[2.5rem]"
                      onChange={(e) => {
                        setDropdownInput((prev) => ({
                          ...prev,
                          5: e.target.value,
                        }));
                      }}
                    />
                  )}
                  {numOfInput >= 6 && (
                    <input
                      type="text"
                      placeholder=" Add Dropdown Value"
                      className=" text-black rounded border-2 border-gray-300 w-11/12 h-[2.5rem]"
                      onChange={(e) => {
                        setDropdownInput((prev) => ({
                          ...prev,
                          6: e.target.value,
                        }));
                      }}
                    />
                  )}
                  {numOfInput >= 7 && (
                    <input
                      type="text"
                      placeholder=" Add Dropdown Value"
                      className=" text-black rounded border-2 border-gray-300 w-11/12 h-[2.5rem]"
                      onChange={(e) => {
                        setDropdownInput((prev) => ({
                          ...prev,
                          7: e.target.value,
                        }));
                      }}
                    />
                  )}
                  {numOfInput >= 8 && (
                    <input
                      type="text"
                      placeholder=" Add Dropdown Value"
                      className=" text-black rounded border-2 border-gray-300 w-11/12 h-[2.5rem]"
                      onChange={(e) => {
                        setDropdownInput((prev) => ({
                          ...prev,
                          8: e.target.value,
                        }));
                      }}
                    />
                  )}
                  {numOfInput >= 9 && (
                    <input
                      type="text"
                      placeholder=" Add Dropdown Value"
                      className=" text-black rounded border-2 border-gray-300 w-11/12 h-[2.5rem]"
                      onChange={(e) => {
                        setDropdownInput((prev) => ({
                          ...prev,
                          9: e.target.value,
                        }));
                      }}
                    />
                  )}
                  {numOfInput >= 10 && (
                    <input
                      type="text"
                      placeholder=" Add Dropdown Value"
                      className=" text-black rounded border-2 border-gray-300 w-11/12 h-[2.5rem]"
                      onChange={(e) => {
                        setDropdownInput((prev) => ({
                          ...prev,
                          10: e.target.value,
                        }));
                      }}
                    />
                  )}
                  <div className="flex gap-2 w-full mt-2">
                    <button
                      className="bg-wc-green text-white rounded p-2 w-4/12 h-[2.5rem]"
                      onClick={() => {
                        if (numOfInput <= 9) {
                          setNumOfInput(numOfInput + 1);
                        } else {
                          const toastPayload = {
                            index: 1,
                            description: 0,
                            type: "dropdown",
                          };
                          setToast(toastPayload);
                        }
                      }}
                    >
                      Add Value
                    </button>
                    <button
                      className="bg-red-400 text-white rounded p-2 w-4/12 h-[2.5rem]"
                      onClick={() => {
                        if (numOfInput >= 2) {
                          setDropdownInput((prev) => ({
                            ...prev,
                            [numOfInput]: "",
                          }));
                          setNumOfInput(numOfInput - 1);
                        } else {
                          const toastPayload = {
                            index: 1,
                            description: 1,
                            type: "dropdown",
                          };
                          setToast(toastPayload);
                        }
                      }}
                    >
                      Remove Value
                    </button>
                  </div>
                </div>
              )}

              {field.fieldType === "CHECK" && (
                <div className="flex flex-col gap-2 items-start">
                  {numOfCheck >= 1 && (
                    <input
                      type="text"
                      placeholder=" Add Checkbox Value"
                      className=" text-black rounded border-2 border-gray-300 w-11/12 h-[2.5rem]"
                      onChange={(e) => {
                        setCheckboxInput((prev) => ({
                          ...prev,
                          1: e.target.value,
                        }));
                      }}
                    />
                  )}

                  {numOfCheck >= 2 && (
                    <input
                      type="text"
                      placeholder=" Add Checkbox Value"
                      className=" text-black rounded border-2 border-gray-300 w-11/12 h-[2.5rem]"
                      onChange={(e) => {
                        setCheckboxInput((prev) => ({
                          ...prev,
                          2: e.target.value,
                        }));
                      }}
                    />
                  )}
                  {numOfCheck >= 3 && (
                    <input
                      type="text"
                      placeholder=" Add Checkbox Value"
                      className=" text-black rounded border-2 border-gray-300 w-11/12 h-[2.5rem]"
                      onChange={(e) => {
                        setCheckboxInput((prev) => ({
                          ...prev,
                          3: e.target.value,
                        }));
                      }}
                    />
                  )}
                  {numOfCheck >= 4 && (
                    <input
                      type="text"
                      placeholder=" Add Checkbox Value"
                      className=" text-black rounded border-2 border-gray-300 w-11/12 h-[2.5rem]"
                      onChange={(e) => {
                        setCheckboxInput((prev) => ({
                          ...prev,
                          4: e.target.value,
                        }));
                      }}
                    />
                  )}
                  {numOfCheck >= 5 && (
                    <input
                      type="text"
                      placeholder=" Add Checkbox Value"
                      className=" text-black rounded border-2 border-gray-300 w-11/12 h-[2.5rem]"
                      onChange={(e) => {
                        setCheckboxInput((prev) => ({
                          ...prev,
                          5: e.target.value,
                        }));
                      }}
                    />
                  )}
                  {numOfCheck >= 6 && (
                    <input
                      type="text"
                      placeholder=" Add Checkbox Value"
                      className=" text-black rounded border-2 border-gray-300 w-11/12 h-[2.5rem]"
                      onChange={(e) => {
                        setCheckboxInput((prev) => ({
                          ...prev,
                          6: e.target.value,
                        }));
                      }}
                    />
                  )}
                  {numOfCheck >= 7 && (
                    <input
                      type="text"
                      placeholder=" Add Checkbox Value"
                      className=" text-black rounded border-2 border-gray-300 w-11/12 h-[2.5rem]"
                      onChange={(e) => {
                        setCheckboxInput((prev) => ({
                          ...prev,
                          7: e.target.value,
                        }));
                      }}
                    />
                  )}
                  {numOfCheck >= 8 && (
                    <input
                      type="text"
                      placeholder=" Add Checkbox Value"
                      className=" text-black rounded border-2 border-gray-300 w-11/12 h-[2.5rem]"
                      onChange={(e) => {
                        setCheckboxInput((prev) => ({
                          ...prev,
                          8: e.target.value,
                        }));
                      }}
                    />
                  )}
                  {numOfCheck >= 9 && (
                    <input
                      type="text"
                      placeholder=" Add Checkbox Value"
                      className=" text-black rounded border-2 border-gray-300 w-11/12 h-[2.5rem]"
                      onChange={(e) => {
                        setCheckboxInput((prev) => ({
                          ...prev,
                          9: e.target.value,
                        }));
                      }}
                    />
                  )}
                  {numOfCheck >= 10 && (
                    <input
                      type="text"
                      placeholder="Add Checkbox Value"
                      className="text-black rounded border-2 border-gray-300 w-11/12 h-[2.5rem]"
                      onChange={(e) => {
                        setCheckboxInput((prev) => ({
                          ...prev,
                          10: e.target.value,
                        }));
                      }}
                    />
                  )}
                  <div className="flex gap-4 w-full mt-2">
                    <button
                      className="bg-wc-green text-white rounded p-2 w-4/12 "
                      onClick={() => {
                        if (numOfCheck <= 9) {
                          setNumOfCheck(numOfCheck + 1);
                        } else {
                          const toastPayload = {
                            index: 1,
                            description: 0,
                            type: "dropdown",
                          };
                          setToast(toastPayload);
                        }
                      }}
                    >
                      Add Checkbox
                    </button>
                    <button
                      className="bg-red-400 text-white rounded p-2 w-4/12 h-[2.5rem]"
                      onClick={() => {
                        if (numOfCheck >= 2) {
                          setCheckboxInput((prev) => ({
                            ...prev,
                            [numOfCheck]: "",
                          }));
                          setNumOfCheck(numOfCheck - 1);
                        } else {
                          const toastPayload = {
                            index: 1,
                            description: 1,
                            type: "dropdown",
                          };
                          setToast(toastPayload);
                        }
                      }}
                    >
                      Remove Value
                    </button>
                  </div>
                  <div className="flex gap-2 items-center mt-2">
                    <input
                      type="checkbox"
                      className="w-4 h-4"
                      defaultValue={field["checkOther"]}
                      onChange={(e) => {
                        setField((prev) => ({
                          ...prev,
                          checkOther: !field["checkOther"],
                        }));
                      }}
                    />
                    <span>Include "Other" option for customer</span>
                  </div>
                </div>
              )}
              {field.fieldType === "LOOKUP" && (
                <div className="flex flex-col gap-4 items-start">
                  <input
                    type="text"
                    placeholder="Label for First Reference"
                    className="text-black rounded border-2 border-gray-300 w-11/12 h-[2.5rem] pl-2"
                    onChange={(e) => {
                      setLookupCharacteristic((prev) => ({
                        ...prev,
                        name1Label: e.target.value,
                      }));
                    }}
                  />
                  <input
                    type="text"
                    placeholder="Label for Second Reference"
                    className="text-black rounded border-2 border-gray-300 w-11/12 h-[2.5rem] pl-2"
                    onChange={(e) => {
                      setLookupCharacteristic((prev) => ({
                        ...prev,
                        name2Label: e.target.value,
                      }));
                    }}
                  />
                  <input
                    type="text"
                    placeholder="Label for Customer Input"
                    className="text-black rounded border-2 border-gray-300 w-11/12 h-[2.5rem] pl-2"
                    onChange={(e) => {
                      setLookupCharacteristic((prev) => ({
                        ...prev,
                        inputLabel: e.target.value,
                      }));
                    }}
                  />
                  <div className="flex gap-2">
                    <input
                      type="checkbox"
                      className="w-4"
                      defaultChecked={lookupCharacteristic["useMultiplier"]}
                      onClick={(e) => {
                        setLookupCharacteristic((prev) => ({
                          ...prev,
                          useMultiplier: e.target.checked,
                        }));
                      }}
                    />
                    <label>Use multiplier</label>
                  </div>
                  {lookupCharacteristic["useMultiplier"] && (
                    <input
                      type="text"
                      placeholder="Label for Multiplier"
                      className="text-black rounded border-2 border-gray-300 w-11/12 h-[2.5rem] pl-2"
                      onChange={(e) => {
                        setLookupCharacteristic((prev) => ({
                          ...prev,
                          multiplierLabel: e.target.value,
                        }));
                      }}
                    />
                  )}
                  <div className="flex flex-col w-full">
                    <label>Search Description</label>
                    <span>
                      Enter the instructions for using the search that should be
                      displayed to customers.
                    </span>
                    <input
                      type="text"
                      className="text-black rounded border-2 border-gray-300 w-11/12 h-[2.5rem] pl-2"
                      onChange={(e) => {
                        setLookupCharacteristic((prev) => ({
                          ...prev,
                          searchDescription: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  <div className="flex flex-col w-full">
                    <label>Add Additional Description</label>
                    <span>
                      Enter the instructions displayed to customers for how to
                      use the "add additional" items not in the search list. If
                      left blank, this section will not be displayed.
                    </span>
                    <input
                      type="text"
                      className="text-black rounded border-2 border-gray-300 w-11/12 h-[2.5rem] pl-2"
                      onChange={(e) => {
                        setLookupCharacteristic((prev) => ({
                          ...prev,
                          addDescription: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  <div className="w-full flex flex-col">
                    <span>Lookup Values</span>
                    <table className="table-auto w-full text-left mt-4 shadow">
                      <thead>
                        <tr className="border-b border-gray-100 bg-blue-400  text-gray-50 ">
                          <th className="p-3 text-sm tracking-wide text-start whitespace-nowrap ">
                            Reference Column 1
                          </th>
                          <th className="p-3 text-sm tracking-wide text-start whitespace-nowrap ">
                            Reference Column 2
                          </th>
                          {lookupCharacteristic["useMultiplier"] && (
                            <th className="p-3 text-sm tracking-wide text-start whitespace-nowrap ">
                              Multiplier
                            </th>
                          )}
                          <th className="p-3 text-sm tracking-wide text-start whitespace-nowrap ">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {lookupOption.map((x, index) => {
                          return (
                            <tr
                              className="border-b border-gray-100  text-gray-900 "
                              key={index}
                            >
                              <td className="p-2 text-sm tracking-wide text-start whitespace-nowrap ">
                                <input
                                  type="text"
                                  className="text-black rounded border-2 border-gray-300 w-full h-[2.5rem] pl-2"
                                  value={x["lookupName1"]}
                                  onChange={(e) => {
                                    updateLookupFunction(
                                      index,
                                      "lookupName1",
                                      e.target.value
                                    );
                                  }}
                                />
                              </td>
                              <td className="p-2 text-sm tracking-wide text-start whitespace-nowrap ">
                                <input
                                  type="text"
                                  className="text-black rounded border-2 border-gray-300 w-full h-[2.5rem] pl-2"
                                  value={x["lookupName2"]}
                                  onChange={(e) => {
                                    updateLookupFunction(
                                      index,
                                      "lookupName2",
                                      e.target.value
                                    );
                                  }}
                                />
                              </td>
                              {lookupCharacteristic["useMultiplier"] && (
                                <td className="p-2 text-sm tracking-wide text-start whitespace-nowrap">
                                  <input
                                    type="text"
                                    className="text-black rounded border-2 border-gray-300 w-full h-[2.5rem] pl-2"
                                    value={x["multiplier"]}
                                    onChange={(e) => {
                                      updateLookupFunction(
                                        index,
                                        "multiplier",
                                        e.target.value
                                      );
                                    }}
                                  />
                                </td>
                              )}
                              <td className="p-3 text-sm tracking-wide text-start whitespace-nowrap ">
                                <MdDelete
                                  className="text-red-500 cursor-pointer"
                                  size="24"
                                  onClick={(e) => removeLookupOptionRow(index)}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>

                    <Button
                      variant="outline"
                      className="rounded bg-blue-500 text-white mr-auto text-sm p-4 mt-4"
                      onClick={(e) => addLookupOptionRow()}
                    >
                      Add Lookup Row
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>

          <Button
            variant="outline"
            className="rounded bg-green-reef text-white ml-auto text-sm p-4"
            onClick={(e) => {
              submit(e);
            }}
          >
            Save
          </Button>
        </div>
      </motion.div>
    </Backdrop>
  );
};

export default AddFieldModal;
