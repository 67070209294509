import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
const axios = require("axios").default;

axios.defaults.headers.common["Client"] = "ACWD";

const prod_base_url =
  "https://expressproxy-prod.azurewebsites.net/email-template";
const local_base_url = "http://localhost:3000/email-template";
const active_base_url =
  process.env.REACT_APP_PRODUCTION === "true" ? prod_base_url : local_base_url;

const initialState = {
  emailTemplates: [],
  status: "idle",
  error: null,
};

export const emailTemplateByProgramId = createAsyncThunk(
  "email-template/fetch-by-id",
  async (payload) => {
    try {
      console.log("fetch:::", payload);
      const res = await axios.post(`${active_base_url}/fetch-by-id`, payload);
      const et = res.data;
      console.log("DATA: ", et);
      return et;
    } catch (err) {
      return err.response.data;
    }
  }
);

export const getSubmissionEmailTemplate = createAsyncThunk(
  "email-template/fetch-submission-template",
  async (payload) => {
    try {
      const res = await axios.post(
        `${active_base_url}/submission-template`,
        payload
      );
      const et = res.data;
      return et;
    } catch (err) {
      return err.response.data;
    }
  }
);

export const createEmailTemplate = createAsyncThunk(
  "email-template/create",
  async (payload) => {
    try {
      const res = await axios.post(`${active_base_url}/create`, payload);
      const et = res.data;
      return et;
    } catch (err) {
      console.log(err);
    }
  }
);

export const updateEmailTemplate = createAsyncThunk(
  "email-template/update",
  async (payload) => {
    try {
      const res = await axios.post(`${active_base_url}/update`, payload);
      const et = res.data;
      return et;
    } catch (err) {
      console.log(err);
    }
  }
);

export const deleteEmailTemplate = createAsyncThunk(
  "email-template/delete",
  async (payload) => {
    try {
      const res = await axios.post(`${active_base_url}/delete`, payload);
      const et = res.data;
      return et;
    } catch (err) {
      return err.response.data;
    }
  }
);

export const emailTemplateSlice = createSlice({
  name: "emailTemplate",
  initialState: { value: initialState },
  reducers: {
    resetEmailTemplateStatus: (state, action) => {
      state.value = initialState;
      state.value.status = "idle";
    },
  },
  extraReducers(builder) {
    // builder
    //   .addCase(emailTemplateByProgramId.pending, (state, action) => {
    //     state.status = "loading";
    //     state.emailTemplates = action.payload;
    //   })
    //   .addCase(emailTemplateByProgramId.fulfilled, (state, action) => {
    //     state.status = "succeeded";
    //     state.emailTemplates = action.payload;
    //   })
    //   .addCase(emailTemplateByProgramId.rejected, (state, action) => {
    //     state.status = "failed";
    //     state.error = action.error.message;
    //   });
  },
});

export const selectEmailTemplates = (state) =>
  state.emailTemplate.emailTemplates;
export const selectEmailTemplateStatus = (state) => state.emailTemplate.status;
export const { resetEmailTemplateStatus } = emailTemplateSlice.actions;

export default emailTemplateSlice.reducer;
