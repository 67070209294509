import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
const axios = require("axios").default;

axios.defaults.headers.common["Client"] = "ACWD";

const prod_base_url =
  "https://expressproxy-prod.azurewebsites.net/email-confirmation/";
const local_base_url = "http://localhost:3000/email-confirmation/";
const active_base_url =
  process.env.REACT_APP_PRODUCTION === "true" ? prod_base_url : local_base_url;

const initialState = {};

export const validateEmailConfirmation = createAsyncThunk(
  "email-confirmation/validate",
  async (sp) => {
    try {
      console.log("url: ", active_base_url);
      const res = await axios.post(`${active_base_url}validate`, sp);
      console.log("RES>DATA: ", res);
      const updatedSP = res.data;
      return updatedSP;
    } catch (err) {
      return err.response.data;
    }
  }
);

export const emailConfirmationSlice = createSlice({
  name: "emailConfirmation",
  initialState,
  reducers: {},
  extraReducers(builder) {},
});

export default emailConfirmationSlice.reducer;
