import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { featureFlags } from "../../utils/FeatureFlags";
import {
  checkEmptyFields,
  passwordConfirmation,
} from "../../utils/FormValidation";
import { registerCustomer, fetchCustomers } from "../../features/customer";
import {
  registerProperty,
  fetchProperties,
  selectAllProperties,
} from "../../features/properties";

import { setToast } from "../../utils/ToastNotification";
import { toast } from "react-toastify";
import SearchBar from "../Ui/SearchBar";
import tblData from "../../assets/locationTable.csv";

import shpData from "../../assets/testShp.csv";
import Papa from "papaparse";
import InputMask from "react-input-mask";

import { formatFullAddress } from "../../utils/formatAddress";
import { useNavigate } from "react-router-dom";

import { LuEye } from "react-icons/lu";
import { LuEyeOff } from "react-icons/lu";

const axios = require("axios").default;

axios.defaults.headers.common["Client"] = "ACWD";

const slideIn = {
  hidden: { x: "-100vw", opacity: 0 },
  visible: {
    x: "0",
    opacity: 1,
    transition: {
      duration: 0.5,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: { x: "100vw", opacity: 0 },
};

const RegisterContact = ({ props }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const baseProperty = {
    customerId: null,
    account_no: null,
    owner: null,
    tenant: null,
    singleHomeFamily: null,
    multiFamilyComplex: null,
    mfcStreeNo: null,
    mfcStreeName: null,
    mfcUnitNo: null,
    commercial: null,
    residential: null,
    landscape: null,
    locationNo: null,
    noBillingAccount: null,
    dontHaveAccountNumber: null,
    isAccountHolder: null,
    isLandscaper: null,
    isPropertyManager: null,
    isLandlord: null,
    isOtherRelationship: null,
    landscaperContact: null,
    otherRelationshipText: null,
    provinceCd: "CA",
    isHOA: null,
    HOAName: null,
    deleted: 0,
    manualAddress1: null,
    manualAddress2: null,
    manualCity: null,
    manualZip: null,
  };

  const [locationTable, setLocationTable] = useState(null);
  const [nextReady, setNextReady] = useState(false);

  const [registerTab, setRegisterTab] = useState(props.internal == 1 ? 1 : 0);
  const [propertyList, setCurrentPropertyList] = useState([]);
  const [currentProperty, setCurrentProperty] = useState(baseProperty);
  const newPropList = useSelector(selectAllProperties);

  // this state is used to manage the radio button's default checked state
  const [radioChecked, setRadioChecked] = useState("");

  const changePropertyTypeRadio = (input) => {
    let tmpObj = currentProperty;
    tmpObj.singleHomeFamily = 0;
    tmpObj.multiFamilyComplex = 0;
    tmpObj.commercial = 0;
    tmpObj.residential = 0;
    tmpObj.landscape = 0;
    tmpObj[input] = true;
    setRadioChecked(input);

    // modify view based off what's checked
    tmpObj.multiFamilyComplex ? setShowMFRFields(1) : setShowMFRFields(0);
    tmpObj.typeofCommercialProperty = null;

    // update the current property
    setCurrentProperty(tmpObj);
  };

  // functions for the appearance of the form
  const [showMFRFields, setShowMFRFields] = useState(0);
  const [showLandscaperFields, setShowLandscaperFields] = useState(0);
  const [showManualAddress, setShowManualAddress] = useState(0);

  const [contact, setContact] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const getAccount = async (input) => {
    try {
      const url =
        process.env.REACT_APP_PRODUCTION === "true"
          ? `https://expressproxy-prod.azurewebsites.net/account/fetch-by-id`
          : `http://localhost:3000/account/fetch-by-id`;

      const res = await axios.post(
        url,
        { id: parseInt(input) },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      return res.data;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  const getAccountByLocationId = async (input) => {
    const url =
      process.env.REACT_APP_PRODUCTION === "true"
        ? `https://expressproxy-prod.azurewebsites.net/location/account/fetch-by-id`
        : `http://localhost:3000/location/account/fetch-by-id`;

    const res = await axios.post(
      url,
      { id: parseInt(input) },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    return res.data;
  };

  const emailInSystem = async (input) => {
    const url =
      process.env.REACT_APP_PRODUCTION === "true"
        ? `https://expressproxy-prod.azurewebsites.net/customer/usernames/fetch`
        : `http://localhost:3000/customer/usernames/fetch`;

    const res = await axios.post(
      url,
      { id: parseInt(input) },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    const emails = res.data.data.map((x) => x.email);
    if (emails.includes(input)) return true;
    return false;
  };

  const getPropertyList = async (input) => {
    const url =
      process.env.REACT_APP_PRODUCTION === "true"
        ? `https://expressproxy-prod.azurewebsites.net/customer-property/customer/fetch-by-id`
        : `http://localhost:3000/customer-property/customer/fetch-by-id`;

    const res = await axios.post(
      url,
      { id: parseInt(input) },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    const cp = res.data.data;
    return res;
  };

  // useEffect to load location data right off the bat
  useEffect(() => {
    getLocationData();
  }, []);

  // location/fetch-all
  const getLocationData = async () => {
    const url = "https://wcservices.azurewebsites.net/";

    // Define the parameters
    const params = {
      endpoint: "ACWD",
      data: JSON.stringify({
        function: "getAllLocations",
        // ... other data you want to send
      }),
    };

    // Create a query string from the parameters
    const queryString = new URLSearchParams(params).toString();

    // Combine the URL and the query string
    const fullUrl = `${url}?${queryString}`;

    let tmpData = await fetch(fullUrl)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        return data;
      });

    let tmp = tmpData.map((x) => {
      return formatFullAddress([
        x.HOUSE_NO,
        x.STREET_PFX_DIR,
        x.STREET_NM,
        x.STREET_NM_SFX,
        x.STREET_SFX_DIR,
        x.SEC_ADDR_ID,
        x.SEC_ADDR_RANGE,
        x.CITY,
        x.PROVINCE_CD,
        x.POSTAL_CODE,
      ]);
    });

    setTableAddressArray(tmp);

    let tmp2 = tmpData.map((x) => {
      return x.LOCATION_NO;
    });
    setTableAddressIDs(tmp2);

    return tmpData;
  };

  const checkRequiredFieldsEntered = async (currentTab) => {
    let check = "";

    // make sure all required fields on tab one are entered
    if (currentTab == 0) {
      if (Object.values(contact).filter((x) => !x).length)
        check = "Please fill out all fields on this page.";
      if (await emailInSystem(contact.email))
        check =
          "This email address is already registered in our system. Please use a different one.";
      if (contact.password != contact.confirmPassword)
        check =
          "Please make sure that your password and its confirmation are the same";
      if (contact.password.length < 6)
        check = "Please enter a password that is at least 6 characters long.";
      if (!/\S+@\S+\.\S+/.test(contact.email))
        check = "Please enter a valid email";
      if (
        !/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(
          contact.phone
        )
      ) {
        check = "Please enter a valid phone number";
      }
    }

    // make sure there is some kind of address or account number
    if (currentTab == 1) {
      if (
        !currentProperty.account_no &&
        !currentProperty.noBillingAccount &&
        !currentProperty.billingNoNumber
      )
        check = "Please fill in information on this page to proceed.";
      if (currentProperty.account_no) {
        const res = await getAccount(parseInt(currentProperty.account_no));
        console.log("after dispatch:", res);
        if (
          !res ||
          !res.data.id ||
          (res.data.cutoffDt !== "NULL" && res.data.cutoffDt !== "")
        ) {
          check =
            "Sorry - this Account Number is invalid or inactive. Check the Account Number, or clear it and use the checkbox options below.";
        }
      }
      if (currentProperty.shapeAddressVal && currentProperty.tableAddressVal)
        check = "Please only enter values to one dropdown."; // only one of the two should be entered
      if (
        currentProperty.shapeAddressVal &&
        !shapeAddressArray.includes(currentProperty.shapeAddressVal)
      )
        check = "Please select a value from the dropdown.";
      if (
        currentProperty.tableAddressVal &&
        !tableAddressArray.includes(currentProperty.tableAddressVal)
      )
        check = "Please select a value from the dropdown.";
      if (
        (currentProperty.manualAddress1 && currentProperty.tableAddressVal) ||
        (currentProperty.manualAddress1 && currentProperty.shapeAddressVal)
      )
        check = "Please clear the address you selected from the dropdown.";

      if (
        showManualAddress == 1 &&
        !(
          currentProperty.manualAddress1 &&
          currentProperty.manualAddress2 &&
          currentProperty.manualZip
        )
      ) {
        check = "Please fill in information on this page to proceed.";
      }

      // top one
      if (
        currentProperty.billingNoNumber == 1 &&
        !currentProperty.tableAddressVal &&
        !currentProperty.exactAddress
      ) {
        check = "Please fill in information on this page to proceed.";
      }

      // "My property does not have a billing account with ACWD" is checked, but nothing else filled out
      if (
        currentProperty.noBillingAccount == 1 &&
        !currentProperty.shapeAddressVal &&
        !currentProperty.exactAddress
      ) {
        check = "Please fill in information on this page to proceed.";
      }

      // bottom one
      if (
        currentProperty.exactAddress == 1 &&
        !currentProperty.shapeAddressVal &&
        !showManualAddress
      ) {
        check = "Please fill in information on this page to proceed.";
      }

      if (
        ![0, 3].includes(
          [
            currentProperty.manualAddress1,
            currentProperty.manualAddress2,
            currentProperty.manualZip,
          ].filter((x) => !x).length
        )
      )
        check = "Please enter all fields for the manual address entry.";

      // check to see if the Location ID is good if table values were sent in. if so set the account number to it!
      if (
        currentProperty.billingNoNumber &&
        currentProperty.tableAddressVal &&
        currentProperty.tableAddressID &&
        false // the false is to kill this from happening.
      ) {
        const res = await getAccountByLocationId(
          parseInt(currentProperty.tableAddressID)
        );
        setCurrentProperty((prev) => ({
          ...prev,
          locationNo: res.data?.accountNumber || null,
        }));
      }
    }

    // just make sure one is checked
    if (currentTab == 2) {
      if (
        ![
          currentProperty.owner,
          currentProperty.isAccountHolder,
          currentProperty.tenant,
          currentProperty.isLandscaper,
          currentProperty.isPropertyManager,
          currentProperty.isLandlord,
          currentProperty.isOtherRelationship,
        ].filter((x) => x).length
      )
        check = "Please select at least one option.";

      if (currentProperty.isLandscaper && !currentProperty.landscaperContact)
        check = "Please provide an answer in the Landscaper box.";

      if (
        currentProperty.isOtherRelationship &&
        !currentProperty.otherRelationshipText
      )
        check = "Please provide an answer in the Other Relationship box.";
    }

    // just make sure one is selected
    if (currentTab == 3) {
      if (
        ![
          currentProperty.singleHomeFamily,
          currentProperty.multiFamilyComplex,
          currentProperty.commercial,
          currentProperty.residential,
          currentProperty.landscape,
        ].filter((x) => x).length
      )
        check = "Please select an option.";
    }

    if (check) {
      toast(check, { type: "error" });
    } else if (currentTab < 3) {
      setRegisterTab(currentTab + 1);
    } else {
      if (!check) return true;
    }
    return false;
  };

  // TR check if this can still be used
  const registerContact = async (e) => {
    // skip registering a contact if we are on the internal version

    if (props.internal == 1) {
      try {
        let curId = 1; // this is the current logged in customer's id
        registerCustomerProperty(localStorage.user_id);
        return true;
      } catch (err) {
        console.log("register-customer-property-error: ", err);
      }
    }

    try {
      let res = null;
      if (checkEmptyFields(contact) && passwordConfirmation(contact)) {
        const res = await dispatch(
          registerCustomer({
            firstName: contact.firstName,
            lastName: contact.lastName,
            phone: contact.phone,
            address: contact.address || "",
            city: contact.city || "",
            state: contact.state || "",
            zip: contact.zip || "",
            company: contact.company || "",
            title: contact.title || "",
            email: contact.email,
            password: contact.password,
          })
        );

        //as admin
        if (res.payload) {
          let newID = res.payload.id;
          await dispatch(fetchCustomers());
          if (process.env.REACT_APP_PRODUCTION === "true") {
            try {
              const response1 = await axios.post(
                `https://expressproxy-prod.azurewebsites.net/bawsca/emails/send`,
                {
                  subject: `Confirm Water Savings Center Registration`,
                  body: `Hello! Thanks for registering for the ACWD Water Savings Center. As a one-time account setup step, please click this link to verify your email address and confirm your registration: https://watersavingscenter.acwd.org/${res.payload.token}/${res.payload.email}`,
                  to: [res.payload.email],
                  fromName: "ACWD Water Savings Center",
                },
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  },
                }
              );
            } catch (err) {
              console.log(err);
            }
          }

          //navigate to success registration page.
          //use props.internal as indicator to navigate to this success page.
          registerCustomerProperty(newID);

          navigate("registration");
        } else {
          const toastPayload = {
            index: 1,
            description: 1,
            type: "customer",
          };
          setToast(toastPayload);
        }
      }

      props.closeShowRegister();
    } catch (err) {
      console.log(err);
    }
  };

  const registerCustomerProperty = async (customerId) => {
    try {
      let shapeObj = {};
      //? tableAddressVal? shapeAddressVal?
      let tmpObj =
        shapeAddressObjects[
          shapeAddressArray.indexOf(currentProperty?.shapeAddressVal)
        ];
      await setCurrentProperty((prev) => ({
        ...prev,
        locationNo: tmpObj?.locationNo,
        mfcStreetName: tmpObj?.streetNm,
        mfcStreetNo: tmpObj?.houseNo,
        streetPfxDir: tmpObj?.streetPfxDir,
        streetSfxDir: tmpObj?.streetSfxDir,
        city: tmpObj?.city,
        // provinceCd: tmpObj?.provinceCd,
        manualEntry: tmpObj?.manualEntry,
      }));
      shapeObj.streetNm = tmpObj?.streetNm;
      shapeObj.city = tmpObj?.city;
      shapeObj.provinceCd = tmpObj?.provinceCd;

      let houseNumber = null;
      let streetName = null;
      let zip = null;
      let city = null;

      if (currentProperty?.tableAddressVal) {
        // const address = currentProperty?.tableAddressVal;
        const address = currentProperty?.tableAddressVal
          .toLowerCase()
          .replace(/\s/g, "")
          .trim();
        let tavObj = shapeAddressMap[address];
        houseNumber = tavObj.HOUSE_NO.trim();
        zip = tavObj.POSTAL_CODE.trim();
        streetName = tavObj.STREET_NM.trim();
        city = tavObj.CITY.trim();
      }

      let shpHouseNumber = null;
      let shpStreetName = null;
      let shpZip = null;
      let shpCity = null;
      let shpUnit = null;
      if (currentProperty?.shapeAddressVal) {
        const address = currentProperty?.shapeAddressVal
          .toLowerCase()
          .replace(/\s/g, "")
          .trim();

        const savObj = shpDataOg[address];
        shpHouseNumber = savObj?.SitusStreetNumber;
        shpStreetName = savObj?.SitusStreetName;
        shpZip = savObj?.SitusZip;
        shpCity = savObj?.SitusCity;
        shpUnit = savObj?.SitusUnit;
      }

      // this should be a check to see if the property has all necessary entries
      //differenctiate between
      let res2 = await dispatch(
        registerProperty({
          customerId: customerId ? customerId : null,
          ACCOUNT_NO: currentProperty?.account_no
            ? currentProperty.account_no
            : null,
          LOCATION_NO: currentProperty?.location_no || null, //  tmpObj?.locationNo ||
          owner: currentProperty?.owner ? currentProperty.owner : false,
          tenant: currentProperty?.tenant ? currentProperty.tenant : false,
          singleHomeFamily: currentProperty?.singleHomeFamily
            ? currentProperty.singleHomeFamily
            : false,
          multiFamilyComplex: currentProperty?.multiFamilyComplex
            ? currentProperty.multiFamilyComplex
            : false,
          mfcStreetNo: houseNumber || shpHouseNumber || null, //tmpObj?.houseNo ? tmpObj.houseNo : houseNumber,
          mfcStreetName: "", //tmpObj?.streetNm ? tmpObj.streetNm : "",
          mfcUnitNo: currentProperty.mfcUnitNo || shpUnit || "",
          commercial: currentProperty?.commercial
            ? currentProperty.commercial
            : false,
          residential: currentProperty?.residential
            ? currentProperty.residential
            : false,
          landscape: currentProperty?.landscape
            ? currentProperty.landscape
            : false,
          noBillingAccount: currentProperty?.noBillingAccount
            ? currentProperty.noBillingAccount
            : false,
          dontHaveAccountNumber: currentProperty?.dontHaveAccountNumber
            ? currentProperty.dontHaveAccountNumber
            : false,
          isAccountHolder: currentProperty?.isAccountHolder
            ? currentProperty.isAccountHolder
            : false,
          isLandscaper: currentProperty?.isLandscaper
            ? currentProperty.isLandscaper
            : false,
          isPropertyManager: currentProperty?.isPropertyManager
            ? currentProperty.isPropertyManager
            : false,
          isLandlord: currentProperty?.isLandlord
            ? currentProperty?.isLandlord
            : false,
          propertyYear: 1990,
          typeofCommercialProperty: currentProperty?.typeofCommercialProperty
            ? currentProperty.typeofCommercialProperty
            : null,
          //deleted: false,
          isOtherRelationship: currentProperty?.isOtherRelationship
            ? currentProperty.isOtherRelationship
            : false,
          landscaperContact: currentProperty?.landscaperContact
            ? currentProperty.landscaperContact
            : "",
          otherRelationshipText: currentProperty?.otherRelationshipText
            ? currentProperty.otherRelationshipText
            : "",
          isHoa: currentProperty?.isHOA ? currentProperty.isHOA : false,
          HOAname: currentProperty?.HOAName ? currentProperty.HOAName : "",
          houseNo: currentProperty?.houseNo || shpHouseNumber || null, //currentProperty?.houseNo ? currentProperty.houseNo : null,
          streetPfxDir: "", //tmpObj?.streetPfxDir ? tmpObj.streetPfxDir : "",
          streetNm:
            currentProperty?.manualAddress1 ||
            shpStreetName ||
            // shapeObj.streetNm ||
            streetName ||
            "", // tmpObj?.streetSfxDir ? tmpObj.streetSfxDir : "",
          streetNmSfx: "",
          streetSfxDir: "", //tmpObj?.streetSfxDir ? tmpObj.streetSfxDir : "",
          city: currentProperty?.manualAddress2 || city || shpCity || "", //shapeObj.city ||
          provinceCd: currentProperty?.provinceCd || "", //shapeObj.provinceCd ||
          postalCode: currentProperty?.manualZip || zip || shpZip || "", //shapeObj.provinceCd
          manualEntry:
            currentProperty?.manualAddress2 ||
            currentProperty?.manualZip ||
            currentProperty?.manualAddress1
              ? true
              : false,
        })
      );

      if (props.internal == 1) {
        props.setShowRegister(0);
        props.forceUpdate(new Date().valueOf());
        await dispatch(fetchProperties());
        await getPropertyList(customerId);
        // setPropertyList((prev)=>[...prev, tmpArr]);
        // take the new property and add it to the list
      }
    } catch (err) {
      console.log(err);
    }
  };

  const [tableAddressIDs, setTableAddressIDs] = useState([]);
  const [tableAddressArray, setTableAddressArray] = useState(null);
  const [shapeAddressIDs, setShapeAddressIDs] = useState([]);
  const [shapeAddressArray, setShapeAddressArray] = useState([]);
  const [shapeAddressObjects, setShapeAddressObjects] = useState([]);
  const [shpDataOg, setShpDataOg] = useState(null);
  const [shapeAddressMap, setShapeAddressMap] = useState(null);

  // TODO: Switch this functionality to use CE's endpoints & prevent blocking of js execution through promise usage
  useEffect(() => {
    Papa.parse(tblData, {
      header: true,
      download: true,
      skipEmptyLines: true,
      complete: function (results) {
        window["tblData"] = results.data;

        let tmp = results.data.map((x) => {
          return formatFullAddress([
            x.HOUSE_NO,
            x.STREET_PFX_DIR,
            x.STREET_NM,
            x.STREET_NM_SFX,
            x.STREET_SFX_DIR,
            x.SEC_ADDR_ID,
            x.SEC_ADDR_RANGE,
            x.CITY,
            x.PROVINCE_CD,
            x.POSTAL_CODE,
          ]);
        });
        const final = tmp;

        const final2 = {};
        results.data.map((x) => {
          final2[
            formatFullAddress([
              x.HOUSE_NO,
              x.STREET_PFX_DIR,
              x.STREET_NM,
              x.STREET_NM_SFX,
              x.STREET_SFX_DIR,
              x.SEC_ADDR_ID,
              x.SEC_ADDR_RANGE,
              x.CITY,
              x.PROVINCE_CD,
              x.POSTAL_CODE,
            ])
              .replace(/\s/g, "")
              .toLowerCase()
              .trim()
          ] = x;
        });

        // call the function that actually loads the location data table
        getLocationData();
        // const fetchData = async () => { //removed
        //   const res = await getLocationData();
        //   console.log(res);

        //   let tmp = res.data.data.map((x) => {
        //     return formatFullAddress([
        //       x.houseNo,
        //       x.streetPfxDir,
        //       x.streetNm,
        //       x.streetNmSfx,
        //       x.streetSfxDir,
        //       x.secAddrId,
        //       x.secAddrRange,
        //       x.city,
        //       x.provinceCd,
        //       x.postalCode,
        //     ]);
        //   });
        //   console.log(tmp);

        //   setTableAddressArray(tmp);
        //   /*
        //   streetNm: x.STREET_NM,
        //     houseNo: x.HOUSE_NO,
        //     streetPfxDir: x.STREET_PFX_DIR,
        //     streetNmSfx: x.STREET_NM_SFX,
        //     streetSfxDir: x.STREET_SFX_DIR,
        //     city: x.CITY,
        //     provinceCd: x.PROVINCE_CD,
        //     manualEntry: false,
        //     locationNo: x.LOCATION_NO,
        //     */
        // };

        // fetchData();

        /*

          // location/fetch-all
          const getLocationData = async () => {
            const res = await axios.post(
              `https://expressproxy-prod.azurewebsites.net/location/fetch-all`,
              { },
              {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
              }
            );
            console.log(
              "Location data in registercontact",
              res,
              res.data,
              res.data?.data
            );
            console.log(propertyList);
            return res;
          }

        */

        //setTableAddressArray(final);
        setShapeAddressMap(final2);

        window["tblString"] = tmp;
        tmp = results.data.map((x) => {
          return x.LOCATION_NO;
        });
        //setTableAddressIDs(tmp);

        tmp = results.data.map((x) => {
          return {
            streetNm: x.STREET_NM,
            houseNo: x.HOUSE_NO,
            streetPfxDir: x.STREET_PFX_DIR,
            streetNmSfx: x.STREET_NM_SFX,
            streetSfxDir: x.STREET_SFX_DIR,
            city: x.CITY,
            provinceCd: x.PROVINCE_CD,
            manualEntry: false,
            locationNo: x.LOCATION_NO,
          };
        });
        setShapeAddressObjects(tmp);
      },
    });

    let tmp = locationTable?.map((x) => {
      // x.streetNm === "WALNUT" && console.log("xx", x);
      // x.streetNm === "WALNUT" && console.log("xx", x);
      return formatFullAddress([
        x.houseNo,
        x.streetPfxDir,
        x.streetNm,
        x.streetNmSfx,
        x.streetSfxDir,
        x.secAddrId,
        x.secAddrRange,
        x.city,
        x.provinceCd,
        x.postalCode,
      ]);
    });

    window["tblString"] = tmp;
    tmp = locationTable?.map((x) => {
      return x.locationNo;
    });
    setTableAddressIDs(tmp);

    tmp = locationTable?.map((x) => {
      return {
        streetNm: x.streetNm,
        houseNo: x.houseNo,
        streetPfxDir: x.streetPfxDir,
        streetNmSfx: x.streetNmSfx,
        streetSfxDir: x.streetSfxDir,
        city: x.city,
        provinceCd: x.provinceCd,
        manualEntry: false,
        locationNo: x.locationNo,
      };
    });
    setShapeAddressObjects(tmp);

    Papa.parse(shpData, {
      header: true,
      download: true,
      skipEmptyLines: true,
      complete: function (results) {
        // modify to fit table address array in a nice way
        let tmp = results.data.map((x) => {
          return (
            x.SitusStreetNumber +
            " " +
            x.SitusStreetName +
            " " +
            x.SitusUnit +
            " " +
            x.SitusCity +
            " " +
            x.SitusZip
          )
            .replace(/\s+/g, " ")
            .trim();
        });
        const tmp2 = {};

        results.data.map((x) => {
          tmp2[
            (
              x.SitusStreetNumber +
              x.SitusStreetName +
              x.SitusUnit +
              x.SitusCity +
              x.SitusZip
            )
              .replace(/\s/g, "")
              .toLowerCase()
              .trim()
          ] = x;
        });

        const finalSA = tmp;
        setShapeAddressArray(finalSA);
        setShpDataOg(tmp2);
        tmp = results.data.map((x) => {
          return {
            streetNm: x.SitusStreetNumber + " " + x.SitusStreetName,
            mfcStreetName: x.SitusStreetName,
            unit: x.SitusUnit,
            city: x.SitusCity,
            postalCode: x.SitusZip,
            provinceCd: x.SitusZip,
          };
        });
        setShapeAddressObjects(tmp);
      },
    });
    setNextReady(true);
  }, [tblData, shpData]);

  // the list of addresses
  // const tableAddresses = tableAddressArray;

  // const shapeAddresses = shapeAddressArray;

  // used to see when values in the property change

  const validateEntry = () => {
    // check to see if all needed values are in the current object

    // add the current object to the list of objects
    setCurrentPropertyList([...propertyList, currentProperty]);

    // reset the current object
    setCurrentProperty(baseProperty);

    // go to the next tab of the popup
    setRegisterTab(registerTab + 1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    const form = document.querySelector("#registerForm"); // Replace 'myForm' with your form ID or use another selector

    const handleFormKeyDown = (e) => {
      if (e.key === "Enter") {
        e.preventDefault(); // Prevent form submission when Enter is pressed
      }
    };

    if (form) {
      form.addEventListener("keydown", handleFormKeyDown);
    }

    return () => {
      if (form) {
        form.removeEventListener("keydown", handleFormKeyDown);
      }
    };
  }, []);

  const setLocationDropdown = (payload) => {
    setCurrentProperty((prev) => ({
      ...prev,
      tableAddressVal: payload,
      location_no: tableAddressIDs[tableAddressArray?.indexOf(payload)], // index in the array of IDs that was pulled from the same file as the address array
      tableAddressID: tableAddressIDs[tableAddressArray?.indexOf(payload)], // index in the array of IDs that was pulled from the same file as the address array
    }));
  };

  const setShpDropdown = (payload) => {
    setCurrentProperty((prev) => ({
      ...prev,
      shapeAddressVal: payload,
    }));
  };

  const compData = (
    <>
      <div className="w-full h-full rounded bg-white">
        <form
          id="registerForm"
          className="contact-form flex flex-col p-8 gap-4  bg-white rounded"
          onSubmit={handleSubmit}
        >
          <span className="text-lg text-blue-reef font-semibold tracking-wide ">
            {props.internal == 0 && <>New Account Registration</>}
            {props.internal == 1 && <>Register a new property</>}
          </span>
          {registerTab == 0 && (
            <>
              <span className="text-blue-500 ">YOUR CONTACT INFORMATION</span>
              <div className="flex gap-12 ">
                <div className="flex flex-col w-full ">
                  <label htmlFor="">
                    First Name <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    className="border border-gray-200  rounded h-[2.5rem] focus:outline-none pl-2"
                    value={contact.firstName}
                    onChange={(e) => {
                      setContact((prev) => ({
                        ...prev,
                        firstName: e.target.value,
                      }));
                    }}
                  />
                </div>
                <div className="flex flex-col w-full">
                  <label htmlFor="">
                    Last Name <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    className="border border-gray-200 rounded h-[2.5rem] focus:outline-none bg-white pl-2"
                    value={contact.lastName}
                    onChange={(e) => {
                      setContact((prev) => ({
                        ...prev,
                        lastName: e.target.value,
                      }));
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-col w-6/12 pr-[1.5rem]">
                <label htmlFor="">
                  Telephone <span className="text-red-500">*</span>
                </label>
                <InputMask
                  type="text"
                  mask="(999) 999-9999"
                  alwaysShowMask={false}
                  className="border border-gray-200 rounded h-[2.5rem] focus:outline-none bg-white pl-2"
                  value={contact.phone}
                  placeholder="(444)444-4444"
                  onChange={(e) => {
                    setContact((prev) => ({
                      ...prev,
                      phone: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="flex flex-col w-full">
                <span className="mb-3 text-blue-500">
                  CHOOSE A USERNAME AND PASSWORD
                </span>
                <label htmlFor="">
                  Email Address (Username){" "}
                  <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  className="border border-gray-200 rounded h-[2.5rem] focus:outline-none bg-white pl-2"
                  value={contact.email}
                  onChange={(e) => {
                    setContact((prev) => ({
                      ...prev,
                      email: e.target.value,
                    }));
                  }}
                />
              </div>

              <div className="flex gap-12 ">
                <div className="flex flex-col w-full ">
                  <label htmlFor="" className="flex">
                    Password <span className="text-red-500 mr-4">*</span>
                    {showPassword ? (
                      <LuEye
                        size="24"
                        className="cursor-pointer "
                        onClick={() => {
                          setShowPassword(false);
                        }}
                      />
                    ) : (
                      <LuEyeOff
                        size="24"
                        className="cursor-pointer"
                        onClick={() => {
                          setShowPassword(true);
                        }}
                      />
                    )}
                  </label>
                  <input
                    type={showPassword ? "text" : "password"}
                    className="border border-gray-200 rounded h-[2.5rem] focus:outline-none bg-white pl-2"
                    value={contact.password}
                    onChange={(e) => {
                      setContact((prev) => ({
                        ...prev,
                        password: e.target.value,
                      }));
                    }}
                  />
                </div>
                <div className="flex flex-col w-full">
                  <label htmlFor="" className="flex">
                    Confirm Password{" "}
                    <span className="text-red-500 mr-4">*</span>
                    {showPassword ? (
                      <LuEye
                        size="24"
                        className="cursor-pointer "
                        onClick={() => {
                          setShowPassword(false);
                        }}
                      />
                    ) : (
                      <LuEyeOff
                        size="24"
                        className="cursor-pointer"
                        onClick={() => {
                          setShowPassword(true);
                        }}
                      />
                    )}
                  </label>
                  <input
                    type={showPassword ? "text" : "password"}
                    className="border border-gray-200  rounded h-[2.5rem] focus:outline-none bg-white pl-2"
                    value={contact.passwordConfirmation}
                    onChange={(e) => {
                      setContact((prev) => ({
                        ...prev,
                        confirmPassword: e.target.value,
                      }));
                    }}
                  />
                </div>
              </div>
              <span className="tracking-wide text-gray-400">
                Passwords are required to be a minimum of 6 characters in
                length.
              </span>
            </>
          )}
          {registerTab != 3 &&
            registerTab > 0 &&
            featureFlags.extendedRegistration && (
              <>
                <span className="text-blue-500 ">
                  ASSOCIATE A PROPERTY WITH YOUR ACCOUNT
                </span>
                <span className="text-gray-400 ">
                  You will have the option to associate additional properties
                  with your account after you register with the first one.
                </span>
              </>
            )}
          {registerTab == 1 && (
            <>
              <div className="flex flex-col">
                {/* <label htmlFor="">Account Number (from your water bill)</label> */}
                <label htmlFor="" className="flex gap-1">
                  <span> Account Number (from your water bill)</span>
                  <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  className="border border-gray-200 rounded h-[2.5rem] focus:outline-none bg-white pl-2"
                  value={currentProperty.account_no}
                  disabled={
                    currentProperty.noBillingAccount ||
                    currentProperty.billingNoNumber
                  }
                  onChange={(e) => {
                    setCurrentProperty((prev) => ({
                      ...prev,
                      account_no: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="flex flex-row align-top">
                <input
                  type="checkbox"
                  className="mr-3"
                  checked={currentProperty.noBillingAccount} // TODO add boolean for if the property doesnt have a billing or figure out how to put in db
                  disabled={
                    currentProperty.account_no ||
                    currentProperty.billingNoNumber
                  }
                  onChange={(e) => {
                    setCurrentProperty((prev) => ({
                      ...prev,
                      noBillingAccount: e.target.checked,
                    }));
                  }}
                />
                <span htmlFor="">
                  My property does not have a billing account with ACWD
                </span>
              </div>
              <div className="flex flex-row alignt-top">
                <input
                  type="checkbox"
                  className="mr-3"
                  checked={currentProperty.billingNoNumber} // TODO see the one above
                  disabled={
                    currentProperty.account_no ||
                    currentProperty.noBillingAccount
                  }
                  onChange={(e) => {
                    setCurrentProperty((prev) => ({
                      ...prev,
                      billingNoNumber: e.target.checked,
                    }));
                  }}
                />
                <span htmlFor="">
                  My property has a billing account, but I don't know what the
                  number is or I live in a unit within an HOA with a single
                  master meter
                </span>
              </div>
              {currentProperty.billingNoNumber == 1 && (
                <>
                  <div className="flex flex-col">
                    <label htmlFor="">
                      Property Street Address Location Lookup
                    </label>
                    <span class="text-gray-400">
                      Begin typing your street address and select the best
                      option that comes up.
                    </span>
                    <SearchBar
                      props={{
                        options: tableAddressArray,
                        setter: setLocationDropdown,
                      }}
                    />
                  </div>
                  <div className="flex flex-row alignt-top">
                    <input
                      type="checkbox"
                      className="mr-3"
                      checked={currentProperty.exactAddress} // TODO see the one above
                      onChange={(e) => {
                        setCurrentProperty((prev) => ({
                          ...prev,
                          exactAddress: e.target.checked,
                        }));
                      }}
                    />
                    <span htmlFor="">
                      My exact address isn't showing up in the lookup options
                      above.
                    </span>
                  </div>
                </>
              )}
              {(currentProperty.noBillingAccount == 1 ||
                (currentProperty.billingNoNumber == 1 &&
                  currentProperty.exactAddress == 1)) && (
                <>
                  <div className="flex flex-col">
                    <label htmlFor="">
                      Property Street Address Assessor Lookup
                    </label>
                    <span class="text-gray-400">
                      Begin typing your street address and select the best
                      option that comes up.
                    </span>
                    <SearchBar
                      props={{
                        options: shapeAddressArray,
                        setter: setShpDropdown,
                      }}
                    />
                  </div>
                  <div className="flex flex-row alignt-top">
                    <input
                      type="checkbox"
                      className="mr-3"
                      checked={showManualAddress}
                      onChange={(e) => {
                        e.target.checked
                          ? setShowManualAddress(1)
                          : setShowManualAddress(0);
                        currentProperty.manualAddress1 = "";
                        currentProperty.manualAddress2 = "";
                        currentProperty.manualCity = "";
                        currentProperty.manualZip = "";
                        setCurrentProperty((prev) => ({
                          ...prev,
                          exactAddress: e.target.checked,
                        }));
                      }}
                    />
                    <span htmlFor="">
                      My exact address isn't showing up in the lookup options
                      above.
                    </span>
                  </div>
                </>
              )}

              {showManualAddress == 1 &&
                (currentProperty.noBillingAccount == 1 ||
                  (currentProperty.billingNoNumber == 1 &&
                    currentProperty.exactAddress == 1)) && (
                  <>
                    <div className="flex flex-col">
                      <label htmlFor="">Manual Address Entry</label>
                      <span class="text-gray-400">
                        Please provide your property location information in the
                        fields below. “Note: ACWD only serves Fremont, Newark,
                        and Union City. Please only enter and register a
                        property in Fremont, Newark, or Union City.”
                      </span>
                      <div className="flex flex-row gap-2">
                        <input
                          type="text"
                          className="w-1/3  border border-gray-200 rounded h-[2.5rem] focus:outline-none bg-white pl-2"
                          placeholder="Street Number and Name"
                          value={currentProperty.manualAddress1}
                          onChange={(e) => {
                            setCurrentProperty((prev) => ({
                              ...prev,
                              manualAddress1: e.target.value,
                            }));
                          }}
                        />
                        {/* <input
                          type="text"
                          className="w-1/3  border border-gray-200 rounded h-[2.5rem] focus:outline-none bg-white pl-2"
                          placeholder="City"
                          value={currentProperty.manualAddress2}
                          onChange={(e) => {
                            setCurrentProperty((prev) => ({
                              ...prev,
                              manualAddress2: e.target.value,
                            }));
                          }}
                        /> */}

                        <select
                          className="text-gray-900 rounded border-2 border-gray-200 h-[2.5rem]"
                          onChange={(e) => {
                            setCurrentProperty((prev) => ({
                              ...prev,
                              manualAddress2: e.target.value,
                            }));
                          }}
                        >
                          <option default>Please select city</option>
                          <option value="FREMONT">FREMONT</option>
                          <option value="NEWARK">NEWARK</option>
                          <option value="UNION CITY">UNION CITY</option>
                        </select>

                        <input
                          type="text"
                          className="w-1/6 border border-gray-200 rounded h-[2.5rem] focus:outline-none bg-white pl-2"
                          placeholder="Zip Code"
                          value={currentProperty.manualZip}
                          onChange={(e) => {
                            setCurrentProperty((prev) => ({
                              ...prev,
                              manualZip: e.target.value,
                            }));
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}
            </>
          )}
          {registerTab == 2 && (
            <>
              <div className="flex flex-col">
                <label htmlFor="">Your relationship with the Property</label>
                <span class="text-gray-400">Please check all that apply.</span>
                <div className="flex flex-row">
                  <div className="w-1/3">
                    <input
                      type="checkbox"
                      className="mr-3 w-6 h-6"
                      checked={currentProperty.owner}
                      onChange={(e) => {
                        setCurrentProperty((prev) => ({
                          ...prev,
                          owner: e.target.checked,
                        }));
                      }}
                    />
                    <span>Owner</span>
                  </div>
                  <div className="w-1/3">
                    <input
                      type="checkbox"
                      className="mr-3 w-6 h-6"
                      checked={currentProperty.tenant}
                      onChange={(e) => {
                        setCurrentProperty((prev) => ({
                          ...prev,
                          tenant: e.target.checked,
                        }));
                      }}
                    />
                    <span>Tenant</span>
                  </div>
                  <div className="w-1/3">
                    <input
                      type="checkbox"
                      className="mr-3 w-6 h-6"
                      checked={currentProperty.isAccountHolder}
                      onChange={(e) => {
                        setCurrentProperty((prev) => ({
                          ...prev,
                          isAccountHolder: e.target.checked,
                        }));
                      }}
                    />
                    <span>Account Holder</span>
                  </div>
                </div>
                <div className="flex flex-row">
                  <div className="w-1/3">
                    <input
                      type="checkbox"
                      className="mr-3 w-6 h-6"
                      checked={currentProperty.isLandscaper}
                      onChange={(e) => {
                        e.target.checked
                          ? setShowLandscaperFields(1)
                          : setShowLandscaperFields(0);
                        setCurrentProperty((prev) => ({
                          ...prev,
                          isLandscaper: e.target.checked,
                        }));
                      }}
                    />
                    <span>Landscaper</span>
                  </div>
                  <div className="w-1/3">
                    <input
                      type="checkbox"
                      className="mr-3 w-6 h-6"
                      checked={currentProperty.isPropertyManager}
                      onChange={(e) => {
                        setCurrentProperty((prev) => ({
                          ...prev,
                          isPropertyManager: e.target.checked,
                        }));
                      }}
                    />
                    <span>Property Manager</span>
                  </div>
                  <div className="w-1/3">
                    <input
                      type="checkbox"
                      className="mr-3 w-6 h-6"
                      checked={currentProperty.isLandlord}
                      onChange={(e) => {
                        setCurrentProperty((prev) => ({
                          ...prev,
                          isLandlord: e.target.checked,
                        }));
                      }}
                    />
                    <span>Landlord</span>
                  </div>
                </div>
              </div>
              {showLandscaperFields == 1 && (
                <>
                  <div className="flex flex-col">
                    <span class="">
                      Are you working with the property owner for this rebate
                      project(s) and do they approve of the project(s)? Please
                      provide the name and contact of the property owner,
                      manager, or site representative that authorizes the
                      project(s).
                    </span>
                    <input
                      type="text"
                      className="border border-gray-200 rounded-sm shadow-sm focus:outline-none bg-white"
                      value={currentProperty.landscaperContact}
                      onChange={(e) => {
                        setCurrentProperty((prev) => ({
                          ...prev,
                          landscaperContact: e.target.value,
                        }));
                      }}
                    />
                  </div>
                </>
              )}
              <div className="flex flex-col">
                <div className="flex-row">
                  <input
                    type="checkbox"
                    className="mr-3 w-6 h-6"
                    checked={currentProperty.isOtherRelationship}
                    onChange={(e) => {
                      setCurrentProperty((prev) => ({
                        ...prev,
                        isOtherRelationship: e.target.checked,
                      }));
                    }}
                  />
                  <span htmlFor="">
                    Other relationship to property, please provide:
                  </span>
                </div>
                <div>
                  <input
                    type="text"
                    className="border border-gray-200 rounded h-[2.5rem] focus:outline-none bg-white w-full"
                    value={currentProperty.otherRelationshipText}
                    onChange={(e) => {
                      setCurrentProperty((prev) => ({
                        ...prev,
                        otherRelationshipText: e.target.value,
                      }));
                    }}
                  />
                </div>
              </div>
            </>
          )}
          {registerTab == 3 && (
            <>
              <span>What kind of property is this?</span>
              <div className="flex flex-row">
                <input
                  type="radio"
                  name="prop_type"
                  className="mr-3 w-6 h-6"
                  checked={radioChecked === "singleHomeFamily"}
                  onChange={(e) => {
                    changePropertyTypeRadio("singleHomeFamily");
                  }}
                />
                <span htmlFor="">Single Family Home</span>
              </div>
              <div className="flex flex-row">
                <span>
                  (Use this if you have your own separate water account in a
                  single-family home, condo, townhouse, or appartment)
                </span>
              </div>
              <div className="flex flex-row">
                <input
                  type="radio"
                  name="prop_type"
                  className="mr-3 w-6 h-6"
                  checked={radioChecked === "multiFamilyComplex"}
                  onChange={(e) => {
                    changePropertyTypeRadio("multiFamilyComplex");
                  }}
                />
                <span htmlFor="">Multi-Family Complex or HOA</span>
              </div>
              <div className="flex flex-row">
                <span>
                  (Use this if you are a tenant, HOA unit owner, or appartment
                  manager for a multi-family property)
                </span>
              </div>
              {showMFRFields == 1 && (
                <>
                  <div className="flex flex-col">
                    <div className="flex-row">
                      <span htmlFor="">
                        If you are a tenant or owner of just one unit, please
                        provide the unit number or specific address of your
                        unique unit:
                      </span>
                    </div>
                    <div>
                      <input
                        type="text"
                        className="border border-gray-200 rounded-sm shadow-sm focus:outline-none bg-white w-full"
                        value={currentProperty.mfcUnitNo}
                        onChange={(e) => {
                          setCurrentProperty((prev) => ({
                            ...prev,
                            mfcUnitNo: e.target.value, //TODO see if this is correct
                          }));
                        }}
                      />
                    </div>
                  </div>

                  <div className="flex flex-col">
                    <div className="flex-row">
                      <span htmlFor="">
                        <input
                          type="checkbox"
                          className="mr-3"
                          checked={currentProperty.isHOA}
                          onChange={(e) => {
                            setCurrentProperty((prev) => ({
                              ...prev,
                              isHOA: e.target.checked,
                            }));
                          }}
                        />
                        I live in an HOA Complex
                      </span>
                    </div>
                    {currentProperty.isHOA == 1 && (
                      <div>
                        <span>HOA Name:</span>
                        <input
                          type="text"
                          className="border border-gray-200 rounded-sm shadow-sm focus:outline-none bg-white w-full"
                          value={currentProperty.HOAName}
                          onChange={(e) => {
                            setCurrentProperty((prev) => ({
                              ...prev,
                              HOAName: e.target.value,
                            }));
                          }}
                        />
                      </div>
                    )}
                  </div>
                </>
              )}
              <div className="flex flex-row">
                <input
                  type="radio"
                  name="prop_type"
                  className="mr-3 w-6 h-6"
                  checked={radioChecked === "commercial"}
                  onChange={(e) => {
                    changePropertyTypeRadio("commercial");
                  }}
                />
                <span htmlFor="">Commercial</span>
              </div>
              <div className="flex flex-row">
                <span>
                  (Use this for commercial, industrial, or institutional
                  locations)
                </span>
              </div>

              {/* {radioChecked === "commercial" && (
                <>
                  <div>
                    <span>
                      Please select the option below that best represents the
                      use of this commercial property:
                    </span>
                  </div>
                  <div className="flex flex-row">
                    <select
                      className="mr-3 h-16 whitespace-pre-wrap border border-gray-200 rounded-sm shadow-sm focus:outline-none bg-white w-full"
                      value={currentProperty.typeofCommercialProperty}
                      onChange={(e) => {
                        setCurrentProperty((prev) => ({
                          ...prev,
                          typeofCommercialProperty: e.target.value,
                        }));
                      }}
                    >
                      <option disabled selected>
                        Select One
                      </option>
                      <option value="Sales">
                        Sales (e.g., retail, shopping centers/malls, grocery
                        stores and food markets)
                      </option>
                      <option value="Services">
                        Services (e.g., auto, personal)
                      </option>
                      <option value="Lodging">
                        Lodging (e.g., hospitality, retirement homes)
                      </option>
                      <option value="Food/Beverage">
                        Food/Beverage (e.g., full service, fast food)
                      </option>
                      <option value="Offices">Offices</option>
                      <option value="Healthcare">
                        Healthcare (e.g., hospitals, medical offices, medical
                        and laboratory equipment and processes)
                      </option>
                      <option value="Public Services">
                        Public Services (e.g., government, prisons and
                        correctional facilities)
                      </option>
                      <option value="Recreation, non-water">
                        Recreation, non-water (e.g., athletic facilities,
                        entertainment facilities, parks/cemeteries, golf course)
                      </option>
                      <option value="Water Recreation">
                        Water Recreation (e.g., public pools/water parks)
                      </option>
                      <option value="Laundry">
                        Laundry (e.g., laundromats, commercial/industrial
                        laundries)
                      </option>
                      <option value="Vehicle Wash">Vehicle Wash</option>
                      <option value="Religious Buildings">
                        Religious Buildings
                      </option>
                      <option value="Education">Education</option>
                      <option value="Industrial, non-manufacturing">
                        Industrial, non-manufacturing (e.g.,
                        temperature-controlled warehouses, non
                        temperature-controlled warehouses)
                      </option>
                      <option value="Manufacturing">Manufacturing</option>
                      <option value="Utility">Utility</option>
                      <option value="Mixed Use Commercial">
                        Mixed Use Commercial (e.g., strip malls, shopping
                        centers, and other commercial spaces that have multiple
                        uses and/or are subject to frequent changes of tenants
                        with different water use patterns)
                      </option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                </>
              )} */}
              <div className="flex flex-row">
                <input
                  type="radio"
                  name="prop_type"
                  className="mr-3 w-6 h-6"
                  checked={radioChecked === "residential"}
                  onChange={(e) => {
                    changePropertyTypeRadio("residential");
                  }}
                />
                <span htmlFor="">Mixed Use Commercial / Residential</span>
              </div>
              <div></div>
              <div className="flex flex-row">
                <span>
                  (Use this if your account serves both commercial and
                  residential units)
                </span>
              </div>
              <div className="flex flex-row">
                <input
                  type="radio"
                  name="prop_type"
                  className="mr-3 w-6 h-6"
                  checked={radioChecked === "landscape"}
                  onChange={(e) => {
                    changePropertyTypeRadio("landscape");
                  }}
                />
                <span htmlFor="">Landscape (Irrigation Account)</span>
              </div>

              <div className="flex flex-row">
                <span>
                  (Use this if you have a dedicated irrigation account and the
                  meter serves irrigation uses only)
                </span>
              </div>
            </>
          )}

          <div className="flex justify-center ">
            <button
              className="text-white bg-gray-500 hover:bg-gray-700 focus:ring-4 focus:ring-gray-300 font-medium rounded text-sm px-5 py-2 mr-2 dark:bg-gray-500 dark:hover:bg-gray-500 focus:outline-none dark:focus:ring-gray-500"
              onClick={(e) => {
                e.preventDefault();
                if (props.internal == 1) props.setShowRegister(0);
                else props.closeShowRegister();
              }}
            >
              Cancel
            </button>

            <button
              className="text-white bg-gray-500 hover:bg-gray-700 focus:ring-4 focus:ring-gray-300 font-medium rounded text-sm px-5 py-2 mr-2 dark:bg-gray-500 dark:hover:bg-gray-500 focus:outline-none dark:focus:ring-gray-500"
              onClick={(e) => {
                e.preventDefault();
                let lowest = props.internal ? 1 : 0;
                return registerTab == lowest
                  ? null
                  : setRegisterTab(registerTab - 1);
              }}
            >
              Back
            </button>

            {registerTab < 3 && (
              <>
                {nextReady ? (
                  <button
                    className="text-white bg-blue-reef hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm px-5 py-2 mr-2 dark:bg-blue-400 dark:hover:bg-blue-reef focus:outline-none dark:focus:ring-blue-500"
                    onClick={(e) => {
                      //from landing
                      e.preventDefault();
                      // logic for changing the tab is inside of the following function
                      checkRequiredFieldsEntered(registerTab);

                      //as admin
                      //registerContact(e);
                    }}
                  >
                    Next
                  </button>
                ) : (
                  <span className="my-auto">
                    loading location information...
                  </span>
                )}
              </>
            )}

            {registerTab == 3 && (
              <>
                <button
                  className="rounded-md bg-green-reef w-full p-4 text-white hover:bg-green-600 tracking-wide"
                  onClick={(e) => {
                    e.preventDefault();
                    checkRequiredFieldsEntered(registerTab).then((e) => {
                      if (e) {
                        registerContact(e);
                      }
                    });
                  }}
                >
                  Register
                </button>
              </>
            )}
          </div>
        </form>
      </div>
    </>
  );

  return (
    <>
      {props.internal == 1 && <>{compData}</>}
      {props.internal != 1 && (
        <motion.div
          className="flex flex-col w-8/12 gap-8 transition-transform duration-250 z-51"
          onClick={(e) => e.stopPropagation()}
          variants={slideIn}
          initial="hidden"
          animate="visible"
          exit="exit"
          transition={{ duration: 0.5 }}
        >
          {compData}
        </motion.div>
      )}
    </>
  );
};

export default RegisterContact;
