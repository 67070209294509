import React from "react";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
const axios = require("axios").default;

axios.defaults.headers.common["Client"] = "ACWD";

const prod_base_url =
  "https://expressproxy-prod.azurewebsites.net/program/status";
const local_base_url = "http://localhost:3000/program/status";
const prod_status_url = "https://expressproxy-prod.azurewebsites.net/status";
const local_status_url = "http://localhost:3000/status";
const active_status_url =
  process.env.REACT_APP_PRODUCTION === "true"
    ? prod_status_url
    : local_status_url;

const active_base_url =
  process.env.REACT_APP_PRODUCTION === "true" ? prod_base_url : local_base_url;

const prod_status_log_url =
  "https://expressproxy-prod.azurewebsites.net/status/status-log";
const local_status_log_url = "http://localhost:3000/status/status-log";
const active_status_log_url =
  process.env.REACT_APP_PRODUCTION === "true"
    ? prod_status_log_url
    : local_status_log_url;

const initStateValue = {
  statusLogs: [],
  statusLogStatus: "idle",
  statusList: [],
  status: "idle",
  error: null,
};

export const fetchStatusList = createAsyncThunk(
  "status/fetchStatusList",
  async () => {
    try {
      const res = await axios.post(
        `${active_base_url}/fetch-all`,
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const status = res.data.data;
      return status;
    } catch (err) {
      console.log(err);
    }
  }
);

export const assignmentChart = createAsyncThunk(
  "status-log/assignmentChart",
  async (id) => {
    try {
      const res = await axios.post(
        `${active_status_log_url}/staff-assignment`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const staff_assignment = res.data.data;
      return staff_assignment;
    } catch (err) {
      console.log(err);
    }
  }
);

export const getStatusFilterNames = createAsyncThunk(
  "status/filter-names",
  async (payload) => {
    try {
      const res = await axios.post(
        `${active_status_url}/number/fetch`,
        payload,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const status = res.data.data;
      return status;
    } catch (err) {
      console.log(err);
    }
  }
);

export const addStatus = createAsyncThunk(
  "status/addStatus",
  async (status) => {
    try {
      const tempStatus = {
        number: status.number,
        customerNumber: status.customerNumber,
        statusId: status.statusId,
        name: status.name,
        customerName: status.customerName,
        milestone: status.milestone,
        description: status.description,
        userPermissionControls: status.userPermissionControls,
        daysBeforeAlert: parseInt(status.daysBeforeAlert),
        programId: status.programId,
      };
      const res = await axios.post(`${active_base_url}/add`, tempStatus, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      const addStatus = res.data.data;
      return addStatus;
    } catch (err) {
      console.log(err);
    }
  }
);

export const deleteStatus = createAsyncThunk("status/delete", async (id) => {
  try {
    const res = await axios.post(`${active_base_url}/delete`, id, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    const deletedStatus = res.data.data;
    return deletedStatus;
  } catch (err) {
    console.log(err);
  }
});

export const updateStatus = createAsyncThunk(
  "status/update",
  async (status) => {
    try {
      const res = await axios.post(`${active_base_url}/update`, status, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      const updatedStatus = res.data.data;
      return updatedStatus;
    } catch (err) {
      console.log(err);
    }
  }
);

export const addStatusLog = createAsyncThunk(
  "status/statusLog/addStatus",
  async (status) => {
    try {
      console.log("STATUS: ", status);
      const res = await axios.post(`${active_status_log_url}/add`, status, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      const addStatus = res.data.data;
      return addStatus;
    } catch (err) {
      console.log(err);
    }
  }
);
export const fetchStatusLogs = createAsyncThunk(
  "status/fetchStatusLog",
  async () => {
    try {
      const res = await axios.post(
        `${active_status_log_url}/fetch-all`,
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const statusLogs = res.data.data;
      return statusLogs;
    } catch (err) {
      console.log(err);
    }
  }
);

export const statusSlice = createSlice({
  name: "status",
  initialState: { value: initStateValue },
  reducers: {
    clearStatusList: (state, action) => {
      state.value = initStateValue;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchStatusList.pending, (state, action) => {
        state.value.status = "loading";
        state.value.statusList = action.payload;
      })
      .addCase(fetchStatusList.fulfilled, (state, action) => {
        state.value.status = "succeeded";
        state.value.statusList = action.payload;
      })
      .addCase(fetchStatusList.rejected, (state, action) => {
        state.value.status = "failed";
        state.value.error = action.error.message;
      })
      .addCase(fetchStatusLogs.pending, (state, action) => {
        state.value.statusLogStatus = "loading";
        state.value.statusLogs = action.payload;
      })
      .addCase(fetchStatusLogs.fulfilled, (state, action) => {
        state.value.statusLogStatus = "succeeded";
        state.value.statusLogs = action.payload;
      })
      .addCase(fetchStatusLogs.rejected, (state, action) => {
        state.value.statusLogStatus = "failed";
        state.value.error = action.error.message;
      });
  },
});

export const selectStatusList = (state) => state.status.value.statusList;
export const getStatusListStatus = (state) => state.status.value.status;

export const selectStatusLogs = (state) => state.status.value.statusLogs;
export const getStatusLogStatus = (state) => state.status.value.statusLogStatus;

export const { clearStatusList } = statusSlice.actions;

export default statusSlice.reducer;
