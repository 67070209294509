import React, { useEffect, useState } from "react";
import { assignmentChart } from "../../features/status";
import { useDispatch } from "react-redux";

export const AssignmentChart = ({ props }) => {
  const dispatch = useDispatch();
  const [chartData, setChartData] = useState(null);
  useEffect(() => {
    const fetchChartData = async () => {
      try {
        const res = await dispatch(assignmentChart());
        console.log("fetch-chart-data: ", res);
        setChartData(res.payload);
      } catch (err) {}
    };
    if (!chartData) fetchChartData();
  }, []);
  const tmpStaff = [
    { first: "Sam", last: "Wesley", count: 34 },
    { first: "Emily", last: "Jordan", count: 23 },
    { first: "Tara", last: "Hall", count: 2 },
    { first: "Mike", last: "Jame", count: 4 },
    { first: "Jessica", last: "West", count: 74 },
    { first: "No Staff Assignment", last: "", count: 180 },
  ];

  // super hacky
  const getStaffHTML = (input) => {
    if (!input.length) return <></>;
    return input.map((x, i) => {
      return (
        <tr
          className={`rounded-lg dark:text-white text-black text-start  hover:bg-blue-50 cursor-pointer`}
        >
          <td className="p-2">
            {/* {x.first} {x.last} */}
            {x.firstName || "No Staff Assigned"} {x.lastName}
          </td>
          <td>{x.count}</td>
        </tr>
      );
    });
  };

  return (
    <div className="sm:w-full lg:w-6/12 relative flex flex-col items-center rounded shadow">
      <div className="w-full top-0 bg-blue-reef p-3 rounded-t flex justify-center items-center text-lg ">
        <h2 className="text-white tracking-wide pl-1 pr-1">
          {props?.title
            ? props?.title
            : "Current Staff Assignments for In Progress Applications"}
        </h2>
      </div>
      <div className="w-full h-full p-8">
        <table className="min-w-full font-sans cursor-pointer">
          <thead className="border-b-2 border-gray-200 ">
            <tr>
              <th className="py-1 text-sm text-gray-800 font-semibold tracking-widerr text-start whitespace-nowrap border-r border-gray-50">
                Staff Name
              </th>
              <th className="text-sm text-gray-800 font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                Count of Applications
              </th>
            </tr>
          </thead>
          <tbody className="divide-y">
            {/* {getStaffHTML(props?.data ? props?.data : tmpStaff)} */}
            {getStaffHTML(chartData ? chartData : tmpStaff)}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AssignmentChart;
