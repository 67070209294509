import { useEffect, useState } from "react";
import {
  passwordResetByUrl,
  validatePasswordReset,
} from "../features/passwordReset";
import { validateEmailConfirmation } from "../features/emailConfirmation";

import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

function NotFound() {
  const dispatch = useDispatch();
  const location = useLocation();
  // const url = location.pathname.replace(/\//g, "");
  // Step 2: Split the pathname into parts by "/"
  let parts = location.pathname.split("/");
  let url = parts[1];
  let type = parts[2];
  const [reset, setReset] = useState(null);
  const [verify, setVerify] = useState(null);

  function hasOneDash(inputString) {
    let questionMarkCount = 0;

    for (let i = 0; i < inputString.length; i++) {
      if (inputString[i] === "-") {
        questionMarkCount++;
      }
    }

    return questionMarkCount === 1;
  }

  const confirmRegister = async (payload) => {
    try {
      //else if payload has a single question mark. split string on question mark
      //take first half as emal and second half as token.
      // verify both properties in database
      //if true send link to landing page and tell customer to login
      //if false do nothing
      //setVerify

      //fix here
      // console.log("confirm-register: ", payload);
      // console.log("Validate Syntax: ", hasOneDash(payload));
      // if (hasOneDash(payload)) {
      //   const urlArr = payload.split("-");
      //   const email = urlArr[0];
      //   const token = urlArr[1];

      console.log("emaiL: ", type);
      console.log("token: ", url);

      const res = await dispatch(
        validateEmailConfirmation({ email: type, token: url })
      );
      console.log("Validated Email", res);
      // if (res.payload.data) {
      //   setVerify(true);
      // } else {
      //   setVerify(false);
      // }

      res.payload.data ? setVerify(true) : setVerify(false);
      console.log("Validated Email", res);
      // } else {
      //   setVerify(false);
      // }
    } catch (err) {
      console.log(err);
    }
  };
  const validateUrl = async (payload) => {
    try {
      console.log("validate-url-payload: ", payload);
      console.log("validate-1: ", url);
      const res = await dispatch(passwordResetByUrl({ url }));
      console.log("validate-url: ", res);
      if (res.payload.data) {
        setReset(true);
      } else {
        confirmRegister();
        setReset(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const [resetType, setResetType] = useState(null);
  useEffect(() => {
    if (url && type) {
      validateUrl(url);
    }
  }, [url]);

  useEffect(() => {
    if (type === "admin" || type === "customer") {
      setResetType(type);
    }
  }, [type]);

  // password reset stuff. consider moving to a component instead
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const resetPassword = async () => {
    try {
      setTimeout(() => {
        window.location.href = "/";
      }, 500);
      const res = await dispatch(
        validatePasswordReset({
          email,
          password,
          url,
          type: resetType,
        })
      );
      console.log("Redirect should have happened.", res);
    } catch {
      console.log("ba");
    }
  };

  return (
    <>
      <div className="w-full flex flex-col justify-center items-center h-screen gap-12">
        <div className="bg-test1 w-[21rem] h-[5rem] bg-cover flex "></div>

        {reset === false && verify === false && (
          <div>
            <h1>404 - Page Not Found</h1>
            <p>The page you are looking for does not exist.</p>
          </div>
        )}
        {reset === true && (
          <div>
            <form
              className=" mx-auto my-auto flex flex-col justify-center rounded bg-gray-100 p-8"
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <span className="mb-4">Reset Password</span>

              <label>Email</label>
              <input
                className="h-[2rem] pl-2"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <label> Password</label>
              <input
                className="h-[2rem] pl-2"
                type="password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              <button
                className="mt-6 flex w-full justify-center rounded bg-blue-800 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                onClick={resetPassword}
              >
                Submit
              </button>
            </form>
          </div>
        )}
        {verify === true && (
          <span>
            You’re confirmed! Please visit{" "}
            <a
              href="https://watersavingscenter.acwd.org/"
              className="text-blue-500 cursor-pointer"
            >
              https://watersavingscenter.acwd.org/
            </a>{" "}
            to log in for the first time!
          </span>
        )}
      </div>
    </>
  );
}

export default NotFound;
