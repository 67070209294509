import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getUploadStatus,
  selectAllUploads,
  fetchUploads,
} from "../../../features/programUpload";
import {
  getFieldStatus,
  selectAllFields,
  fetchFields,
} from "../../../features/programField";
import {
  fetchProperties,
  getPropertyStatus,
  selectAllProperties,
} from "../../../features/properties";
import {
  fetchApplicationStatus,
  getStatus,
} from "../../../features/applicationStatus";
import { getApplicationStatus } from "../../../features/application";
import {
  fetchApplicationValues,
  getValueStatus,
} from "../../../features/applicationValue";
import { fetchStatusList, getStatusListStatus } from "../../../features/status";

import CustomerApplicationTable from "../../Applications/CustomerApplicationTable";
import CustomerUnlockApplicationModal from "../../Applications/CustomerUnlockApplicationModal";
import CloseWarningModal from "../../Misc/CloseWarningModal";

const MyApplications = ({ props }) => {
  const dispatch = useDispatch();
  const uploadList = useSelector(selectAllUploads);
  const fieldList = useSelector(selectAllFields);
  const propertyList = useSelector(selectAllProperties);
  const uploadStatus = useSelector(getUploadStatus);
  const propertyStatus = useSelector(getPropertyStatus);
  const fieldStatus = useSelector(getFieldStatus);
  const appStatus = useSelector(getStatus);
  const statusListStatus = useSelector(getStatusListStatus);
  const appValueStatus = useSelector(getValueStatus);

  const [unlockIds, setUnlockIds] = useState(null);
  const [unlockModal, setUnlockModal] = useState(false);
  const applicationStatus = useSelector(getApplicationStatus);

  const [closeWarningModal, setCloseWarningModal] = useState(false);

  const [closeUnlockType, setCloseUnlockType] = useState(null);
  const closeCloseWarningModal = () => {
    setCloseWarningModal(false);
  };

  const openUnlockModal = (id) => {
    setUnlockIds({
      programId: id.programId,
      appId: id.appId,
      propertyId: id.propertyId,
      nextStatus: id.nextStatus,
    });
    setUnlockModal(true);
  };
  const closeUnlockModal = () => {
    setUnlockModal(false);
  };

  const openCloseWarningModal = (payload) => {
    setCloseUnlockType(payload);
    setCloseWarningModal(true);
  };

  const closeUnlockFunction = () => {
    if (closeUnlockType === "read-write") {
      closeUnlockModal();
    } else {
      appModalController.closeAppModal();
    }
  };

  const [appModalController, setAppModalController] = useState({
    modalCustomerId: null,
    modalAppId: null,
    appModal: false,
    openAppModal: (payload) => {
      setUnlockIds({ programId: payload.programId, appId: payload.appId });
      setAppModalController((prev) => ({
        ...prev,
        appModal: true,
        modalCustomerId: payload.customerId,
        modalAppId: payload.appId,
      }));
    },
    closeAppModal: () => {
      setAppModalController((prev) => ({
        ...prev,
        appModal: false,
      }));
    },
  });
  useEffect(() => {
    // applicationStatus === "idle" && dispatch(fetchApplications());
    uploadStatus === "idle" && dispatch(fetchUploads());
    propertyStatus === "idle" && dispatch(fetchProperties());
    fieldStatus === "idle" && dispatch(fetchFields());
    appStatus === "idle" && dispatch(fetchApplicationStatus());
    statusListStatus === "idle" && dispatch(fetchStatusList());
    appValueStatus === "idle" && dispatch(fetchApplicationValues());
  }, [
    applicationStatus,
    uploadStatus,
    propertyStatus,
    fieldStatus,
    appStatus,
    statusListStatus,
    appValueStatus,
    dispatch,
  ]);

  return (
    <div
      className={` bg-white dark:bg-gray-900 transistion duration-200 font-sans dark:text-gray-50 p-8 ${
        props.sidebarOpen ? "ml-[15rem]" : "ml-[4rem]"
      }`}
    >
      <div className="main-content overflow-auto">
        <div className="p-8">
          {appModalController.appModal && (
            <CustomerUnlockApplicationModal
              props={{
                closeUnlockModal: appModalController.closeAppModal,
                openWarningModal: openCloseWarningModal,
                programs: props.programs,
                uploads: uploadList,
                fields: fieldList,
                properties: propertyList,
                Ids: unlockIds,
                type: "read-only",
              }}
            />
          )}
          {/* make sure correct programId is being passed */}
          {unlockModal && (
            <CustomerUnlockApplicationModal
              props={{
                closeUnlockModal: closeUnlockModal,
                openWarningModal: openCloseWarningModal,
                programs: props.programs,
                uploads: uploadList,
                fields: fieldList,
                properties: propertyList,
                Ids: unlockIds,
                type: "read-write",
              }}
            />
          )}
          {closeWarningModal && (
            <CloseWarningModal
              props={{
                closeWarningModal: closeCloseWarningModal,
                closeAppModal: closeUnlockFunction,
              }}
            />
          )}
          <h2 className="w-full rounded-t tracking-wide text-gray-900 mb-8 font-medium text-lg">
            My Applications
          </h2>
          <div>
            {appModalController && (
              <CustomerApplicationTable
                props={{
                  modalController: appModalController,
                  openUnlockModal: openUnlockModal,
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyApplications;
