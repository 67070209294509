// TODO: Rename this file when the other application type is no longer in the system

import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import moment from "moment";

import { FcAlarmClock } from "react-icons/fc";

const Application = ({ props }) => {
  const [alert, setAlert] = useState(false);
  useEffect(() => {
    if (props.app?.statusObj?.daysBeforeAlert && props.app?.statusDate) {
      let date = new Date(props.app?.statusDate);
      let currentDate = new Date();
      date.setDate(date.getDate() + props.app?.statusObj?.daysBeforeAlert);

      date <= currentDate ? setAlert(true) : setAlert(false);
    }
  }, [props.app?.statusObj?.daysBeforeAlert, props.app?.statusDate]);
  const [manualAddress, setManualAddress] = useState(false);

  useEffect(() => {
    if (props.app.address === "") {
      setManualAddress(true);
    }
  }, [props]);
  return (
    <tr
      onClick={() => {
        console.log("Apps: ", props.app);

        if (props.app.customerId) {
          // customer stuff. need to figure out
          props.modalController.openAppModal({
            customerId: props.app.customerId,
            appId: props.app.id,
            programId: props.app.programId,
            property: props.app.propertyId,
            app: props.app,
          });
        } else {
          props.modalController.openAppModal({
            customerId: 0,
            appId: props.app.id,
            programId: props.app.programId,
            property: props.app.propertyId,
            app: props.app,
          });
        }
      }}
      className={`rounded-lg dark:text-white text-black text-start hover:bg-blue-50 cursor-pointer `}
    >
      {/* {appData.appStatus && ( */}
      {props.view === "app" && (
        <>
          <td className="p-3 text-sm whitespace-nowrap cursor-pointer flex justify-center items-center gap-2">
            {/* {appData.appStatus.adminRespond && <FcAlarmClock size="14" />} */}
            <a className="underline text-blue-reef">{props.app.id}</a>
            {/* ID */}
          </td>
          <td className="p-3">
            {props.app.date ? (
              <Moment format="MM/DD/YYYY">{moment.utc(props.app.date)}</Moment>
            ) : (
              ""
            )}
          </td>
          {/* <td className="p-3 text-sm whitespace-nowrap">
            {manualAddress !== true
              ? props.app.address
              : `${props.app?.propertyId?.mfcStreetName?.trim()}, ${props.app?.propertyId?.city?.trim()} ${props.app?.propertyId?.postalCode?.trim()}`}
          </td> */}
          <td className="p-3 text-sm whitespace-nowrap">{props.app.address}</td>

          <td className="p-3 text-sm whitespace-nowrap">{props.app.name}</td>
          <td className="p-3 text-sm whitespace-nowrap">
            {props.app.programName}
          </td>

          <td className="p-3 text-sm whitespace-nowrap">{props.app.status}</td>
          <td className="p-3 text-sm whitespace-nowrap">
            {props.app.currentStatus}
          </td>
          <td className="p-3">
            {props.app.statusDate ? (
              <Moment format="MM/DD/YYYY">{props.app.statusDate}</Moment>
            ) : (
              ""
            )}
            {/* created at */}
          </td>
          <td className="p-3 text-sm whitespace-nowrap">
            {props.app.assignedTo}
          </td>
          <td className="flex justify-start p-3">
            <td className="flex justify-start p-3">
              {alert === true && <FcAlarmClock />}
            </td>
          </td>
        </>
      )}

      {props.view === "property" && (
        <>
          <td className="text-sm whitespace-nowrap cursor-pointer flex justify-center items-center gap-2">
            <a className="underline text-blue-reef mt-4">{props.app.id}</a>{" "}
          </td>
          <td className="p-3">
            {props.app.date ? (
              <Moment format="MM/DD/YYYY">{props.app.date}</Moment>
            ) : (
              ""
            )}
          </td>
          <td className="p-3 text-sm whitespace-nowrap">{props.app.address}</td>
          <td className="p-3 text-sm whitespace-nowrap">{props.app.name}</td>
          <td className="p-3 text-sm whitespace-nowrap">
            {props.app.programName}
          </td>
          <td className="p-3 text-sm whitespace-nowrap">
            {props.app.waterSavings || 0}
          </td>
          <td className="p-3 text-sm whitespace-nowrap">
            {props.app.cost || 0}
          </td>
          <td className="p-3 text-sm whitespace-nowrap">
            {props.app.currentStatus}
          </td>
        </>
      )}

      {props.view != "app" && props.view != "property" && (
        <>
          <td className="p-3 py-1 text-sm whitespace-nowrap cursor-pointer flex justify-center items-center gap-2">
            <a className="underline text-blue-reef">{props.app.id}</a>{" "}
          </td>
          <td className="p-3">
            {props.app.date ? (
              <Moment format="MM/DD/YYYY">{props.app.date}</Moment>
            ) : (
              ""
            )}
          </td>
          <td className="p-3 text-sm whitespace-nowrap">{props.app.name}</td>
          <td className="p-3 text-sm whitespace-nowrap">
            {props.app.programName}
          </td>
          <td className="p-3 text-sm whitespace-nowrap">{props.app.status}</td>
          <td className="p-3 text-sm whitespace-nowrap">
            {props.app.assignedTo}
          </td>
          <td className="p-3 text-sm whitespace-nowrap">
            {props.app.currentStatus}
          </td>
          <td className="p-3">
            {" "}
            {props.app.statusDate ? (
              <Moment format="MM/DD/YYYY">{props.app.statusDate}</Moment>
            ) : (
              ""
            )}
          </td>
          <td className="flex justify-start p-3">
            <FcAlarmClock />
          </td>
        </>
      )}
    </tr>
  );
};

export default Application;
