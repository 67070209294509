import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Header from "./Header";
import Application from "./ApplicationFixed";
import AppModal from "./UpdateApplicationModal";
import ImportReport from "./ImportReport";
import ApplicationTableFilterModal from "./ApplicationTableFilterModal";
import { formatFullAddress } from "../../utils/formatAddress";
import { selectAllProperties } from "../../features/properties";
import { selectAllValues } from "../../features/applicationValue";
import { selectAllPrograms } from "../../features/program";
import { selectAllFields } from "../../features/programField";
import { selectAllCustomers } from "../../features/customer";
import { selectStatusList, getStatusFilterNames } from "../../features/status";
import { selectStatusLogs } from "../../features/status";
import { selectAllUsers } from "../../features/user";

import {
  paginateByStatusName,
  selectApplicationCount,
  searchByFilterText,
  searchByAssignmentId,
  fetchApplicationChunks,
  exportApplicationByProgramId,
  fetchApplicationCountByProgram,
} from "../../features/application";
import { useDispatch, useSelector } from "react-redux";
import WaterSavingsLog from "./WaterSavingsLog";
import CloseWarningModal from "../Misc/CloseWarningModal";
import { selectAllStatus } from "../../features/applicationStatus";
import ExportModal from "./ExportModal";
import Paginate from "../Misc/Paginate";

import * as XLSX from "xlsx";

import {
  TiArrowSortedDown,
  TiArrowSortedUp,
  TiArrowUnsorted,
} from "react-icons/ti";

const axios = require("axios").default;
axios.defaults.headers.common["Client"] = "ACWD";

export const ApplicationTable = ({ props }) => {
  const dispatch = useDispatch();

  const properties = useSelector(selectAllProperties);
  const appValues = useSelector(selectAllValues);
  const fieldList = useSelector(selectAllFields);
  const applicationCount = useSelector(selectApplicationCount);
  // const initialApplications = useSelector(selectInitialApplications);
  // const customers = useSelector(selectAllCustomers);

  // useEffect(() => {
  //   console.log("application-table-customers: ", customers);
  // }, [customers]);

  // const getAccount = async (e) => {
  //   try {
  //     const url =
  //       process.env.REACT_APP_PRODUCTION === "true"
  //         ? `https://expressproxy-prod.azurewebsites.net/account/fetch-by-id`
  //         : `http://localhost:3000/account/fetch-by-id`;

  //     const res = await axios.post(
  //       url,
  //       { id: 40003185 },
  //       {
  //         headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  //       }
  //     );
  //     const updatedBudget = res.data.data;
  //     console.log(updatedBudget);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  const [reviewNotes, setReviewNotes] = useState(-1);
  const getReviewNotes = async () => {
    try {
      const url =
        process.env.REACT_APP_PRODUCTION === "true"
          ? `https://expressproxy-prod.azurewebsites.net/application/review-note/fetch-all`
          : `http://localhost:3000/application/review-note/fetch-all`;
      const res = await axios.post(
        url,
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const review_notes = res.data.data;
      review_notes.sort((a, b) => {
        return b.id - a.id;
      });

      setReviewNotes(review_notes);
    } catch (err) {
      console.log(err);
      setReviewNotes([]);
    }
  };

  // useEffect(() => { //turned off
  //   getAccount();
  // }, []);

  useEffect(() => {
    getReviewNotes();
  }, []);

  const [numberFilterList, setNumberFilterList] = useState(null);
  const [nameFilterList, setNameFilterList] = useState(null);

  useEffect(() => {
    const getStatusFilters = async () => {
      let res = await dispatch(getStatusFilterNames({ column: "number" }));
      const numberObj = res.payload.map((x) => {
        return { status: x };
      });
      setNumberFilterList(numberObj);

      res = await dispatch(getStatusFilterNames({ column: "name" }));
      const nameObj = res.payload.map((x) => {
        return { currentStatus: x };
      });
      setNameFilterList(nameObj);
    };

    getStatusFilters();
  }, []);

  //application modal
  const [modalController, setModalController] = useState(
    {
      appModal: false,
      modalCustomerId: null,
      modalAppId: null,
      openAppModal: (payload) => {
        setModalController((prev) => ({
          ...prev,
          appModal: true,
          modalCustomerId: payload?.customerId,
          modalAppId: payload.appId,
          programId: payload.programId,
          property: payload.property,
          app: payload.app,
        }));
      },
      closeAppModal: () => {
        setModalController((prev) => ({
          ...prev,
          appModal: false,
        }));
      },
      closeWarning: false,
      openWarningModal: () => {
        setModalController((prev) => ({
          ...prev,
          closeWarning: true,
        }));
      },
      closeWarningModal: () => {
        setModalController((prev) => ({
          ...prev,
          closeWarning: false,
        }));
      },
    },
    []
  );

  const [appData, setAppData] = useState(
    {
      customerList: useSelector(selectAllCustomers),
      customer: null,
      setCustomer: (customer) => {
        setAppData((prev) => ({
          ...prev,
          customer: customer,
        }));
      },
      values: useSelector(selectAllValues),
      appValues: null,
      setAppValues: (values) => {
        setAppData((prev) => ({
          ...prev,
          appValues: values,
        }));
      },
      properties: useSelector(selectAllProperties),
      property: null,
      setProperty: (property) => {
        setAppData((prev) => ({
          ...prev,
          property: property,
        }));
      },
      programs: useSelector(selectAllPrograms),
      program: null,
      setProgram: (program) => {
        setAppData((prev) => ({
          ...prev,
          program: program,
        }));
      },
      fields: useSelector(selectAllFields),
      appStatus: null,
      setAppStatus: (status) => {
        setAppData((prev) => ({
          ...prev,
          appStatus: status,
        }));
      },
      statusList: useSelector(selectStatusList),
      allStatus: useSelector(selectAllStatus),
      statusObj: null,
      statusLogs: useSelector(selectStatusLogs),
      users: useSelector(selectAllUsers),
      setStatus: (status) => {
        setAppData((prev) => ({
          ...prev,
          statusObj: status,
        }));
      },
      // applications: useSelector(selectAllApplications),
    },
    []
  );

  const [updateState, setUpdateState] = useState(0);
  const update = () => {
    setUpdateState(updateState + 1);
  };

  // filtering
  const [filterType, setFilterType] = useState("");
  const [filterText, setFilterText] = useState("");

  const [filterOptions, setFilterOptions] = useState([]);

  const [sortOptions, setSortOptions] = useState({});

  const [loadingApps, setLoadingApps] = useState(false);
  const [filterCount, setFilterCount] = useState(null);

  const filterByCurrentStatus = async (column, sort) => {
    try {
      console.log("filter-by-current-status");
      // setLoadingApps(true);
      if (props.view === "property") {
        //temp fix check why undefined values are coming in from array
        console.log("applications-property: ", props.applications);
        const filteredArray = props.applications.filter(
          (element) => element !== undefined
        );

        configureTargetApps(filteredArray);
      } else {
        console.log(
          "filter-options, on filter by current status: ",
          filterOptions
        );

        console.log("FILTER-OPTIONS", filterOptions);
        let programFilters = [];
        let statusFilters = [];
        let currentStatus = [];
        let assignedTo = [];
        if (filterOptions?.programName?.length > 0) {
          programFilters = [...filterOptions.programName];
        }
        if (filterOptions?.status?.length > 0) {
          statusFilters = [...filterOptions.status];
        }
        if (filterOptions?.currentStatus?.length > 0) {
          currentStatus = [...filterOptions.currentStatus];
        }
        if (filterOptions?.assignedTo?.length > 0) {
          assignedTo = [...filterOptions.assignedTo];
        }

        const filters = {
          programName: programFilters,
          status: statusFilters,
          currentStatus,
          assignedTo,
        };

        const count = await dispatch(
          fetchApplicationCountByProgram({ programFilters: programFilters })
        );

        if (count.payload) {
          setFilterCount(count.payload[0].applicationCount);
        }
        const res = await dispatch(
          paginateByStatusName({
            pageSize: 50,
            page: currentPage,
            orderBy: sort,
            orderVar: column,
            filters,
          })
        );

        console.log("paginate-by-status-name: ", res);

        configureTargetApps(res.payload);
      }
      // setLoadingApps(false);
    } catch (err) {
      console.log(err);
    }
  };

  const searchFilterText = async (column, sort) => {
    try {
      setLoadingApps(true);
      if (props.view === "property") {
        configureTargetApps(props.applications);
      } else {
        let programFilters = [];
        let statusFilters = [];
        let currentStatus = [];
        let assignedTo = [];
        if (filterOptions?.programName?.length > 0) {
          programFilters = [...filterOptions.programName];
        }
        if (filterOptions?.status?.length > 0) {
          statusFilters = [...filterOptions.status];
        }
        if (filterOptions?.currentStatus?.length > 0) {
          currentStatus = [...filterOptions.currentStatus];
        }
        if (filterOptions?.assignedTo?.length > 0) {
          assignedTo = [...filterOptions.assignedTo];
        }

        const filters = {
          programName: programFilters,
          status: statusFilters,
          currentStatus,
          assignedTo,
        };
        const res = await dispatch(
          searchByFilterText({
            pageSize: 50,
            page: currentPage,
            filterType: filterType,
            filterText: filterText,
            filters,
          })
        );
        configureTargetApps(res.payload);
        setFilterCount(res.payload.length);
      }
      setLoadingApps(false);
    } catch (err) {
      console.log(err);
    }
  };

  const showMyAssignments = async () => {
    try {
      setLoadingApps(true);
      if (props.view === "property") {
        configureTargetApps(props.applications);
      } else {
        const res = await dispatch(
          searchByAssignmentId({
            pageSize: 50,
            page: currentPage,
            filterType: filterType,
            customerId: localStorage.getItem("user_id"),
          })
        );
        console.log("assignment applications: ", res.payload);
        configureTargetApps(res.payload);

        setFilterCount(res.payload.length);
      }
      setLoadingApps(false);
    } catch (err) {
      console.log(err);
    }
  };

  // const loggedUser = useSelector(getLoggedUser);

  // TODO: grab current user's name instead of
  // const showMyAssignments = () => {
  //   let input = loggedUser.firstName + " " + loggedUser.lastName;
  //   setFilterText("");
  //   setFilterType("");
  //   setFilterOptions({ assignedTo: [input] });
  //   setSortOptions({});
  //   console.log("input for loggeduser", input);
  // };

  //pagination
  const [targetApplications, setTargetApplications] = useState([]);
  // potentially need to throw this into a useEffect
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPostIndex, setLastPostIndex] = useState(currentPage * 50);
  const [firstPostIndex, setFirstPostIndex] = useState(lastPostIndex * 50);

  useEffect(() => {
    const lpi = currentPage * 50;
    setLastPostIndex(lpi);
    const fpi = lpi - 50;
    setFirstPostIndex(fpi);
  }, [currentPage]);

  const [showFilterModal, setShowFilterModal] = useState({});
  const [showFilterData, setShowFilterData] = useState([]);

  const determineShowFilterData = (payload) => {
    if (payload === "programName") {
      setShowFilterData([...appData.programs]);
    } else if (payload === "status") {
      setShowFilterData([...numberFilterList]);
    } else if (payload === "currentStatus") {
      setShowFilterData([...nameFilterList]);
    } else if (payload === "assignedTo") {
      const temp = appData.users.map((x) => {
        return {
          assignedTo: `${x.firstName} ${x.lastName}`,
        };
      });
      setShowFilterData([...temp]);
    }
  };

  // const applicationsPerPage = 500;
  // const pagesVisited = pageNumber * applicationsPerPage;
  // const lastPostIndex = currentPage * 50;
  // const firstPostIndex = lastPostIndex - 50;
  const [propertyLookup, setPropertyLookup] = useState(new Map());
  const [customerLookup, setCustomerLookup] = useState(new Map());
  const [programLookup, setProgramLookup] = useState(new Map());
  const [statusListLookup, setStatusListLookup] = useState(new Map());
  const [lookupsReady, setLookupsReady] = useState(false);
  const [triggerRefresh, setTriggerRefresh] = useState(false);

  useEffect(() => {
    setPropertyLookup(new Map(properties?.map((prop) => [prop.id, prop])));
    setCustomerLookup(
      new Map(appData.customerList?.map((cust) => [cust.id, cust]))
    );
    setProgramLookup(new Map(appData.programs?.map((prog) => [prog.id, prog])));
    setStatusListLookup(
      new Map(
        appData.statusList?.map((status) => [
          `${status.number}-${status.programId}`,
          status,
        ])
      )
    );
  }, [properties, appData.customerList, appData.programs, appData.statusList]);

  useEffect(() => {
    if (
      propertyLookup.size > 0 &&
      customerLookup.size > 0 &&
      programLookup.size > 0 &&
      statusListLookup.size > 0
    ) {
      setLookupsReady(true);
    }
  }, [propertyLookup, customerLookup, programLookup, statusListLookup]);

  useEffect(() => {
    if (lookupsReady) {
      if (sortOptions.column) {
        filterByCurrentStatus(sortOptions.column, sortOptions.sort);
      } else if (
        !sortOptions.column &&
        filterText.length > 0 &&
        filterType.length > 0
      ) {
        searchFilterText();
      } else {
        filterByCurrentStatus("id", "desc");
      }

      if (triggerRefresh) setTriggerRefresh(false);
    }
  }, [
    lookupsReady,
    filterOptions,
    sortOptions,
    filterText,
    filterType,
    currentPage,
    triggerRefresh,
  ]);

  // useEffect(() => {
  //   console.log("initial-applications: ", initialApplications);

  //   configureTargetApps(initialApplications);
  // }, [
  //   initialApplications,
  //   propertyLookup,
  //   customerLookup,
  //   programLookup,
  //   statusListLookup,
  // ]);

  // generates the array that we use to fill the table. handles filtering in here as well.
  const configureTargetApps = async (applications) => {
    // setLoadingApps(true);
    // Create lookup tables for faster access
    // const propertyLookup = new Map(properties?.map((prop) => [prop.id, prop]));
    // const customerLookup = new Map(
    //   appData.customerList?.map((cust) => [cust.id, cust])
    // );
    // const programLookup = new Map(
    //   appData.programs?.map((prog) => [prog.id, prog])
    // );
    // const statusListLookup = new Map(
    //   appData.statusList?.map((status) => [
    //     `${status.number}-${status.programId}`,
    //     status,
    //   ])
    // );

    console.log("configure: ", applications);
    let tmpArray = [];
    applications?.forEach((application) => {
      const statusLog = //most recent status log
        application?.statusLog && application?.statusLog?.length > 0
          ? application?.statusLog[0]
          : null;
      const tmpProp = propertyLookup.get(application?.propertyId);
      const tmpCustomer = customerLookup.get(application?.customerId);
      const tmpProgram = programLookup.get(application?.programId);
      const tmpStatusId = statusLog?.statusNumber;

      const tmpStatus = statusListLookup.get(
        `${tmpStatusId}-${application?.programId}`
      );
      console.log("tmpStatus: ", tmpStatus);
      let tmpUser = statusLog?.staffAssignmentId
        ? appData?.users?.filter(
            (y) => y?.id === statusLog?.staffAssignmentId
          )[0]
        : "";
      let tmpRow = {
        id: application?.id || "",
        customerId: application.customerId || "",
        propertyId: tmpProp,
        cost: application?.cost,
        programId: application?.programId,
        waterSavings: application?.waterSavings,
        date: application?.createdAt || "",
        address: tmpProp?.location
          ? formatFullAddress([
              tmpProp?.location?.houseNo,
              tmpProp?.location?.streetPfxDir,
              tmpProp?.location?.streetNm,
              tmpProp?.location?.streetNmSfx,
              tmpProp?.location?.streeSfxDir,
              tmpProp?.location?.secAddrId,
              tmpProp?.location?.secAddrRange,
              tmpProp?.location?.city,
              tmpProp?.location?.provinceCd,
              tmpProp?.location?.postalCode,
            ])
          : formatFullAddress([
              tmpProp?.mfcStreetNo,
              tmpProp?.streetPfxDir,
              tmpProp?.mfcStreetName,
              tmpProp?.streetNmSfx,
              tmpProp?.streetSfxDir,
              tmpProp?.secAddrId,
              tmpProp?.secAddrRange,
              tmpProp?.city,
              tmpProp?.provinceCd,
              tmpProp?.postalCode,
            ]),
        name: tmpCustomer
          ? tmpCustomer?.firstName + " " + tmpCustomer?.lastName
          : "",
        programName: tmpProgram?.programName.trim() || "",
        grantFundedAmount: application?.grantFundedAmount,
        statusObj: tmpStatus,
        status: tmpStatus?.number.trim() || "",
        currentStatus: tmpStatus?.name.trim() || "",
        statusDate: statusLog?.createdAt || "",
        assignedTo: tmpUser
          ? (tmpUser?.firstName + " " + tmpUser?.lastName).trim()
          : "",
      };
      tmpArray.push(tmpRow);
    });

    // Perform the filtering based off the modal selections
    // if (Object.keys(filterOptions).length) {
    //   let cols = Object.keys(filterOptions);
    //   cols?.map((col) => {
    //     console.log(col, filterOptions[col]);
    //     tmpArray = tmpArray?.filter((x) =>
    //       filterOptions[col].includes(x[col]) ? 1 : 0
    //     );
    //   });
    // }

    // // // Perform the sorting based off the modal selections
    // if (sortOptions.column) {
    //   if (sortOptions.sort === "asc") {
    //     tmpArray = tmpArray.sort((a, b) => {
    //       return a[sortOptions.column] > b[sortOptions.column] ? 1 : -1;
    //     });
    //   } else {
    //     tmpArray = tmpArray.sort((a, b) => {
    //       return a[sortOptions.column] < b[sortOptions.column] ? 1 : -1;
    //     });
    //   }
    // } else {
    //   tmpArray.sort((a, b) => b.id - a.id);
    // }

    if (filterType && filterText && props.view === "property") {
      let filterBy = "";
      if (filterType === "Name") filterBy = "name";
      else if (filterType === "Program") filterBy = "programName";
      else if (filterType === "Property") filterBy = "address";
      const filteredApps = tmpArray.filter((x) => {
        return x[filterBy].toLowerCase().includes(filterText.toLowerCase());
      });
      setTargetApplications(filteredApps);
    } else {
      console.log("before-set: ", tmpArray);
      setTargetApplications(tmpArray);
    }
    // setLoadingApps(false);
  };

  // run the filtering with no actual filtering

  // useEffect(() => {
  //   configureTargetApps();
  // }, [props.applications, properties]);

  // run the filtering on every change to filter text

  // useEffect(() => {
  //   configureTargetApps(props.applications);
  // }, []);

  // const applicationsPerPage = 18;
  // const pagesVisited = pageNumber * applicationsPerPage;

  const genSortButtons = (input) => {
    return (
      <>
        {sortOptions.column != input && (
          <TiArrowUnsorted
            onClick={(e) => {
              setSortOptions({ column: input, sort: "desc" });
            }}
            className="flex flex-col ml-auto cursor-pointer"
          />
        )}
        {sortOptions.column == input && sortOptions.sort == "asc" && (
          <TiArrowSortedUp
            onClick={(e) => {
              setSortOptions({ column: input, sort: "desc" });
            }}
            className="flex flex-col ml-auto fill-red-400 cursor-pointer"
          />
        )}
        {sortOptions.column == input && sortOptions.sort != "asc" && (
          <TiArrowSortedDown
            onClick={(e) => {
              setSortOptions({ column: input, sort: "asc" });
            }}
            className="flex flex-col ml-auto fill-red-400 cursor-pointer"
          />
        )}
      </>
    );
  };

  const displayProperties = targetApplications
    .filter((x) => {
      return x.status || x.customerId === "";
    })
    .map((app, index) => {
      return (
        <Application
          props={{
            app: app,
            index: index,
            modalController: modalController,
            properties: properties,
            updateState: updateState,
            view: props.view,
          }}
          index={index}
          key={index}
        />
      );
    });

  const [waterSavingsController, setWaterSavingsController] = useState({
    waterSavingsModal: false,
    openWaterSavingsModal: () => {
      setWaterSavingsController((prev) => ({
        ...prev,
        waterSavingsModal: true,
      }));
    },
    closeWaterSavingsModal: () => {
      setWaterSavingsController((prev) => ({
        ...prev,
        waterSavingsModal: false,
      }));
    },
  });

  //import report
  const [importModal, setImportModal] = useState(null);
  const [report, setReport] = useState([]);
  const openImportModal = (payload) => {
    setReport(payload);
    setImportModal(true);
  };

  const closeImportModal = () => {
    setImportModal(false);
  };

  const [applicationTotal, setApplicationTotal] = useState(applicationCount);

  useEffect(() => {
    filterCount && setApplicationTotal(filterCount);
  }, [filterCount]);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [exportModal, setExportModal] = useState(false);
  const openExportModal = () => {
    setExportModal(true);
  };
  const closeExportModal = () => {
    setExportModal(false);
    setStartDate(null);
    setEndDate(null);
  };

  const applyDateFilter = (apps) => {
    let completedApps = apps.filter((x) => {
      return x["Current Status"].toLowerCase().trim() === "complete";
    });

    if (startDate && !endDate) {
      //filter taraget applications with status = complete && status date >= startDate
      const startDateConv = new Date(startDate);

      let filteredApps = completedApps.filter((x) => {
        // let statusDate = new Date(x["Status Date"]);
        let dateParts = x["Status Date"].split("-");

        let statusDate = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
        console.log("status date: ", x["Status Date"]);
        console.log("status date2: ", statusDate);

        return statusDate >= startDateConv;
      });

      return filteredApps;
    } else if (endDate && !startDate) {
      //filter taraget applications with status = complete && status date<= endDate
      const endDateConv = new Date(endDate);

      let filteredApps = completedApps.filter((x) => {
        let dateParts = x["Status Date"].split("-");

        let statusDate = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
        return statusDate <= endDateConv;
      });

      return filteredApps;
    } else if (startDate && endDate) {
      //filter taraget applications with status = complete && status date<= endDate && status date >= startDate
      const startDateConv = new Date(startDate);

      const endDateConv = new Date(endDate);

      let filteredApps = completedApps.filter((x) => {
        let dateParts = x["Status Date"].split("-");

        let statusDate = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
        return statusDate <= endDateConv && statusDate >= startDateConv;
      });

      return filteredApps;
    }
    return apps;
  };

  // const exportExcel = () => {
  //   if (reviewNotes == -1) {
  //     setTimeout(() => {
  //       exportExcel();
  //     }, 1000);
  //     return false;
  //   }

  //   console.log("taraget applications: ", targetApplications);
  //   // create the array of data to export //fix export
  //   let tempFinalData = targetApplications?.map((x) => {
  //     let tmpApp = appData?.applications?.filter((y) => y?.id === x?.id)[0];
  //     console.log("Temp-App: ", tmpApp);
  //     let tmpNote = reviewNotes?.filter((y) => y?.applicationId === x?.id)[0];
  //     let accNo =
  //       x?.propertyId?.customerProperty.length > 0
  //         ? x?.propertyId?.customerProperty[0].accountNo
  //         : "";

  //     return {
  //       ID: x.id,
  //       "Application Submission Date": x.date.split("T")[0],
  //       "Property Address": x.address,
  //       "Applicant Name": x.name,
  //       "Program Name": x.programName,
  //       "Status #": x.status,
  //       "Current Status": x.currentStatus,
  //       "Status Date": x.statusDate.split("T")[0],
  //       "Assigned To": x.assignedTo,
  //       Cost: tmpApp?.cost || "", // inside of the application table
  //       "Water Savings (gals per year)": x.waterSavings || "",
  //       "Grant Funded Amount": tmpApp?.grantFundedAmount || "", // inside of the application table
  //       "Internal Review Notes": tmpNote?.value, // inside of the ReviewNote table. left join off of application id & select max time
  //       "Property ID": tmpApp?.propertyId || "", // inside of the application table
  //       "Customer ID": x.customerId, // inside of the application table
  //       "Account ID": accNo || "", // inside of the application table
  //     };
  //   });
  //   ///
  //   let finalData = applyDateFilter(tempFinalData);
  //   ////
  //   //-- writing the excel file --//
  //   const workBook = XLSX.utils.book_new();

  //   const workSheet = XLSX.utils.json_to_sheet(finalData);
  //   XLSX.utils.book_append_sheet(workBook, workSheet, "Temp");

  //   XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
  //   XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

  //   XLSX.writeFile(workBook, "Application_Export-" + Date.now() + ".xlsx");

  //   //console.log("Time: " + time);
  // };

  const [exportLoading, setExportLoading] = useState(false);

  const handleExportClick = async () => {
    const chunkSize = 1000;
    let offset = 0;
    let allApplications = [];
    setExportLoading(true);
    while (true) {
      const res = await dispatch(
        fetchApplicationChunks({ limit: chunkSize, offset: offset })
      );
      const chunk = res.payload;
      if (chunk.length === 0) break; // Stop fetching if no more data

      allApplications = allApplications.concat(chunk);
      offset += chunkSize;
    }

    exportExcel(allApplications);
  };

  const exportExcel = (apps) => {
    if (reviewNotes == -1) {
      setTimeout(() => {
        exportExcel();
      }, 1000);
      return false;
    }

    console.log("taraget applications123: ", apps);
    // create the array of data to export //fix export
    let tempFinalData = apps?.map((x) => {
      let tmpUser = x?.statusLog?.staffAssignmentId
        ? appData?.users?.filter(
            (y) => y?.id === x.statusLog?.staffAssignmentId
          )[0]
        : null;
      const statusId = x?.statusLog?.statusNumber;
      const statusName = x?.statusLog?.statusName;
      console.log("statusName: ", statusName);

      const status = statusId
        ? statusListLookup.get(`${statusId}-${x?.programId}`)
        : null;
      const statusNumber = status?.number ? status.number : "";
      // const statusName = status?.name ? status.name : "";
      let tmpNote = reviewNotes?.filter((y) => y?.applicationId === x?.id)[0];

      if (x.id === 254) {
        console.log("App: ", x);
        console.log("statusId: ", statusId);
        console.log("status-log: ", x.statusLog);
        console.log("status-name: ", statusName);
        console.log("status: ", status);
        console.log("statusListLookup: ", statusListLookup);
        console.log("status-list: ", appData.statusList);
        console.log(
          "status-show: ",
          appData.statusList.filter((x) => {
            return x.programId === 132;
          })
        );
      }

      return {
        ID: x.id,
        "Application Submission Date": x.createdAt.split("T")[0],
        "Property Address": formatFullAddress([
          x.property?.mfcStreetNo,
          x.property?.streetPfxDir,
          x.property?.mfcStreetName,
          x.property?.streetNmSfx,
          x.property?.streetSfxDir,
          x.property?.secAddrId,
          x.property?.secAddrRange,
          x.property?.city,
          x.property?.provinceCd,
          x.property?.postalCode,
        ]),
        "Applicant Name": `${x.customer?.firstName || ""} ${
          x.customer?.lastName || ""
        }`,
        "Program Name": x.program?.programName,
        "Status #": statusNumber,
        "Current Status": statusName || "",
        "Status Date": x?.statusLog?.createdAt?.split("T")[0] || "",
        "Assigned To": tmpUser
          ? (tmpUser?.firstName + " " + tmpUser?.lastName).trim()
          : "",
        Cost: x.cost || "",
        "Water Savings (gals per year)": x.waterSavings || "",
        "Grant Funded Amount": x?.grantFundedAmount || "", // inside of the application table
        "Internal Review Notes": tmpNote?.value || "", // inside of the ReviewNote table. left join off of application id & select max time
        "Property ID": x?.propertyId || "", // inside of the application table
        "Customer ID": x?.customerId || "", // inside of the application table
        "Account ID": x.accountNo || "", // inside of the application table
        "LOCATION ID": x.property?.locationNo || "", // inside of the application table
        "LOCATION CLASS": x.property?.locationClass || "",
      };
    });
    ///

    console.log("temp-final-data: ", tempFinalData);
    let finalData = applyDateFilter(tempFinalData);
    setExportLoading(false);

    const workBook = XLSX.utils.book_new();

    const workSheet = XLSX.utils.json_to_sheet(finalData);
    XLSX.utils.book_append_sheet(workBook, workSheet, "Temp");

    XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

    XLSX.writeFile(workBook, "Application_Export-" + Date.now() + ".xlsx");

    // console.log("Time: " + time);
  };

  const exportAppDetails = async (id) => {
    // catch if reviewNotes hasnt loaded yet
    setExportLoading(true);

    if (reviewNotes == -1) {
      setTimeout(() => {
        exportExcel();
      }, 1000);
      return false;
    }

    const res = await dispatch(exportApplicationByProgramId({ programId: id }));
    let exportApps = res.payload;
    let selectedApps = exportApps?.map((x) => {
      let tmpUser = x?.statusLog?.staffAssignmentId
        ? appData?.users?.filter(
            (y) => y?.id === x?.statusLog?.staffAssignmentId
          )[0]
        : null;
      const statusId = x?.statusLog?.statusNumber;

      const status = statusId
        ? statusListLookup.get(`${statusId}-${x?.programId}`)
        : null;
      // const statusNumber = status?.number ? status.number : "";
      // const statusName = status?.name ? status.name : "";

      return {
        id: x.id,
        date: x.createdAt.split("T")[0],
        address: formatFullAddress([
          x.property?.mfcStreetNo,
          x.property?.streetPfxDir,
          x.property?.mfcStreetName,
          x.property?.streetNmSfx,
          x.property?.streetSfxDir,
          x.property?.secAddrId,
          x.property?.secAddrRange,
          x.property?.city,
          x.property?.provinceCd,
          x.property?.postalCode,
        ]),
        name: `${x.customer?.firstName || ""} ${x.customer?.lastName || ""}`,
        programName: x.program?.programName,
        programId: x?.programId,
        statusObj: status,
        status: x.statusLog?.statusNumber,
        currentStatus: x.statusLog?.statusName,
        statusDate: x?.statusLog?.createdAt?.split("T")[0] || "",
        assignedTo: tmpUser
          ? (tmpUser?.firstName + " " + tmpUser?.lastName).trim()
          : "",
        cost: x.cost || "",
        waterSavings: x.waterSavings || "",
        grantFundedAmount: x?.grantFundedAmount || "", // inside of the application table
        propertyId: x.property || "", // inside of the application table
        customerId: x?.customerId || "", // inside of the application table
        accountNo: x.accountNo || "",
        LOCATION_NO: x.property?.locationNo,
        LOCATION_CLASS: x.property?.locationClass,
      };
    });

    // console.log("target app programs: ", res);
    const selectedApps2 = targetApplications?.filter((x) => {
      return x.statusObj?.programId === id;
    });
    console.log("selectedApps1: ", selectedApps);
    console.log("selectedApps2: ", selectedApps2);

    let finalDataTemp = selectedApps?.map((x) => {
      const pfObj = {};
      console.log("fieldList: ", fieldList);
      fieldList
        ?.filter((f) => {
          return f.programId === x.programId && !f.deleted;
        })
        .sort((a, b) => {
          return a.applicationOrder - b.applicationOrder;
        })
        .forEach((pf, index) => {
          let addedVal = "";
          if (pf.fieldType === "CHECK") {
            if (pf.checkValues.length > 0) {
              pf.checkValues.forEach((cv, index) => {
                const cVal = appValues?.filter((av) => {
                  return av.applicationId === x.id && av.checkValueId === cv.id;
                });

                if (cVal[0]?.value === "true")
                  if (index === pf.checkValues.length - 1) {
                    addedVal += `${cv.value}`;
                  } else {
                    addedVal += `${cv.value}, `;
                  }
              });

              if (pf.checkOther) {
                const coVal = appValues?.filter((av) => {
                  return (
                    av.applicationId === x.id &&
                    av.checkValueId === null &&
                    av.fieldId === pf.id
                  );
                });
                if (coVal.length > 0) {
                  addedVal += `, ${coVal[0].value}`;
                }
              }
            }
          } else if (pf.fieldType === "LOOKUP") {
            addedVal = "";
          } else {
            const pfVal = appValues?.filter((av) => {
              return av.applicationId === x?.id && av.fieldId === pf?.id;
            });
            if (pfVal.length === 1) {
              addedVal = pfVal[0].value;
            } else if (pfVal.length > 1) {
              addedVal = pfVal.reduce((a, b) => {
                return a.createdAt > b.createdAt ? a : b;
              }).value;
            }
          }
          let pfObjKey = createCollisionKey(pf.fieldName, pfObj);
          pfObj[pfObjKey] = addedVal;
        });

      // let tmpApp = appData?.applications?.filter((y) => y?.id === x?.id)[0];
      let tmpNote = reviewNotes?.filter((y) => y?.applicationId === x?.id)[0];
      return {
        ID: x.id,
        "Application Submission Date": x.date.split("T")[0],
        "Property Address": x.address,
        "Applicant Name": x.name,
        "Program Name": x.programName,
        "Status #": x.status,
        "Current Status": x.currentStatus,
        "Status Date": x.statusDate.split("T")[0],
        "Assigned To": x.assignedTo,
        Cost: x.cost || "", // inside of the application table
        "Water Savings (gals per year)": x.waterSavings || "",
        "Grant Funded Amount": x.grantFundedAmount || "", // inside of the application table
        "Internal Review Notes": tmpNote?.value, // inside of the ReviewNote table. left join off of application id & select max time
        "Property ID": x.propertyId?.id || "", // inside of the application table
        "Customer ID": x?.customerId, // inside of the application table
        "Account ID": x.accountNo || "", // inside of the application table
        "LOCATION NO": x.propertyId?.locationNo || "",
        "LOCATION CLASS": x.propertyId?.locationClass || "",
        ...pfObj,
      };
    });
    console.log("finalDataTemp: ", finalDataTemp);
    let finalData = applyDateFilter(finalDataTemp);
    console.log("Final-Data: ", finalData);
    setExportLoading(false);

    const workBook = XLSX.utils.book_new();

    const workSheet = XLSX.utils.json_to_sheet(finalData);
    XLSX.utils.book_append_sheet(workBook, workSheet, "Temp");

    XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

    XLSX.writeFile(workBook, "Application_Export-" + Date.now() + ".xlsx");
  };

  const createCollisionKey = (key, obj) => {
    let searchKey = key;
    let index = 1;
    while (obj.hasOwnProperty(searchKey)) {
      let temp = searchKey.split("-")[0];
      searchKey = `${temp}-${index}`;
      index++;
    }

    return searchKey;
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className={`application-table bg-white dark:bg-gray-800 dark:text-white rounded duration-400 ease-in-out transition-spacing font-sans flex flex-col gap-4`}
    >
      {modalController.appModal && (
        <AppModal
          props={{
            modalController: modalController,
            waterSavingsController: waterSavingsController,
            setTriggerRefresh,
            update: update,
          }}
        />
      )}
      {waterSavingsController.waterSavingsModal && (
        <WaterSavingsLog
          props={{
            modalController: modalController,
            waterSavingsController: waterSavingsController,
          }}
        />
      )}
      {modalController.closeWarning && (
        <CloseWarningModal
          props={{
            closeWarningModal: modalController.closeWarningModal,
            closeAppModal: modalController.closeAppModal,
          }}
        />
      )}

      {exportModal && (
        <ExportModal
          props={{
            handleClose: closeExportModal,
            exportExcel: handleExportClick,
            exportAppDetails: exportAppDetails,
            startDate: startDate,
            endDate: endDate,
            setStartDate: setStartDate,
            setEndDate: setEndDate,
            exportLoading: exportLoading,
          }}
        />
      )}

      {props.view && (
        <Header
          props={{
            openImportModal: openImportModal,
            setFilterType: setFilterType,
            setFilterText: setFilterText,
            setFilterOptions: setFilterOptions,
            setSortOptions: setSortOptions,
            showMyAssignments: showMyAssignments,
            exportExcel: exportExcel,
            setExportModal: setExportModal,
            setTriggerRefresh,
            sortOptions: sortOptions,
            exportModal: exportModal,
            view: props.view,
          }}
        />
      )}
      {importModal && (
        <ImportReport
          props={{
            report: report,
            closeImportModal: closeImportModal,
            setTriggerRefresh,
          }}
        />
      )}
      <hr />
      {/* current column heads are hardcoded for sorting. need to find an elegant way to provide this to the component */}
      <div className="overflow-auto w-full text-white rounded border border-gray-100 mt-4 max-h-[50rem]">
        <table className=" font-sans shadow w-full">
          <thead className="border-b-2 border-gray-200 bg-wc-green sticky top-0">
            <tr>
              <th className="p-2 py-1 text-sm font-semibold tracking-wider text-start border-r border-gray-50 w-1/12">
                <span className="flex flex-col cursor-pointer">
                  Application ID
                </span>
                {genSortButtons("id")}
              </th>
              <th className="p-2 text-sm font-semibold tracking-wider text-start border-r border-gray-50 w-1/12">
                <span className="flex flex-col cursor-pointer">
                  Application Submission Date
                </span>
                {genSortButtons("createdAt")}
              </th>

              <th className="p-2 border-r border-gray-50 text-sm font-semibold tracking-wider text-start w-1/12">
                <span className="flex flex-col cursor-pointer">
                  Property Address
                </span>
                {genSortButtons("address")}
              </th>

              <th className="p-2 border-r border-gray-50 text-sm font-semibold tracking-wider text-start w-1/12">
                <span className="flex flex-col cursor-pointer">
                  Applicant Name
                </span>
                {genSortButtons("name")}
              </th>
              <th className="p-2 border-r border-gray-50 text-sm font-semibold tracking-wider text-start w-1/12">
                <span
                  className="flex flex-col cursor-pointer"
                  onClick={(e) => {
                    setShowFilterModal((prev) => ({
                      ...prev,
                      type: "programName",
                      show: 1,
                    }));

                    determineShowFilterData("programName");
                  }}
                >
                  Program Name
                </span>
                {genSortButtons("programName")}
              </th>
              {props.view === "app" && (
                <th
                  className={
                    "p-2 border-r border-gray-50 text-sm font-semibold tracking-wider text-start w-1/12" +
                    (Object.keys(filterOptions)[0] == "status"
                      ? "bg-blue-400"
                      : "")
                  }
                >
                  <span
                    className="flex flex-col cursor-pointer"
                    onClick={(e) => {
                      setShowFilterModal((prev) => ({
                        ...prev,
                        type: "status",
                        show: 1,
                      }));

                      determineShowFilterData("status");
                    }}
                  >
                    Status #
                  </span>
                  {genSortButtons("status")}
                </th>
              )}
              {props.view === "property" && (
                <th className="p-2 border-r border-gray-50 text-sm font-semibold tracking-wider text-start w-1/12">
                  <span>Water Savings</span>
                  {genSortButtons("status")}
                </th>
              )}
              {props.view === "property" && (
                <th className="p-2 border-r border-gray-50 text-sm font-semibold tracking-wider text-start w-1/12">
                  <span>Cost</span>
                  {genSortButtons("status")}
                </th>
              )}

              <th className="p-2 border-r border-gray-50 text-sm font-semibold tracking-wider text-start w-1/12">
                <span
                  className="flex flex-col cursor-pointer"
                  onClick={(e) => {
                    setShowFilterModal((prev) => ({
                      ...prev,
                      type: "currentStatus",
                      show: 1,
                    }));

                    determineShowFilterData("currentStatus");
                  }}
                >
                  Current Status
                </span>
                {genSortButtons("currentStatus")}
              </th>
              {props.view === "app" && (
                <th className="p-2 border-r border-gray-50 text-sm font-semibold tracking-wider text-start w-1/12">
                  <span
                    className="flex flex-col"
                    onClick={(e) => {
                      // setShowFilterModal((prev) => ({
                      //   ...prev,
                      //   type: "currentStatus",
                      //   show: 1,
                      // }));
                    }}
                  >
                    Date
                  </span>
                  {genSortButtons("statusDate")}
                </th>
              )}

              {props.view === "app" && (
                <th className="p-2 border-r border-gray-50 text-sm font-semibold tracking-wider text-start w-1/12">
                  <span
                    className="flex flex-col cursor-pointer"
                    onClick={(e) => {
                      setShowFilterModal((prev) => ({
                        ...prev,
                        type: "assignedTo",
                        show: 1,
                      }));

                      determineShowFilterData("assignedTo");
                    }}
                  >
                    Assigned To
                  </span>
                  {genSortButtons("assignedTo")}
                </th>
              )}

              {props.view === "app" && (
                <th className="p-2 text-sm font-semibold tracking-wider text-start w-1/12">
                  <span
                    className="flex flex-col"
                    onClick={(e) => {
                      // setShowFilterModal((prev) => ({
                      //   ...prev,
                      //   type: "alerts",
                      //   show: 1,
                      // }));
                    }}
                  >
                    Alerts
                  </span>
                  {genSortButtons("alert")}
                </th>
              )}
            </tr>
          </thead>

          {loadingApps === false ? (
            <tbody className="divide-y">{displayProperties}</tbody>
          ) : (
            <span className="text-black">loading...</span>
          )}
        </table>
      </div>
      {applicationTotal && (
        <Paginate
          props={{
            totalItems: applicationTotal,
            // props.view === "property"
            //   ? props.applications.length
            //   : filterOptions?.programName && filterCount
            //   ? filterCount
            //   : applicationCount,
            itemsPerPage: 50,
            setCurrentPage: setCurrentPage,
          }}
        />
      )}

      {showFilterModal?.show == 1 && (
        <ApplicationTableFilterModal
          props={{
            closeModal: setShowFilterModal,
            data: showFilterData,
            filter: showFilterModal.type,
            setFilter: setFilterOptions,
            filterOptions: filterOptions,
          }}
        />
      )}
    </motion.div>
  );
};

export default ApplicationTable;
