import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchApplications,
  getApplicationStatus,
  // selectAllApplications,
} from "../../../features/application";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
// import Paginate from "../../Misc/Paginate";

import {
  fetchApplicationValues,
  getValueStatus,
} from "../../../features/applicationValue";

import Application from "../../Applications/ApplicationTable";

const ApplicationsContainer = ({ props }) => {
  const dispatch = useDispatch();
  // const applicationList = useSelector(selectAllApplications);
  const applicationValueStatus = useSelector(getValueStatus);

  const [unlockIds, setUnlockIds] = useState(null);
  const applicationStatus = useSelector(getApplicationStatus);

  const [appModalController, setAppModalController] = useState({
    modalCustomerId: null,
    modalAppId: null,
    appModal: false,
    openAppModal: (payload) => {
      setUnlockIds({ programId: payload.programId, appId: payload.appId });
      setAppModalController((prev) => ({
        ...prev,
        appModal: true,
        modalCustomerId: payload.customerId,
        modalAppId: payload.appId,
      }));
    },
    closeAppModal: () => {
      setAppModalController((prev) => ({
        ...prev,
        appModal: false,
      }));
    },
  });
  useEffect(() => {
    // applicationStatus === "idle" && dispatch(fetchApplications());
    applicationValueStatus === "idle" && dispatch(fetchApplicationValues());
  }, [applicationStatus, applicationValueStatus, dispatch]);

  return (
    <div
      className={`h-full bg-white dark:bg-gray-900 transistion duration-200 font-sans dark:text-gray-50 ${
        props.sidebarOpen ? "ml-[15rem]" : "ml-[4rem]"
      }`}
    >
      <div className="main-content overflow-auto">
        <div className="p-8 flex flex-col gap-8">
          <Application props={{ view: "app" }} />
          {/* <Paginate
            props={{ totalItems: applicationList.length, itemsPerPage: 10 }}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default ApplicationsContainer;
