import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Property from "./Property.js";
import { useSelector } from "react-redux";
import { selectPropertyCount } from "../../features/properties.js";
import PropertyModal from "./PropertyModal.js";
import Paginate from "../Misc/Paginate";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { paginateProperties } from "../../features/properties.js";
import { useDispatch } from "react-redux";
const PropertyTable = ({ props }) => {
  const dispatch = useDispatch();
  const propertyCount = useSelector(selectPropertyCount);
  const [modalController, setModalController] = useState({
    propertyModal: false,
    property: null,
    programCost: null,
    waterSavings: null,
    openPropertyModal: (payload, programCost, waterSavings) => {
      setModalController((prev) => ({
        ...prev,
        propertyModal: true,
        property: payload,
        programCost: programCost,
        waterSavings: waterSavings,
      }));
    },
    closePropertyModal: () => {
      setModalController((prev) => ({
        ...prev,
        propertyModal: false,
      }));
    },
  });

  const [visibleProps, setVisibleProps] = useState(props);
  const [properties, setProperties] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  // const fetchProperties = async (page) => {
  //   try {
  //     const payload = { page };
  //     const res = await dispatch(paginateProperties(payload));
  //     setProperties(res.payload);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  // useEffect(() => {
  //   fetchProperties(currentPage);
  // }, [currentPage]);

  //pagination
  // const lastPostIndex = currentPage * 50;
  // const firstPostIndex = lastPostIndex - 50;
  // const displayProperties = visibleProps.map((property, index) => {
  //   return (
  //     <Property
  //       props={property}
  //       index={index}
  //       key={index}
  //       // applications={applications}
  //       modalController={modalController}
  //     />
  //   );
  // });

  //pagination
  const lastPostIndex = currentPage * 50;
  const firstPostIndex = lastPostIndex - 50;
  console.log("display-properties123: ", visibleProps);
  const displayProperties = visibleProps
    .slice(firstPostIndex, lastPostIndex)
    .map((property, index) => {
      return (
        <Property
          props={property}
          index={index}
          key={index}
          // applications={applications}
          modalController={modalController}
        />
      );
    });

  const [searchValue, setSearchValue] = useState("");

  const searchForStringsInArray = (mainString, searchArray) => {
    // Convert the mainString to lowercase for case-insensitive matching
    mainString = mainString.toLowerCase();

    // Iterate over each search string in the array
    for (const searchString of searchArray) {
      // Convert the searchString to lowercase for case-insensitive matching
      const lowercaseSearchString = searchString.toLowerCase();

      // Check if the lowercaseSearchString exists in the mainString
      if (!mainString.includes(lowercaseSearchString)) {
        return false; // If any search string is not found, return false
      }
    }

    return true; // All search strings were found in the mainString
  };

  // useEffect(() => {
  //   console.log("configure visible: ", properties);
  //   console.log("configure visible2: ", searchValue);
  //   if (properties) {
  //     console.log("configure visible: ", properties);

  //     if (searchValue) {
  //       console.log("search && value");
  //       let tmpVal = properties.filter((x) => {
  //         let tmpStr =
  //           (x.mfcStreetNo !== null ? x.mfcStreetNo : "") +
  //           x.mfcStreetName +
  //           x.streetNmSfx +
  //           "," +
  //           x.city +
  //           x.provinceCd +
  //           "," +
  //           x.postalCode;

  //         if (
  //           searchForStringsInArray(
  //             tmpStr.toLowerCase(),
  //             searchValue.toLowerCase().split(" ")
  //           )
  //         )
  //           return true;
  //         else return false;
  //       });
  //       console.log("tmpVal: ", tmpVal);
  //       setVisibleProps(tmpVal);
  //     } else {
  //       console.log("set visible to properties", properties);
  //       setVisibleProps(properties);
  //     }
  //   }
  // }, [searchValue, properties]);

  useEffect(() => {
    if (searchValue) {
      let tmpVal = props.filter((x) => {
        let tmpStr =
          (x.mfcStreetNo !== null ? x.mfcStreetNo : "") +
          x.mfcStreetName +
          x.streetNmSfx +
          "," +
          x.city +
          x.provinceCd +
          "," +
          x.postalCode;

        if (
          searchForStringsInArray(
            tmpStr.toLowerCase(),
            searchValue.toLowerCase().split(" ")
          )
        )
          return true;
        else return false;
      });
      setVisibleProps(tmpVal);
    } else setVisibleProps(props);
  }, [searchValue]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className={`property-table bg-white dark:bg-gray-700 duration-400 ease-in-out transition-spacing rounded-md font-sans flex flex-col`}
    >
      {modalController.propertyModal && (
        <PropertyModal props={{ modalController: modalController }} />
      )}
      <h2 className="font-sans mb-8 text-lg">PROPERTIES</h2>

      <div className="relative rounded-sm rounded-t w-full">
        <div className="relative w-fit">
          <input
            type="search"
            className="form-input block mb-8 w-[24rem] pl-8 leading-5 h-[2.5rem] transition  duration-150 ease-in-out bg-white  placeholder-gray-500 appearance-none rounded cursor-pointer focus:outline-none focus:shadow-outline-blue focus:border-blue-300 border-2 border-gray-300"
            placeholder="Search"
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
          />
          <div className="absolute top-0 bottom-0 left-0 flex items-center pointer-events-none pl-2">
            <FaMagnifyingGlass />
          </div>
        </div>
      </div>
      <hr className="mb-8" />
      <div className="overflow-auto rounded w-full border border-gray-100">
        <table className="table-auto w-full text-left shadow">
          <thead className="sticky top-0">
            <tr className="bg-blue-reef text-white">
              <th className="p-3 py-1 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50">
                Property ID
              </th>
              <th className="p-3 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50 ">
                Property Address
              </th>

              <th className="p-3 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50 ">
                Number of Associated Applications
              </th>
              <th className="p-3 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50 ">
                Cumulative Water Savings
              </th>
              <th className="p-3 text-sm font-semibold tracking-wider text-start whitespace-nowrap border-r border-gray-50 ">
                Cumulative Program Costs
              </th>
            </tr>
          </thead>
          {props && <tbody className="divide-y">{displayProperties}</tbody>}
        </table>
      </div>
      <Paginate
        props={{
          // totalItems: propertyCount,
          totalItems: visibleProps?.length,
          itemsPerPage: 50,
          setCurrentPage: setCurrentPage,
        }}
      />
    </motion.div>
  );
};

export default PropertyTable;
